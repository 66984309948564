import configs from '../../../configuration/configuration.json';

export const initialState = {
  completed: false,
  showRequiredModal: false,
  showLoadingSpinner: false,
  theme: 'default',
  config: configs['default'],
};

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case 'COMPLETED_SCREENING':
      return { ...state, completed: true, showRequiredModal: false }
    case 'SCREENING_SUBMITTED':
      return { ...state, submitted: true }
    default:
      return state;
    case 'SET_DATA_CONFIG':
      return { ...state, dataConfig: action.data }
    case 'TOGGLE_REQUIRED_MODAL':
      return { ...state, showRequiredModal: !state.showRequiredModal }
    case 'TOGGLE_LOADING_SPINNER':
      return { ...state, showLoadingSpinner: action.data.showLoadingSpinner }
    case 'COGNITO_USER':
      return {...state, cognitoUser: action.data}
    case "SET_GO_REGISTER":
      return { ...state, goRegister: action.data }
    case 'SET_GO_HOME':
      return {...state, goHome: action.data}
    case "SET_FLAGS":
      return {...state, flags: action.flags}
    case 'SET_THEME':
      switch(action.theme){
        case 'dark':
          if(state.flags && state.flags.darktheme){
            return {...state, theme: 'dark'};
          }else{
            return {...state, theme: 'default'}
          }
        case 'ravens':
          if(state.flags && state.flags.ravenstheme){
            return {...state, theme: 'ravens'}
          }else{
            return {...state, theme: 'default'}
          }
        case 'untimed':
          return {...state, theme: 'default'}
        case 'createoppcolumbia':
          return {...state, theme: 'createoppcolumbia'}
        case 'digmedia':
          return {...state, theme: 'default'}
        case 'digmediauntimed':
          return {...state, theme: 'default'}
        case 'serveng':
          return {...state, theme: 'default'}
        case 'servenguntimed':
          return {...state, theme: 'default'}
        case 'ba':
          return {...state, theme: 'default'}
        case 'cybersec':
          return {...state, theme:'default'}
        case 'sales':
          return {...state, theme:'default'}
        case 'projman':
          return{...state, theme:'default'}
        case 'kochaccountanttest':
          return {...state, theme:'default'}
        case 'kochaccounting':
          return {...state, theme:'default'}
        case 'geffenmeshertest':
          return {...state, theme: 'default'}
        default:
          return {...state, theme: 'default'};
    }
    case 'SET_CONFIG':
      switch(action.theme){
        case 'dark':
          if(state.flags && state.flags.darktheme){
            return {...state, config: configs['dark']}
          }else{
            return {...state, config: configs['default']}
          }
        case 'ravens':
          if(state.flags && state.flags.ravenstheme){
            return {...state, config: configs['ravens']}
          }else{
            return {...state, config: configs['default']}
          }
        case 'untimed':
          return {...state, config: configs['untimed']}
        case 'createoppcolumbia':
          return {...state, config: configs['createoppcolumbia']}
        case 'digmedia':
          return {...state, config: configs['digmedia']}
        case 'digmediauntimed':
          return {...state, config: configs['digmediauntimed']}
        case 'serveng':
          return {...state, config: configs['serveng']}
        case 'servenguntimed':
          return {...state, config: configs['servenguntimed']}
        case 'ba':
          return {...state, config: configs['ba']}
        case 'cybersec':
          return {...state, config: configs['cybersec']}
        case 'sales':
          return {...state, config: configs['sales']}
        case 'projman':
          return {...state, config: configs['projman']}
        case 'kochaccountanttest':
          return {...state, config: configs['kochaccountanttest']}
        case 'kochaccounting':
          return {...state, config: configs['kochaccounting']}
        case 'geffenmeshertest':
          return {...state, config: configs['geffenmeshertest']}
        default:
          return {...state, config: configs['default']};
    }
  }
}
