import React from 'react';
import styled from 'styled-components/macro';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
import { useSelector } from 'react-redux';


const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  z-index: 100;
  background-color: ${props => props.theme.card};
  padding-left: 2em;
  padding-right: 1em;
  padding-top: 1em;
`;

const ProgramOverviewTitle = styled.h1`
  width: 100%;
  text-align: left;
  font: normal normal 900 22px/20px;
  letter-spacing: 0px;
  color: ${props => props.theme.accentText};
`;

const ProgramOverviewText = styled.div`
  margin-bottom: 1em;
  width: 70%;
  text-align: left;
  font: normal normal medium 14px/19px;
  letter-spacing: 0px;
  color: ${props => props.theme.primaryText};
`;

const ApplicantRequirementsHeader = styled.h4`
  font: normal normal bold 14px/19px;
  letter-spacing: 0px;
  color: ${props => props.theme.accentText};
`;

const ApplicantRequirementsWrapper = styled.ul`
  display: flex;
  width: 100%;
  margin-bottom: 10%;
  padding: 0;
  flex-direction: column;
  list-style: none;
`;

const StyledBullet = styled.div`
  max-height: 10px;
  min-width: 10px;
  margin-right: 1em;
  margin-top: 1.5%;
  border-radius: 100%;
  background: linear-gradient(90deg, ${props => props.theme.tertiary} 0%, ${props => props.theme.secondary} 100%)
`;

const StyledListItem = styled.li`
  display: flex;
  margin-bottom: 2%;
  margin-right: 1em;
  font: normal normal 600 14px/16px;
  color: ${props => props.theme.primaryText};
`;

const StyledPrimaryButtonComponent = styled(PrimaryButtonComponent)`
  width: 18em;
  margin-bottom: 4em;
  margin-left: 12%;
`;

const MobileOverviewContent = (props) =>{
  const config = useSelector(state => state.global && state.global.config)
  
  const renderTexts = (texts) => {
    return texts.map((v, i) => {
      return <ProgramOverviewText key={i}>{v}</ProgramOverviewText>
    })
  }
  
  const renderListitems = (items) => {
    return items.map((v, i) => {
      return  <StyledListItem key={i}><StyledBullet />{v}</StyledListItem>
    })
  }
  
  if(!(config && config.registration && config.registration.overview)){
    return (
      <></>
    )
  }
    return (
    <StyledContainer>
        <ProgramOverviewTitle>{config.registration.overview.title}</ProgramOverviewTitle>
        {
          renderTexts(config.registration.overview.texts)
        }
        <ApplicantRequirementsHeader>{config.registration.overview.requirementsHeader}</ApplicantRequirementsHeader>
        <ApplicantRequirementsWrapper>
            {
              renderListitems(config.registration.overview.requirements)
            }
        </ApplicantRequirementsWrapper>
        <StyledPrimaryButtonComponent
            onClick={() => props.onClickSubmit({})}
            text={'Start Registration'}>
        </StyledPrimaryButtonComponent>
    </StyledContainer>  
    );
  }

export default MobileOverviewContent;
