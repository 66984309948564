import React, { useContext, useEffect, useState } from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import TextInputComponent from '../TextInputComponent/TextInputComponent';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
import SecondaryButtonComponent from '../SecondaryButtonComponent/SecondaryButtonComponent';
import RadioButtonComponent from '../RadioButtomComponent/RadioButtonComponent';
import DividerComponent from '../DividerComponent/DividerComponent';
import DividerComponentWithText from '../DividerComponent/DividerComponentWithText';
import { ThemeContext } from 'styled-components'; 

const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const FormBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 100;
  background-color: ${props => props.theme.regFormBgColor};
  margin-left: 20%;
  margin-right: 20%;
  padding-left: 7em;
  padding-right: 7em;
  padding-top: 5em;
`;

//** Terms & Conditions */
const TermTitleWrapper = styled.div` 
    display: flex;
    color: ${props => props.theme.accentText};
    font-weight: bold;
`;

const RequiredSymbol = styled.div`
    color: red;  
    margin-bottom: 0;
    padding-left: 0.5em;
`;

const Paragraphs = styled.div`
`;

const Paragraph = styled.div`
    color: ${props => props.theme.primaryText};
    display: flex;
    margin-bottom: 3px;
`;

const BoldStyle = styled.div`
    font-weight: bold; 
`;

const StyledLink = styled.a`
    color: ${(props) => props.theme.main};
    text-decoration: underline;
    padding-top: 1em;
    width: fit-content;
`;

//** Consent Radio Button */
const RadioButtonRow = styled.div`
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;    
`;

const RadioButtons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 20em;
`;

const ConsentHelperText = styled.div`
    color: ${ props => props.theme.error };
    margin-top: .17em;
    font-size: .75em;
    min-height: 1.25em;
`;

//** Google SSO Elements */
const FedBtnContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FedBtn = styled.div`
  border-color: black;
  border-width: 1px;
  border: 1px solid lightgrey;

  width: 32%;
  display: flex;
  cursor: pointer;
  margin-top: 0.5em;
  height: 50px;

  border-radius: 6px;
  &:hover {
    background-color: lightgrey;
  }
`;

const GoogleLogo = styled.div`
  height: 1.5em;
  width: 1.5em;
`;

const SignInWithGoogleText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  font-size: .9em;  
`;

//** Divider */
const DividerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 2em;
`;

const Divider = styled.div`
    display: flex;
    height: .3em;
    width: 100%;
    background-image: linear-gradient(90deg, ${props => props.theme.secondary} 0%, ${props => props.theme.tertiary} 100%);
    border-radius: 10em;
`;

const DividerComponentWithTextWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;

//** Email Signup elements */
const CreateAccountTitle = styled.div` 
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    color: ${props => props.theme.accentText};
    font-size: 1.5em;
    font-weight: bold;
`;

const InputGroup = styled.div`
    display: flex;
    justify-content: center;
`;

const EmailInputWrapper = styled.div`
  display: flex;
  min-height: 6.2em;
  width: 51%;  
`;

const PasswordInputWrapper = styled.div`
  display: flex;
  min-height: 6.2em;
  width: 51%;
`;

//** Buttons */
const ButtonRow = styled.div`
    margin-left: 1em;
    margin-right: 1em;
    justify-content: space-between;
`;

const BackButtonContainer = styled.div`
    margin-left: 1em;
    margin-right: 1em;
    justify-content: space-between;
    display: flex;
    padding-bottom: 4em;
    padding-top: 2em;
`;

const ContinueButtonContainer = styled.div`
    margin-left: 1em;
    margin-right: 1em;
    justify-content: center;
    display: flex;
    padding-bottom: .5em;
    padding-top: 1em;
`;

const LoaderIcon = styled.div`
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid black;
    width: 15px;
    height: 15px;
    margin: 50px 0px 0 10px;
    -webkit-animation: spin 2s linear infinite;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 1s linear infinite;

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const SecondaryFormComponent = (props) =>{
    //Theme & State
    const themeContext = useContext(ThemeContext);
    const [loader, setLoader] = useState(false);
    const global = useSelector(state => state.global) 

    //Cognito
    const cognitoUser = global && global.cognitoUser;
    const validCognitoPassword = props.validCognitoPassword;

    //Email & Password
    const email = props.email;
    const isValidEmail = props.isValidEmail;
    const getEmailHelper = props.getEmailHelper;
    const onEmailChange = props.onEmailChange;
    const onEmailBlur = props.onEmailBlur;
    const password = props.password;
    const isValidPassword = props.isValidPassword;
    const getPasswordHelperText = props.getPasswordHelperText;
    const onPasswordChange = props.onPasswordChange;
    const onPasswordBlur = props.onPasswordBlur;
    const confirmedPassword = props.confirmedPassword;
    const isValidConfirmedPassword = props.isValidConfirmedPassword;
    const onConfirmedPasswordChange = props.onConfirmedPasswordChange;
    const onConfirmedPasswordBlur = props.onConfirmedPasswordBlur;
    const onEyeClick = props.handleEyeClick;

    //Federated Sign In
    const fedSignIn = props.fedSignIn;
    const getGoogleHelperText = props.getGoogleHelperText;

    //Radio buttons
    const consent = props.consent;   
    const onConsentChange = props.onConsentChange;
    const isValidConsent = props.isValidConsent;
    const getConsentHelperText = props.getConsentHelperText;

    //Submit & Back
    const isValid = props.isValid;
    const handleSubmit = props.handleSubmit;
    const handleBack = props.handleBack;

    //Key Handler
    const handleOnKeyDown = props.handleOnKeyDown;
    
    return (
        <FormBox onKeyDown={handleOnKeyDown} tabIndex={0}>   
            {/* Terms & Conditions, Privacy Policy, and accept radio button */}
            <TermTitleWrapper>Terms and Conditions<RequiredSymbol>*</RequiredSymbol></TermTitleWrapper>
            <Paragraphs>
                {global.config.registration.termsAndConditions.texts.map((v, i) => {
                    return (<Paragraph key={i}>{v}</Paragraph>)
                })}
            <BoldStyle>
                {global.config.registration.termsAndConditions.boldTexts.map((v, i) => {
                    return (<Paragraph key={i}>{v}</Paragraph>)
                })}
            </BoldStyle>
            </Paragraphs>
            <StyledLink href="https://www.catalyte.io/privacy/" target="_blank" rel="noreferrer">
                Catalyte privacy policy
            </StyledLink>
            <RadioButtonRow>
                <RadioButtons>
                    <RadioButtonComponent id="agree" onChange={onConsentChange} group="consent" label="I accept all terms and conditions" checked={consent === "agree"} isSelectedUnbold ={true} labelStyle={ {fontSize : 'medium', fontWeight : 600}} />                   
                </RadioButtons>
                <ConsentHelperText>{isValidConsent !== true ? getConsentHelperText(consent) : ''}</ConsentHelperText>
            </RadioButtonRow>
            {
            !cognitoUser &&
            <div>
                <DividerWrapper>
                <Divider></Divider>
                </DividerWrapper>
                {/* Email and Password registration */}
                <CreateAccountTitle>Create Your Account</CreateAccountTitle>
                <InputGroup>
                    <EmailInputWrapper>
                        <TextInputComponent
                            id={'email-input'}
                            error={(isValidEmail !== null && (props.userExists || !isValidEmail)) ? true : false}
                            helperText={getEmailHelper()}
                            type="text"
                            onChange={(e) => {
                                setLoader(true)
                                setTimeout(() => {
                                    setLoader(false)
                                }, 500)
                                onEmailChange(e)
                            }}
                            onBlur={onEmailBlur}
                            value={email}
                            backgroudColor={themeContext.quarterly}
                            isValid={isValidEmail !== null && (!props.userExists && isValidEmail)}
                            label='Email'
                            required
                            autoComplete="off"
                        />
                        {loader && <LoaderIcon></LoaderIcon>}
                    </EmailInputWrapper>
                </InputGroup>
                <InputGroup>   
                    <PasswordInputWrapper>                
                        <TextInputComponent
                            id={'pwd-input'}
                            error={ isValidPassword === null ? false : !isValidPassword }
                            helperText={getPasswordHelperText(password, validCognitoPassword)}
                            type={props.passwordType}
                            isEyeButton = {props.isShowPWEyeButton}
                            onChange={onPasswordChange}
                            onEyeClick ={onEyeClick}
                            value={password}
                            onBlur={onPasswordBlur}
                            onFocus ={props.onPWFocus}              
                            required={true}
                            isValid={isValidPassword}
                            label='Password'
                            backgroudColor={themeContext.quarterly}
                            autoComplete="off"
                        />
                    </PasswordInputWrapper>
                </InputGroup>
                <InputGroup>
                    <PasswordInputWrapper>
                        <TextInputComponent
                            id={'cnfrm-input'}
                            error={ isValidConfirmedPassword === null ? false : !isValidConfirmedPassword }
                            helperText={ confirmedPassword ? 'Passwords do not match.' : 'You must confirm your password.'}
                            type={props.confirmPasswordType}
                            isEyeButton = {props.isShowCPWEyeButton}
                            onChange={onConfirmedPasswordChange}
                            onEyeClick ={onEyeClick}
                            value={confirmedPassword}
                            onBlur={onConfirmedPasswordBlur}
                            onFocus = {props.onCPWFocus}
                            required={true}
                            isValid={isValidConfirmedPassword}
                            label='Confirm Password'
                            backgroudColor={themeContext.quarterly}
                            autoComplete="off"
                        />
                    </PasswordInputWrapper>
                </InputGroup>
                <ContinueButtonContainer>
                    <PrimaryButtonComponent
                        id={'submit-btn'}
                        innactive={!isValid}
                        onClick={handleSubmit}
                        text={!cognitoUser ? 'Continue' : 'Continue'}
                        className={'g-recaptcha'}
                        data-sitekey='6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C'
                        data-callback={handleSubmit}
                        data-action='submit'
                    ></PrimaryButtonComponent>
                </ContinueButtonContainer>
                <DividerComponentWithTextWrapper>
                    <DividerComponentWithText dividerText={'OR'}/>
                </DividerComponentWithTextWrapper>
                {/* Google registration */}
                <FedBtnContainer>
                    <FedBtn onClick={isValidConsent === true ? fedSignIn : null } style={{opacity: isValidConsent ? '1' : '0.5'}}>
                        <GoogleLogo style={{ backgroundImage: `url(${imgBasePath}logo_google.svg)`, margin: '10px'}} id="googLogo"/>
                        <SignInWithGoogleText>Continue with Google</SignInWithGoogleText>
                    </FedBtn>
                </FedBtnContainer>
            </div>
            }
            {/* Submit and Back buttons */}
            <BackButtonContainer>
                <SecondaryButtonComponent
                    id={'back-btn'}
                    onClick={handleBack}
                    text={'Back'}
                ></SecondaryButtonComponent>
                {
                cognitoUser &&
                    <PrimaryButtonComponent
                        id={'submit-btn'}
                        innactive={!isValid}
                        onClick={handleSubmit}
                        text={!cognitoUser ? 'Continue' : 'Continue'}
                        className={'g-recaptcha'}
                        data-sitekey='6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C'
                        data-callback={handleSubmit}
                        data-action='submit'
                    ></PrimaryButtonComponent>
                }
            </BackButtonContainer>
        </FormBox>
      );
  }

export default SecondaryFormComponent;