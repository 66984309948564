const sectionReducer = (state = [], action ) => {

switch(action.type){
        case "ADD_SECTIONS": {
                let orderedSections = action.data.sort((a, b) => (a.order > b.order) ? 1 : -1);
                orderedSections = orderedSections.map((section, i) => {
                        section.questions = section.questions.sort((a, b) => (a.order > b.order) ? 1 : -1);

                        section.questions = section.questions.map((question, i) => { 
                               
                                if(question.type === 'checkbox.text' || question.type === 'checkbox' || 
                                   question.type.toLowerCase().indexOf('ddl.multipleselect') > -1){
                                        question.answer = question.options.map((option, i) => {
                                                option.input = '';
                                                return option;
                                        })
                                        return question;
                                }
                                return question;
                        });

                        section.questions = section.questions.map((question, i) => {
                                var answer = section.answers.filter((answer, i) => answer.questionID === question.questionID)[0].input;                                
                              
                                if(!answer){
                                    return question;
                                }
                              
                                if(question.type === 'checkbox.text' || question.type === 'checkbox' || section.type === 'multiForm.employment' ||
                                question.type === 'number.ddl' || question.type.toLowerCase().indexOf('ddl.multipleselect') > -1){                                          
                                        question.answer = JSON.parse(answer);
                                }else{
                                        question.answer = answer;
                                }
                                return question;
                        });
                        return section;
                });
                return [...orderedSections];
        }
        case "SAVE_SECTION":
                return state.map((v, i) => {
                        
                        if(v.sectionID === action.data.sectionID){                        
                         return action.data;
                        }
                        return v;
                });
        case "UPDATE_SAVED_SECTION": {
                let newSectionData = JSON.parse(JSON.stringify(action.data));

                newSectionData.questions = newSectionData.questions.sort((a, b) => (a.order > b.order) ? 1 : -1);

                newSectionData.questions = newSectionData.questions.map((question, i) => { 
                       
                        if(question.type === 'checkbox.text' || question.type === 'checkbox' || 
                           question.type.toLowerCase().indexOf('ddl.multipleselect') > -1){
                                question.answer = question.options.map((option, i) => {
                                        option.input = '';
                                        return option;
                                })
                                return question;
                        }
                        return question;
                });

                newSectionData.questions = newSectionData.questions.map((question, i) => {
                        var answer = newSectionData.answers.filter((answer, i) => answer.questionID === question.questionID)[0].input;                                
                      
                        if(!answer){
                            return question;
                        }
                      
                        if(question.type === 'checkbox.text' || question.type === 'checkbox' || newSectionData.type === 'multiForm.employment' ||
                           question.type === 'number.ddl' || question.type.toLowerCase().indexOf('ddl.multipleselect') > -1){                                          
                                question.answer = JSON.parse(answer);
                        }else{
                                question.answer = answer;
                        }
                        return question;
                });

                return state.map((v, i) => {
                        
                        if(v.sectionID === newSectionData.sectionID){                        
                         return newSectionData;
                        }
                        return v;
                });
        }
        default:
                return state;
    }
};

export default sectionReducer;
