import debounce from 'lodash/debounce';
import store from '../../redux/store';

export const validateDateFormat = (inputText) =>{    
  
    let dateformat =  /^\d{2}\/\d{2}\/\d{4}$/;
    if(dateformat.test(inputText))
    {            
        const opera1 = inputText.includes('/');
        const opera2 = inputText.includes('-');        
        let pdate;       
        if (opera1)
        {
            pdate = inputText.split('/');
        }
        else if (opera2)
        {
            pdate = inputText.split('-');
        }
        let mm = parseInt(pdate[0]);
        let dd  = parseInt(pdate[1]);
        let yy = parseInt(pdate[2]);
 
        if(mm >12) {
            return false;
        } 
        if(yy > 2021 || yy <1920){
            return false;
        }  
    
        let ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
        if (mm===1 || mm > 2)
        {
            if (dd>ListofDays[mm-1])
            {        
                return false;
            }
        }
        if (mm===2)
        {
            let lyear = false;
            if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
            {
                lyear = true;
            }
            if ((lyear===false) && (dd>=29))
            {        
                return false;
            }
            if ((lyear===true) && (dd>29))
            {          
                return false;
            }
        }
        return true;
    }
    else
    {     
        return false;
    }
}

export const setDateFormat =(answer, requiredLength) =>{
         
    if(answer.length > 2 && answer.length <6)
    {
        answer = answer.replace(/.{2}/,'$&/');                 
    }
   
    if(answer.length >= 6 && answer.length <11)
    {  
        answer = answer.replace(/.{2}/,'$&/'); 
        answer = answer.replace(/.{5}/,'$&/'); 
    } 
    if(answer.length <= answer)  {
        return answer;
    }
    else
    {
        return answer.slice(0,requiredLength); 
    }        
}

export const dateValidation =(answer ) =>{                
    answer = answer ? answer.replace(/\D/g,'') : '';       
    if(answer){                
        return setDateFormat(answer, 'xx/xx/xxxx'.length); 
    }    
    return null;         
}

export const getClient = () => {
    let client = 'catalyte'

    switch(window.location.pathname){
      case '/createoppcolumbia':
        client = 'createoppcolumbia';
        break;
      default:
        client = 'catalyte';
    }

    let clientCookie = document.cookie
      .split(';')
      .filter((v, i) => { 
        return v.split('=')[0]
        .trim() === 'client' 
      });

    if(clientCookie.length > 0){
      client = clientCookie[0].split('=')[1];
    }

    return client;
}

// export const getConfig = () => {
//     let configFromUrl = window.location.hostname.split('//').pop().split('.screen')[0]

//     if(configFromUrl === null){
//         return ''
//     }

//     return configFromUrl
// }

export const getClientBaseUrl = () => {
    let baseUrl = process.env.REACT_APP_env_url
    let client = getClient()
    //let config = getConfig()

    //If there is a client other than catalyte, add that client's config to the base url
    if(client && client !== 'catalyte'){
        let urlparts = baseUrl.split('//')
        let clientUrl = `${urlparts[0]}//${client}.${urlparts[1]}`
        return clientUrl
    }

    //If there is a config prefix in the current url, add it to the base url
    // if(config){
    //     let urlparts = baseUrl.split('//')
    //     let baseUrlWithConfig = `${urlparts[0]}//${config}.${urlparts[1]}`
    //     return baseUrlWithConfig
    // }

    return baseUrl
}

export const getConfigUrl = (config) => {
    //get base site url
    let baseUrl = process.env.REACT_APP_env_url

    //split base url after 'https://' and add the config name
    let urlparts = baseUrl.split('//')
    let configUrl = `${urlparts[0]}//${config}.${urlparts[1]}`
    
    //return base url with config
    return configUrl
}

const checkEmail = (email) => {
    store.dispatch({ 'type': 'CHECK_USER_EMAIL', email });
  }

export const debouncedEmailCheck = debounce(checkEmail, 1000);

export const getParameterByName = (name, url = window.location.href) => {
    /* eslint no-useless-escape: "off" */
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

