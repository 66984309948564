import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledInput = styled.div`
  width: 250%;
  display: flex;
  min-height: 5em;
  background-color: ${props => props.theme.inputBackground};
  border-bottom-style: solid;
  justify-content: space-between;
  padding-left: 1em;
`;

const StyledTextArea = styled.textarea`
  border: none;
  min-height: 5em;
  resize: vertical;
  font-size: 1em;
  background-color: ${props => props.theme.inputBackground};
  flex-grow: 1;
  color: ${props => props.theme.inputText};
  &:hover {
    border: none;
  }
  &:focus {
    border: none;
    outline: none;
  }
`;

const LabelText = styled.div`
  color: ${props => props.theme.label};
  font-weight: 600;
  font-size: medium;
  margin-left: 0; 
`;

const StyledLabel = styled.div`
  width: 250%;
  display: flex;
  min-height: 10px;
  padding-bottom: 1em;
  @media (max-width: 425px) {
    width: 150%;
  } 
`;

const RequiredSymbol = styled.div`
  color: red;
  margin-left: .2em;
  margin-bottom: 0;    
`;

const ErrorSymbol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: green;
  background-image: url(${props => props.theme.errorIcon});
  width: 1.5em;
  background-repeat: no-repeat;
  background-position-y: center;
`;

const HelperText = styled.div`
  color: ${props => props.theme.error};
  margin-left: .5em;
  margin-top: .5em;
  font-size: .75em;
  min-height: 1.25em;    
`;

const ValidationSymbolWrapper = styled.div`
  width: 1.9em;
  display: flex;  
  position: relative;
  left: -1em;  
`;

export const TextAreaComponent = (props) => {
  const [isFocus, setIsFocus] = React.useState(false);
  const wrapperRef = React.useRef(null); 

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = event => {
    if(props.outsideClick) props.outsideClick();
    setIsFocus(false);
  };

  const handleChange = (e) => {
    props.onChange(e.target.value, isFocus); 
  }

  const handleOnBlur = (e) => {
    e.target.placeholder = props.placeholder ? props.placeholder : '';
    if(props.onBlur) {
      props.onBlur(e.target.value);
    }
  }

  const handleOnFocus = (e) => {
    e.target.placeholder = '';
    setIsFocus(true);
    if(props.onFocus) props.onFocus(e.target.id);
  }

  return (
    <StyledRoot className={props.className}>
      { !props.noLabelPlaceholder &&
        <StyledLabel>
          {props.label ? <LabelText>{ props.label }</LabelText> : ''}
          { props.required ? <RequiredSymbol>*</RequiredSymbol> : '' }
        </StyledLabel>
      }
      <StyledInput ref={wrapperRef}>
        <StyledTextArea
          id={props.id}
          onChange={handleChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          value={props.value || ""}
          placeholder={props.placeholder}
          autoComplete={props.autoComplete}
          maxLength={props.maxLength}
          rows={props.rows}
        />
        <ValidationSymbolWrapper>
          { props.error ? <ErrorSymbol></ErrorSymbol> : '' }
        </ValidationSymbolWrapper>
      </StyledInput>
      <HelperText> { props.error ? props.helperText : '' } </HelperText>
    </StyledRoot>
  );
};

export default TextAreaComponent;
