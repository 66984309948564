import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import TimerComponent from '../TimerComponent/TimerComponent';
import QuestionParentComponent from '../QuestionParentComponent/QuestionParentComponent';
import { Typography } from '@material-ui/core';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const SectionTile = styled.div`
    padding: 2em;
    text-align: center;
`;

const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const ButtonWrapper = styled.div`
    margin: 1em;
`;

const EducationHistoryComponent = () => {
    const dispatch = useDispatch();
    const selectedSection = useSelector((state) => state.selectedSection);


    const renderQuestions = () => {
        return selectedSection.questions.filter((v, i) => {
            return v.isVisible
        }).map((v, i) => {
            return(
                <QuestionParentComponent key={v.uuid} question={v}></QuestionParentComponent>
            )
        })
    }

    const revealQuestionGroup = (e) => {
        let currentGroup = selectedSection.questions.filter((q, i) => {
            return q.isVisible
        }).sort((a, b) => (a.group > b.group) ? -1 : 1)[0].group;

        let nextGroup = currentGroup * -1 * -1 + 1;
        
        selectedSection.questions.forEach(q => {
            if(q.group === nextGroup){
                dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": q.uuid, "id": q.questionID, "isVisible": true} });
            }
        });
    }

    return (
        <StyledRoot>
            {selectedSection.isTimed ? <TimerComponent></TimerComponent> : <></>}
            <SectionTile>
                <Typography variant="h4" color="inherit" style={{marginTop: ".5em"}}>
                    {selectedSection.name}
                </Typography>
            </SectionTile>
            <StyledBody>
                <QuestionWrapper>
                    {renderQuestions()}
                </QuestionWrapper>
            </StyledBody>
            <ButtonWrapper>
                <button onClick = {revealQuestionGroup}>
                    Add
                </button>
            </ButtonWrapper>
        </StyledRoot>
    );
}

export default EducationHistoryComponent;