import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import ChartComponent from '../ChartComponent/ChartComponent';
import VerticalRadioQuestionComponent from '../VerticalRadioQuestionComponent/VerticalRadioQuestionComponent';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled'
import MobileChartComponent from '../ChartComponent/MobileChartComponent';

const StyledRoot = styled.div`
    display: flex;    
    flex-direction: column;    
    margin-right: 2em;
    margin-left: 2em;   
    ${up('xl')} {
        margin-right: 0;
        margin-left: 0;
    }
`;

const StyledBody = styled.div`
    display: flex;
    flex-wrap: wrap;    
    ${up('xl')} {
        margin-right: 0;
        margin-left: 0;
    }
`;

const StyledHeader = styled.div`
    color: ${props => props.theme.accentText};
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
`;

const StyledHeaderOne = styled.div`
    max-width: 1165px;
    color: ${props => props.theme.accentText};
    font-weight: bold;    
    margin-bottom: 1em;
    font-size: 1.5em;
    :not(:first-child) {
        margin-top: 2em;        
    }
`;

const StyledParagraph = styled.div`
    max-width: 1165px;
    min-height: 1em;
    color: ${props => props.theme.primaryText};
    margin-top: 1em;
    margin-bottom: 1.5em;    
`;

const StyledSubText = styled.div`
    color: ${props => props.theme.primaryText}
`;

const QuestionWrapper = styled.div`
    width: 100%;
    ${up('xl')} {
        margin: 0 2em;        
    }
`;

const StyledChartComponent = styled(ChartComponent)``;

const StyledVerticalRadioQuestionComponent = styled(VerticalRadioQuestionComponent)`    
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 2em;    
    ${up('xl')} {        
        margin-bottom: 6em;
        margin-right: 2em;
        margin-left: 2em;        
        width: calc(50% - 4em);                
        > div {
            width: 50%;
        }        
    }
`;

const StyledDivider = styled.div`
    border-top: 1px solid #8B8B8B;    
    margin-bottom: 5em;
`;

const ReadingSectionComponent = (props) => {    
    const selectedSection = useSelector((state) => state.selectedSection);
    const isDesktop = useBreakpoint(up('xl'))

    const renderQuestions = () => {
        return selectedSection.questions.map((v, i) => {

            switch(v.class){
                case "text.reading.passage_text":
                    return(
                        <QuestionWrapper key={v.uuid}>
                            {renderPassageQuestion(v.prompts)}
                        </QuestionWrapper>
                    )
                default:                    
                    return <StyledVerticalRadioQuestionComponent key={`vrqc_${i}`} question={v}></StyledVerticalRadioQuestionComponent>                    
            }
        })
    }

    const renderPassageQuestion = (prompts) => {

        return prompts.sort((a, b) => { return parseInt(a.order) > parseInt(b.order) ? 1 : -1 }).map((v, i) => {
            switch(v.valueType){
                case "h1":
                    return (<StyledHeaderOne key={`passage_part_${i}`}>{v.value}</StyledHeaderOne>);
                case "h2":
                    return (<StyledHeader key={`passage_part_${i}`}>{v.value}</StyledHeader>);
                case "p":
                    return (<StyledParagraph key={`passage_part_${i}`}>{v.value}</StyledParagraph>);
                case "sub":
                    return (<StyledSubText key={`passage_part_${i}`}>{v.value}</StyledSubText>);
                case "#text":                   
                    break;
                case "chart":
                    return  isDesktop ? <StyledChartComponent key={`passage_part_${i}`} chart={v.value}></StyledChartComponent> :  <MobileChartComponent key={`passage_part_${i}`} chart={v.value}></MobileChartComponent>;
                case "br":
                    return (<StyledDivider key={`passage_part_${i}`}></StyledDivider>);
                default:
                    return (<StyledParagraph key={`passage_part_${i}`}>{v.value}</StyledParagraph>);
            }

            return (<StyledParagraph key={`passage_paragraph_${i}`}>{v.value}</StyledParagraph>);
        })

    }

    return (
        <StyledRoot> 
            <StyledBody>
                {renderQuestions()}
            </StyledBody>
        </StyledRoot>
    );
}

export default ReadingSectionComponent;
