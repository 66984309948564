import React from 'react';
import styled from 'styled-components';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1165px;
  background-color: ${props => props.theme.mainTwo};
  color: white;
  border-radius: 25px;
  margin-top: 5em;
  margin-bottom: 2em;
  box-shadow: 0px 3px 6px #00000029;
`;

const StyledHeaderCell = styled.div`
    display: flex;
    width: 25%;
    justify-content: center;    
    color: white;
    line-height: 40px;
    width: 229px;
    padding: 1em;
`;

const StyledHeaderInnerCell = styled(StyledHeaderCell)`
    border-right: 1px solid white;
`;
const StyledHeaderLeftCell = styled(StyledHeaderCell)`
    border-right: 1px solid white;
`;
const StyledHeaderRightCell = styled(StyledHeaderCell)`
    width: 621px;
`;

const StyledRowCell = styled.div`
    display: flex;
    width: 229px;
    border: 1px solid white;
    padding: 1em;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

const StyledFinalRowInnerCell = styled(StyledRowCell)`
    border-bottom: none;
`;

const StyledRowBigCell = styled(StyledRowCell)`
    width: 621px;
    text-align: left;
    line-height: 1.89;
    border-right: none;
`;

const StyledRowFirstCell = styled(StyledRowCell)`
    border-left: none;
`;

const StyledFinalRowRightCell = styled(StyledRowBigCell)`
    border-right: none;
    border-bottom: none;
`;


const StyledFinalRowLeftCell = styled(StyledRowCell)`
    border-left: none;
    border-bottom: none;
`;

const StyledRow = styled.div`
    display: flex;
`;

const StyledHeaderRow = styled.div`
    display: flex;
    border-radius: 25px 25px 0 0;
    background-color: ${props => props.theme.main};
`;

const ChartComponent = (props) => {

    let chart = JSON.parse(props.chart);

    const renderHeaders = (headers) => {
        return (
            <StyledHeaderRow>
                {
                    headers.map((v, i) => {
                        if(i === 0){
                            return (<StyledHeaderLeftCell key={`header_row_${i}`} dangerouslySetInnerHTML={{__html: v}}></StyledHeaderLeftCell>)
                        }else if (i === headers.length - 1)
                        {
                            return (<StyledHeaderRightCell key={`header_row_${i}`} dangerouslySetInnerHTML={{__html: v}}></StyledHeaderRightCell>)
                        }else{
                            return (<StyledHeaderInnerCell key={`header_row_${i}`} dangerouslySetInnerHTML={{__html: v}}></StyledHeaderInnerCell>)
                        }
                        
                    })
                }
            </StyledHeaderRow>
        )
    }

    const renderRows = (rows) => {
        return rows.map((v, x) => {
            return (<StyledRow key={`chart_row_${x}`}>
                {
                v.cells.map((z, i) => {
                    if(x === rows.length -1 && i === v.cells.length - 1){
                        return(<StyledFinalRowRightCell key={`body_row_${i}`} dangerouslySetInnerHTML={{__html: z.text}}></StyledFinalRowRightCell>)
                    }else if (x === rows.length -1 && i === 0){
                        return(<StyledFinalRowLeftCell key={`body_row_${i}`} dangerouslySetInnerHTML={{__html: z.text}}></StyledFinalRowLeftCell>)
                    }
                    else if(x === rows.length -1){
                        return<StyledFinalRowInnerCell key={`body_row_${i}`} dangerouslySetInnerHTML={{__html: z.text}}></StyledFinalRowInnerCell>
                    }
                    else if(i === v.cells.length - 1){
                        return(<StyledRowBigCell key={`body_row_${i}`} dangerouslySetInnerHTML={{__html: z.text}}></StyledRowBigCell>)
                    }else if(i === 0){
                        return(<StyledRowFirstCell key={`body_row_${i}`} dangerouslySetInnerHTML={{__html: z.text}}></StyledRowFirstCell>)
                    }else{
                        return(<StyledRowCell key={`body_row_${i}`} dangerouslySetInnerHTML={{__html: z.text}}></StyledRowCell>)
                    }
                    
                })
                }
            </StyledRow>);
        });
    }

    return (
        <StyledRoot>
            {renderHeaders(chart.headers)}
            {renderRows(chart.rows)}
        </StyledRoot>
    );
}

export default ChartComponent;
