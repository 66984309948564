import React from 'react';
import styled from 'styled-components/macro';

const StyledRoot = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 5em;
`;

const Divider = styled.div`
    display: flex;
    height: 0;
    width: 100%;
    border-radius: 1em;
    border-top-style: solid;
    border-color: #D8D7D5;
`;

const DividerComponent = () => {
    return(
        <StyledRoot>
            <Divider />
        </StyledRoot>
    )
}

export default DividerComponent;