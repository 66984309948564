import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/rootReducer/rootReducer';
import rootSaga from './sagas/rootSaga/rootSaga';

const sagaMiddleware = createSagaMiddleware();
const devTools =
  process.env.REACT_APP_env_nodeenv === 'development' ||
  process.env.REACT_APP_env_nodeenv === 'local'
    ? composeWithDevTools(applyMiddleware(sagaMiddleware))
    : applyMiddleware(sagaMiddleware);
const store = createStore(rootReducer, devTools);
sagaMiddleware.run(rootSaga);

export default store;
