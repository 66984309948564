import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import {validateDateFormat, setDateFormat, dateValidation } from '../../util/helper/Helper';
import MultipleRadiobuttonComponent from '../MultipleRadiobuttonComponent/MultipleRadiobuttonComponent';
import TextInputComponent from '../TextInputComponent/TextInputComponent';
import RadioButtonComponent from '../RadioButtomComponent/RadioButtonComponent';

const Root = styled.div`   
    min-height: 9em;
    display: flex;
    flex-direction: column; 
`;

const RadioWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2.8em;
    padding-left: 0.8em;
`;

const TextWrapper = styled.div`
`;

const ErrorMessage = styled.div`
    color: red;
`;

const ControllWrapper = styled.div`
    display: flex; 
`;

const TextRadioGroupComponent = (props) => {
    const dispatch = useDispatch(); 
    const [isChecked, setIsChecked] = useState(props.question.answer === 'Decline to Answer' ? true : false);
    const [textInput, setTextInput] = useState(props.question.answer === 'Decline to Answer' ? '' : props.question.answer);
    const [isDataValid, setIsDataValid] = useState(null);
   
    const onRadioQuestionChange = (qid) =>{
        setIsChecked(!isChecked); 
        setTextInput('');
        setIsDataValid(null);
 
        if(qid === 'radio'){        
            dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": 'Decline to Answer'} });
        }
        else{
            dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": ''} });
        }        
    }  

    const handleDateOnBlur = (e) =>{ 
        
        if(validateDateFormat(e)){           
            setTextInput(e);   
            setIsDataValid(true);             
          }
          else if(e === ''){
            setIsDataValid(null);
            return;
          }
          else{
            setIsDataValid(false);  
            return;
          }  
        setIsChecked(false);         
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": e} });      
    }

    const handleDateChange = (e) =>{
       
        let formatedData = dateValidation(e);          
    
        if(formatedData){          
          setTextInput(formatedData);          
        }
        else if(formatedData == null){
          setTextInput('');
        }
        else{
          setTextInput(e);
        }       
    } 
   
    const getPrompt = (prompts) =>{
        if(prompts && prompts.length > 0){         
            return prompts[0].value;          
        }else{
          return "";
        }
      } 
 
    return (   
        <Root className={props.className}> 
          <ControllWrapper>
                <TextWrapper>
                <TextInputComponent
                        onChange={handleDateChange}
                        onBlur={handleDateOnBlur}
                        value={textInput} 
                        type="text"
                        placeholder={props.placeholder ? props.placeholder : 'MM/DD/YYYY'}     
                        error = {isDataValid!=null && !isDataValid}  
                        required ={props.required}  
                        helperText={''}
                        isValid={isDataValid !== null && isDataValid} 
                        label ={getPrompt(props.question.prompts)}
                        width ={ 160 }                      
                />  
                </TextWrapper>                                  
                <RadioWrapper>
                    <RadioButtonComponent
                        question={props.question} 
                        onChange = {onRadioQuestionChange}
                        group ={'birthdate'}
                        key={'birthdate_rbutton'}
                        id = {isChecked ? 'date' : 'radio'}                                
                        checked={isChecked}
                        label = {'Decline to Answer'}                   
                    />
                </RadioWrapper>
             </ControllWrapper>
             {isDataValid!=null && !isDataValid && <ErrorMessage>{'Please enter a valid date using format MM/DD/YYYY'}</ErrorMessage>}
        </Root>
    );   
}

export default TextRadioGroupComponent;