import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import DesktopHeaderComponent from '../HeaderComponent/DesktopHeaderComponent';
import FooterComponent from '../FooterComponent/FooterComponent';
import RegistrationPrimaryFormContainer from '../../containers/RegistrationPrimaryFormContainer/RegistrationPrimaryFormContainer';
import RegistrationSingleFormContainer from '../../containers/RegistrationSingleFormContainer/RegistrationSingleFormContainer';
import ConfirmationComponent from '../ConfirmationComponent/ConfirmationComponent.js';
import DividerComponent from '../DividerComponent/DividerComponent';
import { ThemeContext } from 'styled-components';
import RegistrationSecondaryFormContainer from '../../containers/RegistrationSecondaryFormContainer/RegistrationSecondayFormContainer';
import RegistrationOverviewContainer from '../../containers/RegistrationOverviewContainer/RegistrationOverviewContainer';
import PulseSpinnerComponent from '../PulseSpinnerComponent/PulseSpinnerComponent';



const imgBasePath = process.env.REACT_APP_env_img_base_path;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: space-between;
  background-color: ${ props => props.theme.regBgColor };
`;

const HeaderBox = styled.div`
  background-color: ${ props => props.theme.mainTextBackground };
  display: flex;
  flex-direction: column;
  min-height: 20em;
  text-align: left;
  color: ${ props => props.theme.mainText };
  position: relative;
  left: 0;
  border-radius: 3em 3em 0 0;
  padding-top: 1em;
`;

const AboveDividerContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const VerticalDecorationContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 5%;
  margin-right: 5%;
  margin-top: 9.5%;
`;

const HorizontalDecorationContainer = styled.div`
  width: 10%;
  overflow: hidden;
`;

const BlackSquareDecoration = styled.div`
  width: 2em;
  height: 2em;
  background-color: black;
`;

const VerticalGradientDecoration = styled.div`
  width: 2em;
  height: 10em;
  background: linear-gradient(#B5B3B3, #DE4F31);
  margin-top: 2em;
`;

const HorizontalGradientDecoration = styled.div`
  width: 100%;
  height: 2em;
  background: linear-gradient(to left top, #DE4F31, #B5B3B3);
  margin-left: 2em;
`;

const TemplatedHeaderbox = styled.div`
  background: ${props => props.theme.registrationHeaderBackground};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-height: 20em;
  text-align: center;
  color: white;
  position: relative;
  left: 0;
  border-radius: 3em 3em 0 0;
  padding-top: 1em;
  padding-bottom: 5em;
`;

const CreateOpportunityConfirmationHeaderBox = styled.div`
  background: transparent linear-gradient(89deg, #27487E 0%, #124BC5CB 33%, #6294E8 60%, #F79634 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  min-height: 20em;
  text-align: center;
  color: white;
  position: relative;
  border-radius: 3em 3em 0 0;
  padding-top: 4em;
  padding-bottom: 4em;
`;

const HeaderTextContainer = styled.div`
  width: 90%;
  padding-top: 3em;
  padding-bottom: 4em;
`;

const RegHeaderText = styled.h1`
  width: 100%;
  font-family: 'Tusker Grotesk';
  font-weight: 100;
  font-size: 6em;
  letter-spacing: -.022em;
  transform: scale(1.75, 2);
  margin-bottom: .3em;
  text-align: center;
`;

const RegHeaderSubtext = styled.p`
  font-size: 3em;
  width: 100%;
  margin-bottom: 1em;
  text-align: center;
`;

const RegBlurbText = styled.p`
  margin-top: .3em;
  font-size: 1.5em;
  font-weight: 400;
  align-self: center;
  text-align: center;
`;

const RegBlurb = styled.div`
  margin-top: 1em;
  width: 100%;
  padding-left: 17%;
  padding-right: 17%;
  padding-bottom: 5em;
`;

const CardContainerTitle = styled.p`
  font-size: .8em;
  font-weight: 400;
  height: 1em;
  width: 30em;
  align-self: center;
  text-align: center;
  letter-spacing: .1em;
`;

const CardsContainer = styled.div`
  margin-bottom: 2em;
  padding: 1em;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const RegCard = styled.div`
  margin-bottom: 5em;
  width: 25em;
  padding: 2em 2em 2em 2em;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: ${ props => props.theme.regCardBorderColor };
`;

const CardTitle = styled.p`
`;

const CardImage = styled.img`
  height: 6em;
`;

const CardHeader = styled.h1`
  font-size: 2.2em;
  font-weight: 400;
`;

const CardText = styled.p`
  font-size: 1.2em;
  font-weight: 400;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

 const UpperShadow = styled.div`
  position: relative;
  border-radius: 3em;
  margin-top: 2.5em;
 `;

 const LowerShadow = styled.div`
  position: relative;
  border-radius: 3em;
  top: -2.5em;
 `;

 const CenteredRow = styled.div`
  display: flex;
  height: 45em;
  justify-content: center;
  border-radius: 3em;
  background-color: white;
`;

const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 27%;
`;

const PoweredByCatalyteContainer = styled.div`
  max-width: 50%;
  max-height: 10em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CreateOpportunityLogoContainer = styled.img`
  width: 18em;
  height: 4em;
  margin-top: 7%;
`;

const PoweredByText = styled.p`
  min-width: 7em;
`;

const CatalyteLogoContainer = styled.img`
  max-width: 8em;
  max-height: 2em;
`;

const FormInstructionWrapper = styled.ol`
  width: 64%;
  margin-bottom: 5em;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: left;
`;

const InstructionListWrapper = styled.ol`
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: left;
`;

const FormInstructionTitle = styled.p`
`;

const StyledListItem = styled.li`
  display: list-item;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
  font-size: 1.3em;
  font-weight: 400;
  letter-spacing: 0px;
  color: ${props => props.theme.primaryText};
  align-items: left; 
`;

const FormInstructionText = styled.p`
  font-size: 1.3em;
  width: 100%;
`;

const RegistrationComponent = (props) =>{
    const themeContext = useContext(ThemeContext);
    const [global, setGlobal] = useState(props.globalState);
    const config = useSelector(state => state.global && state.global.config || {"name": "default"})
    let childProps = {...props, themeContext};

    useEffect(() => {
      setGlobal(props.globalState);
    }, [props.globalState])

    const renderHeaderBox = function(){
      if((props.client === 'createoppcolumbia') && props.currentForm === 'confirmation'){
        return(
          <CreateOpportunityConfirmationHeaderBox>
            <RegHeaderText style={{marginBottom: '2em'}} dangerouslySetInnerHTML={{__html: headerText}}></RegHeaderText>
            <DividerComponent />
            <RegBlurb>
              <RegBlurbText dangerouslySetInnerHTML={{__html: blurbText}}></RegBlurbText>
            </RegBlurb>
          </CreateOpportunityConfirmationHeaderBox>
        )
      }else if(props.client === 'createoppcolumbia'){
        return(
          <TemplatedHeaderbox>
            <CreateOpportunityLogoContainer src={imgBasePath + global.config.secondaryLogo} />
            <PoweredByCatalyteContainer>
              <PoweredByText>Powered by</PoweredByText>
              <CatalyteLogoContainer src={imgBasePath + global.config.logo} />
            </PoweredByCatalyteContainer>
          </TemplatedHeaderbox>
        )
      }else{
        return(
          <HeaderBox>
            <AboveDividerContainer>
              <VerticalDecorationContainer>
                <VerticalGradientDecoration></VerticalGradientDecoration>
                <BlackSquareDecoration></BlackSquareDecoration>
              </VerticalDecorationContainer>
              <HeaderTextContainer>
                <RegHeaderText dangerouslySetInnerHTML={{__html: headerText}}></RegHeaderText>
                <RegHeaderSubtext>{headerSubtext}</RegHeaderSubtext>
              </HeaderTextContainer>
              <HorizontalDecorationContainer>
                <HorizontalGradientDecoration></HorizontalGradientDecoration>
                <BlackSquareDecoration></BlackSquareDecoration>
              </HorizontalDecorationContainer>
            </AboveDividerContainer>
            <DividerComponent />
            <RegBlurb>
              <RegBlurbText dangerouslySetInnerHTML={{__html: blurbText}}></RegBlurbText>
            </RegBlurb>
            <CardContainerTitle>{config.registration.primary.cardContainerTitle}</CardContainerTitle>
            <CardsContainer>
            {config.registration.primary.regCardImgUrl1 &&
              <RegCard>
                <CardTitle>{config.registration.primary.regCardTitle1}</CardTitle>
                <CardImage src={imgBasePath + config.registration.primary.regCardImgUrl1}></CardImage>
                <CardHeader>{config.registration.primary.regCardHeader1}</CardHeader>
                <CardText>{config.registration.primary.regCardText1}</CardText>
              </RegCard>
            }
            {config.registration.primary.regCardImgUrl2 &&
              <RegCard>
                <CardTitle>{config.registration.primary.regCardTitle2}</CardTitle>
                <CardImage src={imgBasePath + config.registration.primary.regCardImgUrl2}></CardImage>
                <CardHeader>{config.registration.primary.regCardHeader2}</CardHeader>
                <CardText>{config.registration.primary.regCardText2}</CardText>
              </RegCard>
            }
            {config.registration.primary.regCardImgUrl3 &&
              <RegCard>
                <CardTitle>{config.registration.primary.regCardTitle3}</CardTitle>
                <CardImage src={imgBasePath + config.registration.primary.regCardImgUrl3}></CardImage>
                <CardHeader>{config.registration.primary.regCardHeader3}</CardHeader>
                <CardText>{config.registration.primary.regCardText3}</CardText>
              </RegCard>
            }
            </CardsContainer>
            <FormInstructionWrapper>
              <FormInstructionTitle>{config.registration.primary.instructionTitle}</FormInstructionTitle>
              <DividerComponent marginBottom='0'/>
              <InstructionListWrapper>
              {
              (config.registration.primary.instructionBullets) &&
              renderListitems(config.registration.primary.instructionBullets)
              }
              </InstructionListWrapper>
              <FormInstructionText dangerouslySetInnerHTML={{__html: config.registration.primary.instructionText}}></FormInstructionText>
            </FormInstructionWrapper>
          </HeaderBox>
        )
      }
    }

    const renderForm = function(form){

      if(props.showLoadingSpinner){
        return (
          <CenteredRow style={{flexGrow: 1}}>
            <CenteredColumn>
              <PulseSpinnerComponent
                dotColorArray={['#F7C634', '#f6bf55', '#f7ac43', '#f79a34']}
                dotSpeedArray={[1.8, 1.8, 1.8, 1.8]}
                dotDelayArray={[0, 0.2, 0.4, 0.6]}
                size={30}
                positionArray={[5, 10]}
                borderColor={'white'}
              />
            </CenteredColumn>
          </CenteredRow>
          );          
      }
      switch(form){
        case "overview": return(
          <RegistrationOverviewContainer {...childProps} />
        )
        case "primary": return(
          <RegistrationPrimaryFormContainer {...childProps} />
        )
        case "secondary": return(
          <RegistrationSecondaryFormContainer {...childProps} />
        )
        case "single": return(
          <RegistrationSingleFormContainer {...childProps} />
        )
        case "confirmation": return(
          <ConfirmationComponent {...childProps} />
        )
        default:
          return (
            <RegistrationPrimaryFormContainer {...childProps} />
        )
      }
    }

    const renderListitems = (items) => {
      return items.map((v, i) => {
        return  <StyledListItem key={i} dangerouslySetInnerHTML={{__html: v}}></StyledListItem>
      })
    }
    
    const registrationConfig = props.globalState.config && props.globalState.config.registration || {"confirmation": {}, "primary": {}};

    const headerText = props.currentForm === 'confirmation' ? registrationConfig.confirmation.header : registrationConfig.primary.header;
    const headerSubtext = props.currentForm === 'confirmation' ? registrationConfig.confirmation.subHeader : registrationConfig.primary.subHeader;
    const blurbText = props.currentForm === 'confirmation' ? registrationConfig.confirmation.blurb : registrationConfig.primary.blurb;
    const imgBasePath = process.env.REACT_APP_env_img_base_path;

    return (
        <StyledRoot>
            <DesktopHeaderComponent logoImageUrl={imgBasePath + props.globalState.config.logo} headerText={headerText} blurbText={blurbText} showLogin={!global.cognitoUser} showLogout={global.cognitoUser} username={global.cognitoUser && global.cognitoUser.username}/>
            <StyledWrapper>
              <UpperShadow>
                { renderHeaderBox() }
              </UpperShadow>
              <LowerShadow>
                { renderForm(props.currentForm) }
              </LowerShadow>
            </StyledWrapper>
            <FooterComponent showLogin={!global.cognitoUser} showLogout={global.cognitoUser} />
        </StyledRoot>
    );
  }

export default RegistrationComponent;
