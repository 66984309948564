import React from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const KatexFormulaComponent = (props) => {
    return (
        <div className={props.className} dangerouslySetInnerHTML={{__html: katex.renderToString(props.value, { throwOnError: true})}}></div>
    );
}

export default KatexFormulaComponent;