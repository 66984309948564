import React from 'react';
import styled from 'styled-components/macro';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';

const StyledRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MobileOverviewComponent = (props) =>{

    return (
    <StyledRoot>
     {props.content}
    </StyledRoot>
    );
  }

export default MobileOverviewComponent;
