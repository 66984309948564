export const colors = {
  black: '#000000',
  white: '#ffffff',
  grey: '#ebedf0',
  lightGrey: '#4a4a4a',
  darkGrey: '#545453',
  transparentBlack: 'rgba(0,0,0,.25)',
  catalyteBlue: '#27487E',
  textboxColor: '#DEE0E5'
};
