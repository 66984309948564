import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import SecondaryButtonComponent from '../SecondaryButtonComponent/SecondaryButtonComponent';
import { ThemeContext } from 'styled-components';
import { useDispatch } from 'react-redux';
import { up } from 'styled-breakpoints';
import { getClient, getClientBaseUrl, getConfigUrl } from '../../util/helper/Helper';

const StyledRoot = styled.div`
    display: flex;
    justify-content: center;
    height: 4em;
    width: 100%;
    box-shadow: 1px 8px 35px ${props => props.theme.formShadow};
    background-color: ${props => props.theme.mainHeader};
`;

const StyledLogo = styled.img`
    width: 10.7em;
`;

const VerticalCenteringDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${props => props.width ? props.width : 'unset'};
    margin-left: .5em;
`;

const HeaderSpacerDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: .5em;
`;

const StyledLoginLText = styled.div`
    font-size: 1em;
    font-weight: 400;
    color: white;
    padding-right: .7em;
`;

const StyledContainer = styled.div`
    display: flex;
    ${up('md')} {
        width:90%;
        justify-content: space-between;
    }
`;

const Row = styled.div`
    display: flex;
`;

const StyledLoginLink = styled.a`
    font-size: 1em;
    color: white;
    text-decoration: none;
`;

const Username = styled.div`
    color: white;
    font-weight: 500;
    padding-right: 1em;
`;

//Track Select Styling:
const TrackSelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${props => props.width ? props.width : 'unset'};
`;
const TrackSelectHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
`;
const TrackSelectHeaderText = styled.span`
    font-size: 1em;
    color: white;
    text-decoration: none;
`;
const TrackSelectArrow = styled.span`
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: ${({ isOpen }) => (isOpen ? 'none' : '5px solid white')};
    transition: transform 0.3s ease;
    transform: ${({ trackSelectIsOpen }) => (trackSelectIsOpen ? 'rotate(180deg)' : 'rotate(0)')};
`;
const TrackSelectDropdown = styled.div`
    position: absolute;
    top: 8%;
    left: 69%;
    width: 12%;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
`;
const TrackSelectLink = styled.div`
    padding: 10px;
    font-size: 1em;
    color: black;
    text-decoration: none;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    background-color: ${({ highlight }) => (highlight ? '#f1f1f1' : 'transparent')};

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: #f1f1f1;
    }
`;

const DesktopHeaderComponent = (props) => {
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();

    const [username, setUsername] = useState(props.username);
    const [trackSelectIsOpen, setTrackSelectIsOpen] = useState(false);
    
    let clientName = getClient()
    let redirectTo = getClientBaseUrl()

    useEffect(() => {
        setUsername(props.username);
    }, [props.username])

    const toggleTrackSelectDropdown = () => {
        setTrackSelectIsOpen(!trackSelectIsOpen);
    };
    
    const handleTrackNavigation = (trackUrl) => {
        window.location = trackUrl;
        setTrackSelectIsOpen(false);
    };

    return (
        <StyledRoot>
            <StyledContainer>
                <VerticalCenteringDiv>
                    <StyledLogo src={props.logoImageUrl} />
                </VerticalCenteringDiv>
                {props.currentSection &&
                    <VerticalCenteringDiv width='56%'>
                    </VerticalCenteringDiv>
                }
                <HeaderSpacerDiv></HeaderSpacerDiv>
                <TrackSelectWrapper>
                    <TrackSelectHeader onClick={toggleTrackSelectDropdown}>
                        <TrackSelectHeaderText>Pathways</TrackSelectHeaderText>
                        <TrackSelectArrow trackSelectIsOpen={trackSelectIsOpen}/>
                    </TrackSelectHeader>
                    {trackSelectIsOpen &&
                        <TrackSelectDropdown>
                            <TrackSelectLink 
                                onClick={() => handleTrackNavigation(process.env.REACT_APP_env_url)}
                                highlight={clientName === 'catalyte'}>Software Development
                            </TrackSelectLink>
                            <TrackSelectLink 
                                onClick={() => handleTrackNavigation(getConfigUrl('digmedia'))}
                                highlight={clientName === 'digmedia'}>Digital Media
                            </TrackSelectLink>
                            <TrackSelectLink 
                                onClick={() => handleTrackNavigation(getConfigUrl('serveng'))}
                                highlight={clientName === 'serveng'}>IT Services
                            </TrackSelectLink>
                            <TrackSelectLink 
                                onClick={() => handleTrackNavigation(getConfigUrl('cybersec'))}
                                highlight={clientName === 'cybersec'}>Cyber Security
                            </TrackSelectLink>
                            <TrackSelectLink 
                                onClick={() => handleTrackNavigation(getConfigUrl('sales'))}
                                highlight={clientName === 'sales'}>Sales
                            </TrackSelectLink>
                            <TrackSelectLink 
                                onClick={() => handleTrackNavigation(getConfigUrl('projman'))}
                                highlight={clientName === 'projman'}>Project Management
                            </TrackSelectLink>
                        </TrackSelectDropdown>
                    }
                </TrackSelectWrapper>
                <VerticalCenteringDiv>
                    {props.showLogout ?
                    <Row>
                        <VerticalCenteringDiv>
                            <Username>{username}</Username>
                        </VerticalCenteringDiv>
                        <VerticalCenteringDiv>
                            <StyledLoginLink onClick={() => { dispatch({ type: 'LOG_OUT' }) }}>
                                <SecondaryButtonComponent size={'small'} color={themeContext.headerButtonTextColor} borderColor={themeContext.headerButtonBorderColor} backgroundColor={themeContext.headerButtonBgColor} onClick={() => { }} text={'Log Out'}></SecondaryButtonComponent>
                            </StyledLoginLink>
                        </VerticalCenteringDiv>
                    </Row>
                    :
                    <Row>
                        <VerticalCenteringDiv>
                            <StyledLoginLText>
                                Already registered?
                            </StyledLoginLText>
                        </VerticalCenteringDiv>
                        <VerticalCenteringDiv>
                            <StyledLoginLink href={process.env.REACT_APP_env_sso_redirect_url + '?redirectTo=' + redirectTo}>
                                <SecondaryButtonComponent color={themeContext.headerText} borderColor={themeContext.headerButtonBorderColor} backgroundColor={themeContext.headerButtonBgColor} onClick={() => { }} text={'Log In'}></SecondaryButtonComponent>
                            </StyledLoginLink>
                        </VerticalCenteringDiv>
                    </Row>
                    }
                </VerticalCenteringDiv>
            </StyledContainer>
        </StyledRoot>
    )
}

export default DesktopHeaderComponent;