import 'react-app-polyfill/ie11';
import React from 'react';
import 'babel-polyfill';
import AppComponent from './components/AppComponent/AppComponent';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import "./index.css";

if(process.env.REACT_APP_env_nodeenv !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_env_sentry_dsn,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_env_nodeenv,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25,
  });
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_env_ld_client_key,    
    options: {},
    user: {
      key: 'screen'
    }
  });
  
  render(
    <LDProvider>
      <AppComponent />
    </LDProvider>,
    document.getElementById('root'),
  );  
})();

