import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components/macro';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';

export const StyledText = styled('p')`
  font-family: ${(props) => `${props => props.theme.fontFamily}`};
  color: ${props => props.primaryText};
`;

function LogoutNotification(props) {
  const { open, handleCancel, inactivityTimeout } = props;
  const inactivityMinutes = Math.round(inactivityTimeout / 1000 / 60);

  const handleOnClose = (e, reason) => {
    if(reason === 'backdropClick'){
      e.preventDefault();
      return;
    }
  }

  return (
    <Dialog
      onClose={handleOnClose}
      disableEscapeKeyDown
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}>
      <DialogTitle
        disableTypography
        id="confirmation-dialog-title">
        Session Expired
      </DialogTitle>
      <DialogContent>
        <StyledText>
          You are about to be logged out after {inactivityMinutes} minutes of inactivity. Click
          Cancel to continue your session.
        </StyledText>
      </DialogContent>
      <DialogActions>
        <PrimaryButtonComponent
          id="cancel-logout"
          onClick={handleCancel}
          text="Cancel"
        />
      </DialogActions>
    </Dialog>
  );
}

export default LogoutNotification;
