import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MobileSecondaryFormComponent from '../../components/RegistrationComponent/MobileSecondaryFormComponent';
import SecondaryFormComponent from '../../components/RegistrationComponent/SecondaryFormComponent';
import { getClientBaseUrl } from '../../util/helper/Helper';
import { Auth } from 'aws-amplify';

const RegistrationSecondaryFormContainer = (props) =>{
    let secondaryFormProps = {...props};
    let redirectTo = getClientBaseUrl()

    const dispatch = useDispatch();
   
    //** Recaptcha **/
    const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
          window.grecaptcha
            .execute("6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C", { action: "registration" })
            .then(token => {
            });
        })
    }

    useEffect(() => {
        if(process.env.REACT_APP_env_cookie_domain === 'catalyte.io'){
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js?render=6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C";
            script.id = "g-recaptcha-script";
            script.addEventListener("load", handleLoaded);
            document.body.appendChild(script);
        }
    }, [])

    //** Field value state **/
    const [password, setPassword] = React.useState(props.password);
    const [confirmedPassword, setConfirmedPassword] = React.useState(props.confirmedPassword);
    const [consent, setConsent] = React.useState(props.consent); 
    const [signup, setSignup] = React.useState(props.signup);
    const [email, setEmail] = useState(props.email);  
    const [isShowPassword, setIsShowPassword]= useState(false);
    const [isShowPWEyeButton, setIsShowPWEyeButton]= useState(null);
    const [isShowCPWEyeButton, setIsShowCPWEyeButton]= useState(null);    
    const [global, setGlobal] = useState(props.globalState);

    //** Field validator functions **/
    const validatePassword = secondaryFormProps.validatePassword = (v) => {
        if(!v){
            return false;
        }

        const validLength = v.length > 7;
        const hasNums = /\d/.test(v);
        const hasSpecial = !/^[A-Za-z0-9]*$/.test(v);
        const hasMixedCase = /[a-z]/.test(v) && /[A-Z]/.test(v);

        let validPassword = validLength && hasNums && hasSpecial && hasMixedCase;
        return validPassword;
    }

    const validateConsent = secondaryFormProps.validateConsent = (v) => {
        if(!v || v !== 'agree'){
            return false;
        }
        return true;
    }

    const validateEmail = secondaryFormProps.validateEmail = (email) => {
        let validEmail = !!email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        return validEmail;
      };

    const getIsValid = secondaryFormProps.getIsValid = (isSubmitAction) => {
        let cognitoUser = global && global.cognitoUser;

        if(!cognitoUser){
            let passwordIsValid;
            let confirmedPasswordIsValid;
            let consentIsValid;
            let emailIsValid;

            if (email && props.userExists === null) {
                props.debouncedEmailCheck(email);
              }
     
            if(isSubmitAction){
                emailIsValid = validateEmail(email);
                setValidEmail(emailIsValid);
                passwordIsValid = validatePassword(password);
                setValidPassword(passwordIsValid);
                confirmedPasswordIsValid = (confirmedPassword === password) && confirmedPassword;
                setValidConfirmedPassword(confirmedPasswordIsValid);
                consentIsValid = validateConsent(consent);
                setValidConsent(consentIsValid);           
            }else{
                emailIsValid = validateEmail(email);
                passwordIsValid = validatePassword(password);
                confirmedPasswordIsValid = confirmedPassword === password;
                consentIsValid = validateConsent(consent);
            }

            if (email && props.userExists === null) {
                props.debouncedEmailCheck(email);
            }
     
            return passwordIsValid && confirmedPasswordIsValid && consentIsValid && emailIsValid && (props.userExists === false || secondaryFormProps.globalState.cognitoUser);
        }else{
            let consentIsValid;      
     
            if(isSubmitAction){
                consentIsValid = validateConsent(consent);
                setValidConsent(consentIsValid);           
            }else{
                consentIsValid = validateConsent(consent);    
            }
     
            return consentIsValid;
        }
    }

    //** Field Validation state **/
    const [isValidPassword, setValidPassword] = React.useState(null);
    const [isValidConfirmedPassword, setValidConfirmedPassword] = React.useState(null);
    const [isValidConsent, setValidConsent] = React.useState(consent);
    const [isSubmitAction, setIsSubmitAction]= useState(false);
    const [isValidEmail, setValidEmail] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [isFirstPasswordAttempt, setIsFirstPasswordAttempt] = useState(true);
    const regState = useSelector((state) => state.registration);

    //** vvv Google Sign in vvv **/

    const fedSignIn = () => {
        console.log('signing in with Google')
        localStorage.setItem('ssoRegState', JSON.stringify({...regState, consent, ts: Date.now()}));
        let redirectTo = getClientBaseUrl()

        dispatch({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: true } });

        window.location.replace(`${process.env.REACT_APP_env_sso_redirect_url}?federatedSignIn=Google&redirectTo=${redirectTo}registration`);

        dispatch({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: false } });
    };

    //** ^^^ Google Sign in ^^^ **/
    
    /** Props */
    secondaryFormProps.password = password;
    secondaryFormProps.confirmedPassword = confirmedPassword;
    secondaryFormProps.consent = consent;
    secondaryFormProps.signup = signup;
    secondaryFormProps.email = email;
    secondaryFormProps.isValidPassword = isValidPassword;
    secondaryFormProps.isValidConfirmedPassword = isValidConfirmedPassword;
    secondaryFormProps.isValidConsent = isValidConsent;
    secondaryFormProps.isSubmitAction = isSubmitAction;
    secondaryFormProps.isValidEmail = isValidEmail;
    secondaryFormProps.isValid = isValid;
    secondaryFormProps.isFirstPasswordAttempt = isFirstPasswordAttempt;
    secondaryFormProps.fedSignIn = fedSignIn;

    /** Helpers */
    secondaryFormProps.getPasswordHelperText = (v, validCognitoPassword) => {
        let helperText = '';
        if(!v) {
            if(validCognitoPassword !== null && !validCognitoPassword) {
                helperText = validCognitoPassword ? helperText : 'Password is too simple. Please choose another password.';
                return helperText;
            }
            return 'Password is required.'; 
        }
        const invalidLengthMsg = 'Password must be at least 8 characters.\n';
        const invalidNums = 'Password must contain at least one number.\n';
        const invalidSpecial = 'Password must contain at least one special character.\n';
        const invalidCase = 'Password must contain upper and lower case characters.';

        const validLength = v.length > 7;
        const hasNums = /\d/.test(v);
        const hasSpecial = !/^[A-Za-z0-9]*$/.test(v);
        const hasMixedCase = /[a-z]/.test(v) && /[A-Z]/.test(v);

        helperText = validLength ? helperText : helperText + invalidLengthMsg;
        helperText = hasNums ? helperText : helperText + invalidNums;
        helperText = hasSpecial ? helperText : helperText + invalidSpecial;
        helperText = hasMixedCase ? helperText : helperText + invalidCase;

        return helperText;
    }

    //** Field change handlers **/

    const emailId = 'email-input'
    const pwdId = 'pwd-input'
    const cnfrmId = 'cnfrm-input'

    secondaryFormProps.onPasswordChange = (v,f) => {
        if(f) setIsShowPWEyeButton(true);  
        setPassword(v);
        if(!isFirstPasswordAttempt){          
            if(isValidConfirmedPassword !== null){
                setValidConfirmedPassword(v === confirmedPassword);
            }
        }

        if (document.activeElement.id !== pwdId || isValidPassword !== null) {
            setValidPassword(validatePassword(v));
          }
    }

    secondaryFormProps.onConfirmedPasswordChange = (v,f) => {
        if(f) setIsShowCPWEyeButton(true);  
        setConfirmedPassword(v);
        setValidConfirmedPassword(v === password);

        if (document.activeElement.id !== cnfrmId || isValidConfirmedPassword !== null) {
            setValidConfirmedPassword(validatePassword(v));
          }
    } 

    secondaryFormProps.onConsentChange = (v) => { 
        let newV = consent === 'agree' ? 'no' : 'agree';       
        setValidConsent(validateConsent(newV));
        setConsent(newV);
    }

    secondaryFormProps.onSignupChange = (v) => {
        console.log('Signup changed to ' + v);
        let newV = signup === 'useEmail' ? 'useSSO' : 'useEmail';
        setSignup(newV);
    }

    secondaryFormProps.onEmailChange = (v) => {
        if (secondaryFormProps.globalState.cognitoUser) {
        return
        }

        setEmail(v);

        if (document.activeElement.id === emailId || isValidEmail !== null) {
            setValidEmail(validateEmail(v));
        }
    };

    //** Field blur handlers **/
    secondaryFormProps.onPasswordBlur = (v) => {
        setIsFirstPasswordAttempt(false);        
        setValidPassword(validatePassword(v));
    }

    secondaryFormProps.onConfirmedPasswordBlur = (v) => {       
          setValidConfirmedPassword(v === password);        
    }

    secondaryFormProps.onEmailBlur = (v) => {
        setValidEmail(validateEmail(v));
        dispatch({ 'type': 'COGNITO_USER_EXISTS', data: null });
        props.debouncedEmailCheck(email);
      }

    //** Key Handlers **//
    secondaryFormProps.handleOnKeyDown = (e) => {
        if(isValid && (e.key === "Enter")){
            secondaryFormProps.handleSubmit(e);
          }
    }

    //** Click Handlers **//
    secondaryFormProps.handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitAction(true);
        if(getIsValid(true)){ 
            props.onClickSubmit({ consent, password, email });
        }
    }   

    secondaryFormProps.handleBack = (e) => {
        e.preventDefault();
        props.onClickBack( { consent } );
    } 

    secondaryFormProps.handleEyeClick =(e) =>{ 
        setIsShowPassword(!isShowPassword);
    } 

    secondaryFormProps.onPWFocus =(id) =>{ 
        if(id === 'pwd-input' || id === 'pwd-inputeye'){
          setIsShowPWEyeButton(true);
          setIsShowCPWEyeButton(false);          
        }
        else if(!id)
        {  
            setIsShowPWEyeButton(false);
            setIsShowCPWEyeButton(false)
        } 
    }

    secondaryFormProps.onCPWFocus =(id) =>{
        if(id ==='cnfrm-input' || id === 'cnfrm-inputeye'){
            setIsShowCPWEyeButton(true);
            setIsShowPWEyeButton(false);           
        }
        else if(!id)
        {  
            setIsShowPWEyeButton(false);
            setIsShowCPWEyeButton(false);   
        }                           
    } 
   
    secondaryFormProps.passwordType = isShowPassword ? 'text' : 'password';
    secondaryFormProps.confirmPasswordType = isShowPassword ? 'text' : 'password';
    secondaryFormProps.isShowPWEyeButton = isShowPWEyeButton;
    secondaryFormProps.isShowCPWEyeButton = isShowCPWEyeButton;

    //** Helpers **//
    secondaryFormProps.getConsentHelperText = (v) => {
        if(!v || v != 'agree'){
            return 'Please read and agree to the terms and conditions.'
        
        }else{
            return '';
        }
    }

    secondaryFormProps.getEmailHelper = () => {
        if (props.userExists && validateEmail(email)) {
          return <p>Email already in use. Please <a href={process.env.REACT_APP_env_sso_redirect_url + '?redirectTo=' + redirectTo}>log in</a> or try another email.</p>;
        }
        if (!isValidEmail) {
          return 'Please enter a valid email address';
        }
        return '';
      }

    useEffect(() => {
        setIsValid(getIsValid());
    }, [password, confirmedPassword, consent, email, props.userExists, getIsValid])

    useEffect(() => {
        if(regState.validCognitoPassword !== null && !regState.validCognitoPassword) {
            setValidPassword(regState.validCognitoPassword);
            setValidConfirmedPassword(null);
            setPassword(props.password);
            setConfirmedPassword(props.confirmedPassword);
            setConsent(null);
            window.scrollTo(0, 0);
        }
    }, [regState.validCognitoPassword]);

    useEffect(() => {
        setGlobal(props.globalState);
    }, [props.globalState])

    useEffect(() => {
        setEmail(props.email);

        if (document.activeElement.id === emailId || isValidEmail !== null) {
            setValidEmail(validateEmail(props.email));
        }

      }, [props.email])

    useEffect(() => {
    if (props.userExists) {
        setValidEmail(false);
    }
    }, [props.userExists])

    if(props.isSmallScreen){
        return (
          <MobileSecondaryFormComponent {...secondaryFormProps}></MobileSecondaryFormComponent>
        )
      }else{
        return(
          <SecondaryFormComponent {...secondaryFormProps}></SecondaryFormComponent>
        )
      } 
  }

export default RegistrationSecondaryFormContainer;
