import React from 'react';
import styled from 'styled-components/macro';
import chrome from '../../images/browsers/chrome.png';
import edge from '../../images/browsers/edge.png';
import firefox from '../../images/browsers/firefox.png';
import safari from '../../images/browsers/safari.png';
import android from '../../images/os/android.png';
import apple from '../../images/os/apple.png';


const StyledError = styled.div`
  border-bottom: 2.5em solid #3e549c;
  display: flex;
  padding: 3em;
  color: #1b3e77;
  flex-direction: column;
`;

const ImageWrapper = styled.img`
  max-width: 20em;
  margin-bottom: 3em;
  margin-left: 3em;
  width: 33%;
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-align: center;
  align-items: center;
`;

const StyledContent = styled.div`
  margin-top: 3em;
  width: 60%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  width: 100%;
  height: 2.5em;
  background-color: #3e549c;
  color: white;
  text-align: center;
  padding-top: .5em;
  font-weight: 600;
`;

const StyledP = styled.p`
  margin-bottom: 10px;
  font-weight: bold;
`;

const StyledBrowsersContainer = styled.div`
 display: flex;
`;

const StyledOSContainer = styled.div`
 display: flex;
`;

const StyledLogoContainer = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: space-between;
 margin-right: 10%;
`;

const StyledLogo = styled.img`
 width: 4vw;
 margin-bottom: 15px;
`;

export const UnsupportedBrowserComponent = (props) => {
    return (
        <StyledRoot>
            <StyledContent>
                <ImageWrapper src='/images/catalyte-logo.png'></ImageWrapper>
                <StyledTitle>Unsupported Browser</StyledTitle>
                <StyledError>
                    <p>
                        <b>The browser you are using is not supported by the screening application.<br /><br />
                            Please see below for a full list of supported devices and browsers.
                        </b>
                    </p>
                    <StyledP>Desktop Browsers</StyledP>
                    <StyledBrowsersContainer>
                        <StyledLogoContainer>
                            <StyledLogo src={chrome} />
                            <div>Chrome</div>
                        </StyledLogoContainer>
                        <StyledLogoContainer>
                            <StyledLogo src={firefox} />
                            <div>FireFox</div>
                        </StyledLogoContainer>
                        <StyledLogoContainer>
                            <StyledLogo src={edge} />
                            <div>Microsoft Edge</div>
                        </StyledLogoContainer>
                        <StyledLogoContainer>
                            <StyledLogo src={safari} />
                            <div>Safari</div>
                        </StyledLogoContainer>
                    </StyledBrowsersContainer>
                    <StyledP>Mobile/Tablet Devices</StyledP>
                    <StyledOSContainer>
                        <StyledLogoContainer>
                            <StyledLogo src={android} />
                            <div>Android</div>
                        </StyledLogoContainer>
                        <StyledLogoContainer>
                            <StyledLogo src={apple} />
                            <div>iOS</div>
                        </StyledLogoContainer>
                    </StyledOSContainer>
                </StyledError>
            </StyledContent>
        </StyledRoot>
    )
}

export default UnsupportedBrowserComponent;