import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import RadioButtonComponent from '../RadioButtomComponent/RadioButtonComponent';
import { up } from 'styled-breakpoints';

const StyledRoot = styled.div`
    display: flex;    
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    height: 100%;    
`;

const RadioGroup = styled.div`    
    display: flex;
    margin-top: auto;
    padding-top: 1.5em;    
    flex-direction: column;
    justify-content: space-around;    
`;

const PromptText = styled.div`
    display: flex;
    color: ${ props => props.theme.label };
`;

const StyledPrompt = styled.div`
    display: flex;
    width: 100%;    
    align-content: baseline;    
`;

const RadioWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    line-height: 2em;
    margin-top: .3em;
`;

const RadioButtonLabel = styled.div`
    max-width: 12em;
    flex-wrap: wrap;
    display: flex;
    color: ${ props => props.theme.label };
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5em;
    padding-left: .7em;
    overflow: overlay;
`;

const SelectedRadioButtonLabel = styled(RadioButtonLabel)`
    color: ${ props => props.theme.accentText };
`;

const StyledRadioButtonComponent = styled(RadioButtonComponent)`
    margin-right: .5em;    
`;

const StyledImagePrompt = styled.img`
    width: 100%;
    border-radius: 20px;    
`;

const StyledPuzzleImagePrompt = styled.img`
    width: 100%;
    border-radius: 20px;
`;

const StyledImageOption = styled.img`
    max-width: 4em;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5em;
`;

const StyledPuzzleImageOption = styled.img`
    max-width: 4em;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5em;
`;

const SelectedPuzzleImageOption = styled.img`
    max-width: 4em;
    display: flex;
    color: ${ props => props.theme.accentText };
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5em;
    position: relative;
    top: -3px;
`;

const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const LargeImageQuestionComponent = (props) => {
    const dispatch = useDispatch();

    const handleChange = (questionOptionID) => {
        const selectedOption = props.question.options.filter((v, i) => {
            return v.questionOptionID === parseInt(questionOptionID);
        })[0];
        
        const selectedValue = (selectedOption.valueType === "img") || (selectedOption.valueType === "img.puzzle")
            ? selectedOption.optionsUrl : selectedOption.value
        
        const currentVal = props.question.answer;

        const newVal = selectedValue === currentVal ? "" : selectedValue;

        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": newVal} });
    };

    const getListItems = () => {
        const selectionOptions = props.question;
        let orderedOptions = selectionOptions.options.sort((a, b) => (a.order > b.order) ? 1 : -1)
        const listElements = orderedOptions.map((option, i) => {
            const isChecked = props.question.answer && (props.question.answer === option.value || props.question.answer === option.optionsUrl);
            return(
                <RadioWrapper key={option.questionOptionID + '_wrapper'}>
                    <StyledRadioButtonComponent key={option.questionOptionID + '_button'} onChange={handleChange} id={option.questionOptionID} group={props.question.questionID} checked={isChecked}></StyledRadioButtonComponent>
                    {getLabel(i, isChecked, option)}
                </RadioWrapper>
            )
        })
        return listElements;
    }

    const getLabel = (index, isChecked, option) => {
        if(option.valueType === 'img'){
            return(<StyledImageOption key={'option_'+index} src={imgBasePath+option.optionsUrl}></StyledImageOption>);
        }else if(option.valueType === 'img.puzzle'){
            return(<StyledPuzzleImageOption key={'option_'+index} src={imgBasePath+option.optionsUrl}></StyledPuzzleImageOption>);
        }else if(option.valueType === 'text' || option.valueType === '#text'){
            if(isChecked){
                return(<SelectedRadioButtonLabel key={'option_'+index} dangerouslySetInnerHTML={{__html: option.value}}></SelectedRadioButtonLabel>);
            }else{
                return(<RadioButtonLabel key={'option_'+index} dangerouslySetInnerHTML={{__html: option.value}}></RadioButtonLabel>);
            }
        }
    }

    const getPrompt = (prompts) => {
        return prompts
        .sort((a, b) => parseInt(a.order) < parseInt(b.order) ? -1 : 1)
        .map((v, i) => {
            switch(v.valueType){
                case "#text":
                    return (<PromptText key={'prompt_'+i} style={{}} dangerouslySetInnerHTML={{__html: v.value}}></PromptText>);
                case "img":
                    return (<StyledImagePrompt key={'prompt_'+i} src={imgBasePath+v.url}></StyledImagePrompt>);
                case "img.puzzle":
                    return (<StyledPuzzleImagePrompt key={'prompt_'+i} src={imgBasePath+v.url}></StyledPuzzleImagePrompt>);
                default:
                    return (<PromptText key={'prompt_'+i} dangerouslySetInnerHTML={{__html: v.value}}></PromptText>);
            }
        })
    }

    return (
        <StyledRoot className={props.className}>
            <StyledPrompt>
                {getPrompt(props.question.prompts)}
            </StyledPrompt>
            <RadioGroup>
                {getListItems()}
            </RadioGroup>
        </StyledRoot>
    );
}

export default LargeImageQuestionComponent;
