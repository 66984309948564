import React from 'react';
import styled from 'styled-components/macro';
import { down } from 'styled-breakpoints';

const InfoContainer = styled.div`
    display: flex;
    color: white;
    ${(down('md'))}{
        flex-direction: column; 
        padding: 0.5em 0.5em 0.5em 0;                           
    }  
`;

const LabelText = styled.div`
    margin-right: 0.5em;
`;

const InfoLink = styled.a`
    color: white;
    text-decoration: none;
`;

const FooterContactInfoComponent = (props) => {
    return(
        <InfoContainer>
            <LabelText>Questions?</LabelText>
            <InfoLink href={`mailto:${props.contactInfo}`}>{props.contactInfo}</InfoLink>
        </InfoContainer>
    )
}

export default FooterContactInfoComponent;