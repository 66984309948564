import React from 'react';
import styled from 'styled-components/macro';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';

const DesktopRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MobileRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const OverviewComponent = (props) =>{

    if(props.isSmallScreen){
      return(
        <MobileRoot>
          {props.content}
        </MobileRoot>
      )
    }else{
      return(
        <DesktopRoot>
          {props.content}
        </DesktopRoot>
      )
    }
  }

export default OverviewComponent;
