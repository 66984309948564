import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components/macro';

const StyledRoot = styled.div`
`;

const getPrompts = (prompts) =>{
  if(!prompts){
    return(<div></div>)
  }
 
  return(
    prompts.map((prompt, i) =>{
        return (<Typography key={'prompt_'+i} variant="body1" color="inherit">{prompt.value}</Typography>);
    })
  );
}

const ParagraphComponent = (props) => {
    return (
        <div>
            <StyledRoot>
                {getPrompts(props.question.prompts)}
            </StyledRoot>
        </div>
    );
}

export default ParagraphComponent;