import React from 'react';
import styled from 'styled-components/macro';

const StyledRoot = styled.div`
    margin: 2em -1.5em 2em;    
    background-color: #7283A2;
    border-radius: 25px;  
`;

const StyledTable = styled.div`
    display: flex;
    flex-direction: column;                  
    color: white;
    border: 5px solid #fff;
    border-bottom: 0;
    border-radius: 25px 25px 0 0;            
    position: relative;
    margin-top: -20px;
    :first-child {
        margin-top: 0;
    }    

    :last-child{
        border-bottom: 5px solid #FFF; 
        border-radius: 25px;    
        > div:last-child > div:first-child{
            border-radius: 0 0 0 25px;
        }   
        > div:last-child > div:last-child{
            border-radius: 0 0 25px 0;
        }   
    }
`

const StyledHeaderRow = styled.div`
    display: flex;    
    border-radius: 25px 25px 0 0;    
    border-bottom: 5px solid #f4f5f8;  
    > div:first-child {
        background-color: #465E85D5;
        border-radius: 25px 0 0 0;    
    }
    > div:last-child {
        background-color: #5d7394;
        border-radius: 0 25px 0 0;            
    }
`;

const StyledHeaderCell = styled.div`
    display: flex;        
    align-items: center;    
    color: white;    
    padding: 1em;   
    font-size: 17px;
    font-weight: bold;
    :first-child {
        width: 35%;
        min-width: 35%;
    }
    :last-child {
        flex: 1;
    }
`;

const StyledRow = styled.div`
    display: flex;    
    border-bottom: 1px solid white;
    
    > div:first-child {
        font-size: 16px;
        font-weight: bold;
        background-color: #7283A2;        
    }
    > div:last-child {
        font-size: 18px;
        background-color: #8493ad;        
    }
    :last-child {
        border-bottom: none;      
        > div {
            padding-bottom: 50px;
        }  
    }
`;

const StyledRowCell = styled.div`   
    display: flex;    
    justify-content: flex-start;    
    align-items: center;     
    padding: 1em;        
    :first-child {
        width: 35%;
        min-width: 35%;        
    }
    :last-child {
        flex: 1
    }
`;


const MobileChartComponent = (props) => {
    let chart = JSON.parse(props.chart);
    
    return <StyledRoot>
                {chart.rows.map((row, i) =>    
                    <StyledTable key={`chart_table_${i}`} >
                        <StyledHeaderRow>
                            <StyledHeaderCell dangerouslySetInnerHTML={{__html: chart.headers[0]}}></StyledHeaderCell>
                            <StyledHeaderCell dangerouslySetInnerHTML={{__html: row.cells[0].text}}></StyledHeaderCell>
                        </StyledHeaderRow>
                            {chart.headers.filter((_, idx) => idx!==0)
                                .map((header, index) => <StyledRow key={`chart_header_row_${i}_${index}`}>
                                    <StyledRowCell dangerouslySetInnerHTML={{__html: header}}></StyledRowCell>
                                    <StyledRowCell dangerouslySetInnerHTML={{__html: row.cells[index+1].text}}></StyledRowCell>
                                </StyledRow>)}
                    </StyledTable>                          
                )}            
            </StyledRoot>     
}

export default MobileChartComponent;
