const sectionTimerReducer = (state = { started: false, timeRemaining: 0, timeLeft: 0, startTime: null, minitLimit: 0 }, action ) => {

    switch(action.type){
            case "SET_TIMER":
                return action.data;
            case "UPDATE_TIMER": {
                let newState = JSON.parse(JSON.stringify(state))
                const timeChange = newState.startTime + newState.minitLimit*60*1000 - Date.now(); 
                let timeLeft = {minutes: 0, seconds: 0};

                if (timeChange > 0) {
                  timeLeft.minutes = Math.floor((timeChange / 1000 / 60) % 60); 
                  timeLeft.seconds = Math.floor((timeChange / 1000) % 60);
                  newState.timeRemaining = newState.timeChange;
                }

                newState.timeLeft = timeLeft;
                return newState;
            }
            default:
                    return state;
        }
    };
    
export default sectionTimerReducer;
        