import React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import OverviewComponent from '../../components/RegistrationComponent/OverviewComponent';
import MobileOverviewComponent from '../../components/RegistrationComponent/MobileOverviewComponent';
import MobileOverviewContent from '../../components/RegistrationComponent/MobileOverviewContent';
import OverviewContent from '../../components/RegistrationComponent/OverviewContent';
import {Redirect} from 'react-router-dom';
import reactDom from 'react-dom';


const RegistrationOverviewContainer = (props) =>{ 
  const config = useSelector(state => state.global && state.global.config || {"name": "default"})

  const renderMobileContent = () => {
      return(
        <MobileOverviewContent onClickSubmit={props.onClickSubmit}/>
      )
  }

  const renderDesktopContent = () => {
      return(
        <OverviewContent onClickSubmit={props.onClickSubmit}/>
      )
  }

  if(config.name === 'default'){
    return <Redirect to='/registration' />
  }

  if(props.isSmallScreen){
    return(
      <MobileOverviewComponent content={renderMobileContent()}></MobileOverviewComponent>
    )
  }else{
    return(
      <OverviewComponent content={renderDesktopContent()}></OverviewComponent>
    )
  }
}

export default RegistrationOverviewContainer;
