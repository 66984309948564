import { Auth } from 'aws-amplify';

export const ACCESS_TOKEN_KEY = 'accessToken';
export const ID_TOKEN_KEY = 'idToken';
export const getToken = async (key) => {
  if (process.env.REACT_APP_env_use_sso === 'true') {
    const session = await Auth.currentSession();   
    return session.idToken.jwtToken;
  }
  return this.getSession(key);
};