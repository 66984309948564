import React from 'react';
import MobileHeaderComponent from './MobileHeaderComponent';
import DesktopHeaderComponent from './DesktopHeaderComponent';
import { useMediaQuery } from 'react-responsive';

const HeaderComponent = (props) => {

    const isBigScreen = useMediaQuery({ query: '(min-width: 1160px)' });

    if(isBigScreen){
        return <DesktopHeaderComponent {...props}></DesktopHeaderComponent>
    }else{
        return <MobileHeaderComponent {...props}></MobileHeaderComponent>
    }
}

export default HeaderComponent;