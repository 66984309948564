import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import SecondaryButtonComponent from '../SecondaryButtonComponent/SecondaryButtonComponent';
import { useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { up, down } from 'styled-breakpoints';
import { useLocation } from 'react-router-dom'
import { getClientBaseUrl } from '../../util/helper/Helper';

const StyledRoot = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 4.5em;
    width: 100%;
    box-shadow: 1px 8px 35px ${props => props.theme.formShadow};
    background-color: ${props => props.theme.main};
    padding: 0 2em;        
    ${up('md')} {
        margin-left: 0;    
      }
`;

const StyledLogo = styled.img`
    width: 107px;
`;

const VerticalCenteringDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledLoginLText = styled.div`
    color: white;
    padding-right: .5em;
    text-align: -webkit-center;
    @media (max-width: 400px) {
        width: min-content;
    }
`;

const StyledContainer = styled.div`
    display: flex;        
    width: 100%;
    justify-content: space-between;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const StyledLoginLink = styled.a`
    font-weight: bold;
    color: white;
    text-decoration: none;
`;

const Username = styled.div`
    color: white;
    font-weight: 500;
`;

const BriefcaseIconWrapper = styled.div`
    display: none;
    ${down('md')} {
        display: block;
        border-radius: 50%;
        background: linear-gradient(171deg, rgba(92,123,173,1) 8%, rgba(42,75,128,1) 100%); 
    }
`;

const BriefcaseIcon = styled.div`
display: none;
${down('md')} {
    display: block;
    width: 37px;
    height: 34px;
    border: 3px solid #5F80B5;
    padding: 20px;
    border-radius: 50%;
    background-image: url(./images/briefcase.svg);
    background-size: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 1;
}
`;

const MobileHeaderComponent = (props) => {
    const { currentSection } = props;
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();
    const [username, setUsername] = useState(props.username);
    const location = useLocation();
    const showBriefcase = currentSection && currentSection.name === "Employment History" ? true : false;

    let redirectTo = getClientBaseUrl()

    useEffect(() => {
        setUsername(props.username);
    }, [props.username])

    return (
        <StyledRoot>
            <StyledContainer>
                {location.pathname === "/home" &&
                    <>
                        <VerticalCenteringDiv>
                            <StyledLogo src={props.logoImageUrl} />
                        </VerticalCenteringDiv>
                        {showBriefcase && <BriefcaseIconWrapper><BriefcaseIcon /></BriefcaseIconWrapper>}
                    </>
                }

                {
                    props.showLogin &&
                    <>
                        <VerticalCenteringDiv>
                            <StyledLogo src={props.logoImageUrl} />
                        </VerticalCenteringDiv>
                        <VerticalCenteringDiv>
                            <Row>
                                <VerticalCenteringDiv>
                                    <StyledLoginLText>
                                        Already registered?
                                    </StyledLoginLText>
                                </VerticalCenteringDiv>
                                <VerticalCenteringDiv>
                                    <StyledLoginLink href={process.env.REACT_APP_env_sso_redirect_url + '?redirectTo=' + redirectTo}>
                                        <SecondaryButtonComponent size={'small'} color={themeContext.headerText} backgroundColor={themeContext.main} borderColor={'white'} onClick={() => { }} text={'Log In'}></SecondaryButtonComponent>
                                    </StyledLoginLink>
                                </VerticalCenteringDiv>
                            </Row>
                        </VerticalCenteringDiv>
                    </>
                }
                {
                    props.showLogout &&
                    <Row>
                        <VerticalCenteringDiv>
                            <StyledLogo src={props.logoImageUrl} />
                        </VerticalCenteringDiv>
                        <VerticalCenteringDiv style={{ paddingRight: '1em' }}>
                            <Row>
                                <VerticalCenteringDiv>
                                    <Username>{username}</Username>
                                </VerticalCenteringDiv>
                                <StyledLoginLink onClick={() => { dispatch({ type: 'LOG_OUT' }) }}>
                                    <SecondaryButtonComponent size={'small'} color={themeContext.headerText} backgroundColor={themeContext.main} borderColor={'white'} gradientBorder={true} onClick={() => { }} text={'Log Out'}></SecondaryButtonComponent>
                                </StyledLoginLink>
                            </Row>
                        </VerticalCenteringDiv>
                    </Row>
                }
            </StyledContainer>
        </StyledRoot>
    )
}

export default MobileHeaderComponent;