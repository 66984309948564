import { put, takeEvery } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import apiCall from '../../util/axiosAPIRequest/axiosAPIRequest';
import { getClientBaseUrl } from '../../util/helper/Helper';

//Cognito user registration//
export function* registerUserCognito(action){
    yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: true } });

    try {
      yield Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }

    let recaptchaToken;

    if(process.env.REACT_APP_env_cookie_domain === 'catalyte.io'){
      recaptchaToken = yield window.grecaptcha
        .execute("6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C", { action: "registration" })
        .then((res) => { return res });
      
    }else{
      recaptchaToken = "token";
    }

    const countryCode = '1';
    //Phone number in E.164 format for Cognito registration call
    const e164Phone = `+${countryCode}${action.data.phone.replace(/\D/g, '')}`;


    const authData =
    {
        username: action.data.email.substring(0, action.data.email.indexOf('@')),
        password: action.data.password,
        validationData: {
          Name: 'recaptchaToken',
          Value: recaptchaToken,
        },
        attributes: {
            email: action.data.email,
            name: `${action.data.firstName} ${action.data.lastName}`,
            given_name: `${action.data.firstName}`,
            family_name: `${action.data.lastName}`,
            locale: 'en',
            "custom:client_app": "screening",
            phone_number: e164Phone,
            address: JSON.stringify({
              "street_address": action.data.address,
              "locality": action.data.city,
              "region": action.data.state,
              "postal_code": action.data.zipcode
            })
      },
      clientMetadata: {
        isScreenApplicant: "true",
        screenRegistrationData: JSON.stringify({
          "firstName": action.data.firstName,
          "lastName": action.data.lastName,
          "email": action.data.email,
          "location": action.data.location,
          "aboutUs": action.data.aboutUs,
          "phone": action.data.phone,
          "state": action.data.state,
          "city": action.data.city,
          "address": action.data.address,
          "zipcode": action.data.zipcode,
          "consent": action.data.consent,
          "aptSuite": action.data.apt,
          "token": action.data.token,
          "marketingClaims": action.data.marketingClaims,
          "job": action.data.job,
          "client": action.data.client
        })
      }
    }

    const authRes = yield Auth.signUp(authData)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

    if(authRes.code === 'InvalidPasswordException'){
      yield put({type: 'COGNITO_VALID_PASSWORD', data: false});
    } else {
      yield put({type: 'COGNITO_VALID_PASSWORD', data: true});
    }

    //if user exists, sign them in and see if they have confirmed their email
    if(authRes.code === 'UsernameExistsException'){
      yield put({type: 'COGNITO_USER_EXISTS', data: true});
      yield put({type: 'SET_GO_REGISTER', data: false})
      //user login using registration form data
      let loginRes = yield Auth.signIn(authData.username, authData.password)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

      //set confirmed/unconfirmed
      if(loginRes.code === 'NotAuthorizedException'){
        yield put({type: 'COGNITO_USER_NOT_AUTHORIZED', data: loginRes});
      }else if(loginRes.code === 'UserNotConfirmedException'){
        yield put({type: 'COGNITO_USER_UNCONFIRMED', data: loginRes});
      } else {
        yield put({type: 'COGNITO_USER_CONFIRMED', data: loginRes});
      }

    } else {
      //if no user exists, register new first time user
      yield put({ type: 'USER_REGISTERED_COGNITO', data: authRes });
      //yield put({ type: 'REGISTER_USER_BACKEND', data: action.data });
    }
    yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: false } });
  }

export function* watchCognitoRegistering(){
  yield takeEvery('REGISTER_USER_COGNITO', registerUserCognito)
}

export function* registerUserBackend(action){
  yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: true } });

  let payload = {
    "firstName": action.data.firstName,
    "lastName": action.data.lastName,
    "email": action.data.email,
    "location": action.data.location,
    "aboutUs": action.data.aboutUs,
    "phone": action.data.phone,
    "state": action.data.state,
    "city": action.data.city,
    "address": action.data.address,
    "zipcode": action.data.zipcode,
    "consent": action.data.consent,
    "aptSuite": action.data.apt,
    "token": action.data.token,
    "marketingClaims": action.data.marketingClaims,
    "job": action.data.job,
    "client": action.data.client
  }

  let resp;

  yield apiCall('screening/netapi/v1/registration', 'POST', payload)
  .then((res) => {
      resp = res;
  })
  .catch((err) => {
      console.log(err);
  });

  yield put({type: 'USER_REGISTERED_BACKEND', data: { userId : resp.data.userID }});
  yield put({type: 'SET_GO_HOME', data: true});
  yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: false } });
}

export function* watchRegisterUserBackend(){
  yield takeEvery('REGISTER_USER_BACKEND', registerUserBackend)
}

export function* verifyUserAttributeCognito(action){
  yield Auth.resendSignUp(action.data.username)
  .catch(err => this.error(err));
}

export function* watchCognitoUserReverify(){
  yield takeEvery('COGNITO_USER_REVERIFY', verifyUserAttributeCognito)
}

//Cognito user confirmation//
export function* confirmUserCognito(action){
  yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: true } });
  let confirmRes;
  let signInRes;
  let username = action.confData.email.substring(0, action.confData.email.indexOf('@'));

  //attempt to confirm user email
  try {
    confirmRes = yield Auth.confirmSignUp(username, action.confData.cc, { clientMetadata: { isScreenApplicant: "true" }});
  } catch(err) {
    yield put({type: 'COGNITO_CONFIRMATION_FAILED'});
    confirmRes = false;
  }
  //if confirmation succeeded attempt to sign in
  if(confirmRes){
    yield put({type: 'COGNITO_CONFIRMATION_SUCCESS'});
    try{
      signInRes = yield Auth.signIn(username, action.confData.password);
    }
    catch(err)
    {
      console.log(err);
      signInRes = false;
      yield put({type: 'COGNITO_SIGN_IN_FAILED'});
    }
  }
  //if confirmation and sign in succeeded register the user
  if(confirmRes && signInRes){
    delete action.confData.password;
  }

  yield put({ type: 'USER_REGISTERED_BACKEND', data: { userId : 1 } })
  yield put({type: 'SET_GO_REGISTER', data: false})
  yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: false } });
}

export function* watchCognitoConfirming(){
  yield takeEvery('USER_CONFIRMING_COGNITO', confirmUserCognito)
}

export function* cleanRegistrationState(action) {
  let cleanData = {};
  if(action.data != null || undefined)
  {
    for (const [key, value] of Object.entries(action.data)) 
    {
      if(key !== 'password')
      {
        cleanData[key] = value;
      }
    }
    yield put({type: 'UPDATE_CLEANED_REGISTRATION_STATE', data: cleanData })
  }
}

export function* watchUpdateRegistrationState() {
  yield takeEvery('UPDATE_REGISTRATION_STATE', cleanRegistrationState)
}

export function* tryAgain(action) {
  yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: true } });

  let configName = action.data.config == 'default' ? 'catalyte' : action.data.config;

  const res = yield apiCall('screening/netapi/v1/assessmentinstance/retry/' + configName, 'PUT')
  .then(res => { return res })
  .catch(err => { 
    console.log('err during tryAgain');
    console.log(err);
  })
  let userId;
  if(res.status === 200){

    let redirectTo = getClientBaseUrl()

    userId = res.data.userID;
    window.location.replace(redirectTo);
  }
  yield put({type: 'TOGGLE_LOADING_SPINNER', data: { showLoadingSpinner: false } });
  yield put({ type: 'TRY_AGAIN_COMPLETED', data: { userId } })
} 

export function* watchTryAgain() {
yield takeEvery('TRY_AGAIN', tryAgain)
}

export function* checkUserEmail(action){
  let response = null;

  try {

    response = yield Auth.signIn(action.email.toLowerCase(), '123')
      .then(res => {
        return response;
      }).catch(error => {
        const code = error.code;
        return(code);
      });

  } catch(err) {
    console.log(err);
    return
  }


  switch (response) {
    case 'UserNotFoundException':
      yield put({type: 'COGNITO_USER_EXISTS', data: false})
      return
    case 'NotAuthorizedException':
      yield put({type: 'COGNITO_USER_EXISTS', data: true})
      return
    case 'PasswordResetRequiredException':
      yield put({type: 'COGNITO_USER_EXISTS', data: true})
      return
    case 'UserNotConfirmedException':
      yield put({type: 'COGNITO_USER_EXISTS', data: true})
      return
    default:
      yield put({type: 'COGNITO_USER_EXISTS', data: false})
      return
  }
}

export function* watchcheckUserEmail() {
  yield takeEvery('CHECK_USER_EMAIL', checkUserEmail)
}