import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { FormGroup } from '@material-ui/core';

const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const StyledRoot = styled.div`
  background-color: red;
`;

const StyledFormControl = styled(FormControl)`

`;

const StyledFormLabel = styled(FormLabel)`

`;

const StyledPrompt = styled.div`
  color: ${props => props.theme.primaryText}
`;

const StyledCheckbox = styled(Checkbox)`
  color: ${props => props.theme.primaryText}
`;

const StyledLabel = styled.span`
  color: ${props => props.theme.primaryText}
`;

const getPrompts = (prompts) =>{
    if(!prompts){
      return(<div></div>)
    }
    let orderedPrompts = prompts.sort((a, b) => (a.order > b.order) ? 1 : -1)
    return(
      orderedPrompts.map((prompt, i) =>{
        if(prompt.valueType === 'img'){
          return (<StyledPrompt key={'prompt_'+i} >{prompt.value}<img alt={prompt.description} src={imgBasePath+prompt.url} ></img></StyledPrompt>);       
        }else{
          return (<StyledPrompt key={'prompt_'+i} dangerouslySetInnerHTML={{__html: prompt.value}}></StyledPrompt>);       
        }
        
      })
    );
  }
const MultipleCheckboxComponent = (props) => {
    const dispatch = useDispatch();
       
    const handleChange = (event) => {
      const option = props.question.answer.filter(o => o.value === event.target.value)[0];
      let newAnswerVal = props.question.answer;
      if(option.isExclusive){
          newAnswerVal = newAnswerVal.map((v, i) => {
               if(v.questionOptionID === option.questionOptionID){
                   v.input = !v.input;
               }else{
                   v.input = false;
               }
               return v;
          })
      }else{
          newAnswerVal = newAnswerVal.map((v, i) => {
              if(v.questionOptionID === option.questionOptionID){
                  v.input = !v.input;
              }else if(v.isExclusive){
                  v.input = false;
              }
              return v;
          })
      }
      dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": newAnswerVal} });
  };

  const getCheckState = (id) => {
    return props.question.answer.filter(o => o.questionOptionID === id)[0].input === true;
  }

    const getListItems = () => {
        const selectionOptions = props.question;
        let orderedOptions = selectionOptions.options.sort((a, b) => (a.order > b.order) ? 1 : -1)
        const listElements = orderedOptions.map((option, i) => {
            const choicevalue = option.value === '' ? option.optionsUrl : option.value;
              return (
                <FormControlLabel key={'option_'+i} value={choicevalue} control={<StyledCheckbox checked={getCheckState(option.questionOptionID)} onChange={handleChange}></StyledCheckbox>}
                    label={
                        <>
                          <StyledLabel dangerouslySetInnerHTML={{__html: option.value}}></StyledLabel>
                        </>
                    }
                >
                </FormControlLabel>
            )

        })
        return listElements;
    }
    return (
      <div>
        <StyledRoot>
          <StyledFormControl>
            <StyledFormLabel>{getPrompts(props.question.prompts)}</StyledFormLabel>
            <FormGroup>
              {getListItems()}
            </FormGroup>
          </StyledFormControl>
        </StyledRoot>
      </div>
    );
}

export default MultipleCheckboxComponent;