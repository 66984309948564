import React from 'react';
import {Redirect} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import OneButtonModal from '../ModalComponent/OneButtonModal';
import styled from 'styled-components/macro';
import SectionComponent from '../SectionComponent/SectionComponent';
import MultiFormComponent from '../MultiFormComponent/MultiFormComponent';
import ReadingSectionComponent from '../ReadingSectionComponent/ReadingSectionComponent';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
import SecondaryButtonComponent from '../SecondaryButtonComponent/SecondaryButtonComponent';
import SaveExitButtonComponent from '../PrimaryButtonComponent/SaveExitButtonComponent';
import ConditionalSectionComponent from '../ConditionalSectionComponent/ConditionalSectionComponent';
import TimerContainerComponent from '../TimerComponent/TimerContainerComponent';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import TraitifySectionComponent from '../TraitifySectionComponent/TraitifySectionComponent';

const StyledRoot = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;    
    ${up('lg')} {
      margin: 0; 
    } 
   
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height:518px;
`;

const ButtonWrapper = styled.div`
    min-width: 5em;
    max-width: 50%;
`;

const TwoButtonDiv = styled.div`
    display: flex;
    justify-content: space-between;    
    padding:3em 2em 1em 2em;    
    ${up('md')}{
      padding: 0 2em 2em 2em;
    }
    ${up('lg')}{
      padding: 0 0 2em 0;
    }
`;

const HelperText = styled.div`
    color: ${ props => props.theme.error };
    font-size: .7em;
    min-height: 1.25em;
`;

const HelperTextWrapper = styled.div`
    min-height: 1em;
    width: 150px;
`;

const MainPage = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    width: 100%;
    max-width: 100%;
    ${up('sm')} {
      width: 70%;
      max-width: 1300px;
    }    
`;
const StyledTimerContainer = styled(TimerContainerComponent)`  
  position:relative;
  z-index: 9999;  
  top: -50px;
  margin-left: 2em;
  ${up('md')} {
    top: -40px;
  }
  ${up('lg')} {
    top: -60px;
    margin-left: 0em;
  }
`;

const StyledSaveNExitButton = styled(SaveExitButtonComponent)`
  margin: 0.1em;
  padding-left: 0em;
`;

const DumySpace = styled.div`
   margin: 0.5em;
`;
				
const SmallButtonWrapper = styled.div` 
    display: flex;
    flex-direction: column;    
    text-align: center;   
`;

const BigButtonWrapper = styled.div`
`;

const SectionParentComponent = (props) => {
  
  const dispatch = useDispatch();
  const selectedSection = props.selectedSection;
  const sections = props.sections;
  const [showMissingRequiredError, setShowMissingRequired] = React.useState(false);  
  const [return2Welcome, setReturn2Welcome] = React.useState(false);
  const isDesktop = useBreakpoint(up('xl'))
  const hardSectionTimer = props.hardSectionTimer;
  const hardTimedSections = props.hardTimedSections;

  if (!selectedSection) {
    return <div></div>
  }

  const renderSection = () => {
    switch(!selectedSection ? "fail" : selectedSection.type){
      case "noTitle":
        return (
          <SectionComponent />        
        ); 
      case "multiForm.education":
        return (
          <MultiFormComponent />
        );
      case "multiForm.employment":
        return (
          <MultiFormComponent />
        );
      case "conditional":
        return (
          <ConditionalSectionComponent />
        );
      case "reading":
        return (
          <ReadingSectionComponent />
        );
      case "traitify":
        return (
          <TraitifySectionComponent onNextSection={onNextSection}/>
        )
      default:
        return (
          <SectionComponent />
        );
      }
  }

  const getNextAvailableSection = () => { 
     let availableSections = sections.filter(s => (s.order > selectedSection.order) && !(hardTimedSections.some(el => s.name.includes(el)) && s.startTime));
    
     if(availableSections.length > 0){
       return availableSections[0];
     }else{
       return null;
     }
  }

  const getPreviousAvailableSection = () => {
    let availableSections = sections.filter(s => (s.order < selectedSection.order) && !hardTimedSections.some(el => s.name.includes(el)) && s.startTime);
    
    if(availableSections.length > 0){
      return availableSections[availableSections.length - 1];
    }else{
      return null;
    }
  }

  const onNextSection = (e) =>{
      
      if(e && String(e.target.id) === 'saveExitButton'){         
         dispatch({type: 'SAVE_SECTION', data: { selectedSection: selectedSection, signOut: true }});
      }
      else{          
          let nextSection = getNextAvailableSection();
          const isFinal = !nextSection;
      
          props.onSectionChange(isFinal); 

          if(selectedSection.missingRequired){
            setShowMissingRequired(true);
            return;
          }
          else{
            setShowMissingRequired(false);
          }
          if(!isFinal){
              dispatch({type: 'SAVE_SECTION', data: { selectedSection: selectedSection, nextSection: nextSection}});
              dispatch({type: 'SET_TIMER', data: { started: false, timeRemaining: 0, timeLeft: 0, startTime: null, minitLimit: 0 }});
          }
          else{            
              dispatch({type: 'SET_TIMER', data: { started: false, timeRemaining: 0, timeLeft: 0, startTime: null, minitLimit: 0 }})
              //route to thank you
              dispatch({ type: 'COMPLETE_ASSESSMENT', data: selectedSection });
          }

      }
  }

  const onPreviousSection = () =>{    
    
      let curIndex = sections.findIndex((ele) => { return ele.sectionID === selectedSection.sectionID });  

      let previousAvailableSection = getPreviousAvailableSection();

      if(curIndex !== 0){
          dispatch({type: 'SAVE_SECTION', data: { selectedSection: selectedSection, nextSection: previousAvailableSection }})
      }
      if(curIndex === 0){
        setReturn2Welcome(true);
      } 
  }

    if (props.completed)
    {
      // go here if they just submitted for the first time
      if(props.submitted){
        return(
          <Redirect to='/thankyou' />
        )
      // go here if they're returning to a previously completed assessment
      }else{
        return(
            <Redirect to = '/completed' />
        )
      }
    }
    else if(return2Welcome){
      return(
        <Redirect to ='/welcome'></Redirect>
      )
    }
    else
    {
      return(
        <StyledRoot> 
           <MainPage>
              {selectedSection && selectedSection.isTimed && <StyledTimerContainer onNext ={onNextSection}></StyledTimerContainer>  }        
            <MainColumn>
                {renderSection()}
                <TwoButtonDiv>
                    <ButtonWrapper>
                      <HelperTextWrapper></HelperTextWrapper>
                      { selectedSection.name && hardSectionTimer ? <></> : <SecondaryButtonComponent onClick={onPreviousSection} text={'Back'}></SecondaryButtonComponent> }
                    </ButtonWrapper>
                    {!isDesktop && <DumySpace></DumySpace>}
                    <ButtonWrapper>
                      <HelperTextWrapper>
                      { selectedSection.missingRequired && showMissingRequiredError ? <HelperText id='missingRequiredError'>Unanswered mandatory questions.</HelperText> : <></> }
                      </HelperTextWrapper>
                        { !isDesktop ? 
                          <SmallButtonWrapper>                        
                            { selectedSection.type !== 'traitify' && <PrimaryButtonComponent id='nextButton' onClick={onNextSection} innactive={ selectedSection.missingRequired } text={ getNextAvailableSection() ? 'Save' :  (isDesktop? 'Submit Screening' : 'Finish')} />}
                            <StyledSaveNExitButton id='saveExitButton' onClick={onNextSection} text={'Save & Exit'}></StyledSaveNExitButton>
                          </SmallButtonWrapper> :
                          <BigButtonWrapper>
                            <StyledSaveNExitButton id='saveExitButton' onClick={onNextSection} text={'Save & Exit'}></StyledSaveNExitButton>
                            { selectedSection.type !== 'traitify' &&<PrimaryButtonComponent id='nextButton' onClick={onNextSection} innactive={ selectedSection.missingRequired } text={ getNextAvailableSection() ? 'Save' : (isDesktop? 'Submit Screening' : 'Finish')} />}
                          </BigButtonWrapper>
                        }
                    </ButtonWrapper>
                </TwoButtonDiv>
                  { props.showRequiredModal ? <OneButtonModal handleClose={() => { dispatch({ type: 'TOGGLE_REQUIRED_MODAL' }) }} handleContinue={() => { dispatch({ type: 'TOGGLE_REQUIRED_MODAL' }) }} title='Unanswered Mandatory Questions' /> : <></> }
            </MainColumn>
            </MainPage>
        </StyledRoot>
    )
  }
}

export default SectionParentComponent;
