import React,  { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro'; 

const timer = process.env.REACT_APP_env_svg_base_path + 'time.svg';

const TimerStyle = styled.div`
    max-width: 15em;
`;

const TimeCountStyle = styled.div`  
    display: flex;
    align-items: center;
    color: ${ props => props.theme.accentText };    
`;

const TimerDisplay = styled.div`    
    top: 446px;
    left: 375px;
    width: 66px;
    height: 66px;
    border-radius: 800px/800px;
    background: ${props => props.theme.background};
    box-shadow: 1px 10px 35px ${props => props.theme.formShadow};
    border: 5px solid ${props => props.theme.absolute};
    opacity: 1; 
    padding-top: 8px;
    padding-left: 3px;    
`;

const SpanStyle = styled.span`  
    border-radius: 1px;   
    font-size: 1.6em; 
    font-weight:bold;     
`;

const TimingPart = styled.div`
    display : flex;
    flex-direction : row;     
`;
const TimerImage = styled.img`
   width: 33px;
   height: 33px;
`;

const TimerComponent = (props)=>{
    const dispatch = useDispatch();
    const sectionTimer = useSelector((state) => state.sectionTimer);
    const selectedSection = useSelector((state) => state.selectedSection);   
    const customizedWith = props.width ? props.width : '';
    const customizedHeight = props.height?props.height: '';
    const timerSize = {width: customizedWith, height: customizedHeight};
    const imgSize ={width: props.width ?  customizedWith / 2 : '', height: props.height ? customizedHeight / 2 : ''}
    let timerCustomization = props.customization ? props.customization : null;

    if(timerCustomization){
       timerCustomization = {...timerCustomization, ...timerSize};
    }

    useEffect(() => {
      setTimeout(() => {
        if(sectionTimer && sectionTimer.started){
          dispatch({ type: 'UPDATE_TIMER' });
        
          const timeLeft = sectionTimer.timeLeft.minutes * 60  + sectionTimer.timeLeft.seconds;       
          if (timeLeft === 0 && props.onNext){                    
              props.onNext();
          }
        }

      }, 1000); 
    });

  
 if(selectedSection && selectedSection.isTimed && sectionTimer){  

      if(!sectionTimer.started){
        dispatch({type: 'SET_TIMER', data: { started: true, timeRemaining: selectedSection.timeAllowed * 1000, timeLeft: { minutes: selectedSection.timeAllowed / 60, seconds: 0 }, startTime: Date.now(), minitLimit: selectedSection.timeAllowed/60 }})
        return <div></div>
      }
      return (
        <TimerStyle className={props.className}>                    
              <TimeCountStyle >                                     
                <TimerDisplay style ={ timerCustomization } >
                    <TimerImage src={timer} style = {imgSize}/>
                </TimerDisplay>
                <TimingPart style={{color: props.textColor ? props.textColor : ''}}> 
                    <SpanStyle className="minutes" id="minute">{sectionTimer.timeLeft.minutes.toString().length > 1 ? sectionTimer.timeLeft.minutes : '0' + sectionTimer.timeLeft.minutes}</SpanStyle>                   
                    :<SpanStyle className="seconds" id="second"> {sectionTimer.timeLeft.seconds.toString().length>1?sectionTimer.timeLeft.seconds : '0'+sectionTimer.timeLeft.seconds}</SpanStyle>
                </TimingPart>                
            </TimeCountStyle> 
      </TimerStyle>     
    );
   }else{
     return <div></div>
   }
}

export default TimerComponent;