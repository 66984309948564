import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { up } from 'styled-breakpoints';
import FooterContactInfoComponent from '../FooterContactInfoComponent/FooterContactInfoComponent';

const StyledRoot = styled.div`
    height: 100%;
    width: 100%;
    display: flex;    
    background-color: ${ props => props.theme.mainHeader };
    align-items: center;
    justify-content: space-between;
    ${(up('lg'))}{
        height: 6em;     
        align-items: flex-start;                            
    }   
`;

const LogoWrapper = styled.div`   
    margin: .5em 1em .25em 1em;     
    ${(up('lg'))}{
        margin-top: 2.5em;
        margin-left: 10em;
        margin-bottom: 0    
    }     
`;

const StyledLogo = styled.img`
    image-rendering: -webkit-optimize-contrast;
    width: 107px;
   
    ${(up('lg'))}{
        width: 10.5em;
        height: 1.3em;
    }
`;

const VariableText = styled.div`
    ${(up('lg'))}{
        font-size: 1.2em;
        margin-top: 2em;
        margin-right: 5em;    
    }
`;
const FooterComponent = (props) => {
    const { showLogin, showLogout } = props;
    const globalState = useSelector((state) => state.global);
    const imgBasePath = process.env.REACT_APP_env_img_base_path;
    return(
        <StyledRoot>
            <LogoWrapper>
                <StyledLogo src={imgBasePath + globalState.config.logo}/>
            </LogoWrapper>
            {
                (showLogin || showLogout) &&
                <VariableText>
                    <FooterContactInfoComponent contactInfo="screeningsupport@catalyte.io" />
                </VariableText>
            }
        </StyledRoot>
    )
}

export default FooterComponent;