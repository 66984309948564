import React, { Component } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import jwkToPem from 'jwk-to-pem';
import LogoutNotification from './LogoutNotification';

class SSOProvider extends Component {
  constructor(props) {
    super(props);

    const { authConfig } = this.props;

    Amplify.configure({
      Auth: authConfig
    });

    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    this.state = {
      authLoaded: false,
      confirmTimer: null,
      invalidUser: false,
      sessionTimedOut: false,
      sessionTimer: null,
      user: null
    };
  }

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      if (!session) {
        window.location.replace(this.props.redirectUrl);
      }
      const idTokenPayload = await this.verifyToken(session.idToken.jwtToken);
      if (idTokenPayload) {
        if (this.checkAppPermissions(idTokenPayload)) {
          const userData = await Auth.currentUserInfo();
          const { authLoaded } = this.state;
          if (!authLoaded) {
            this.setState({
              authLoaded: true,
              user: userData
            });
          }
          this.initActivityTimer();
        } else {
          this.setState({
            invalidUser: true
          });
        }
      } else {
        this.setState({
          invalidUser: true
        });
      }
    } catch (e) {
      if (e === 'No current user') {
        window.location.replace(this.props.redirectUrl);
      }
    }
  }

  cancelLogout = () => {
    const { confirmTimer } = this.state;
    clearTimeout(confirmTimer);
    this.setState({
      sessionTimedOut: false
    });
    this.initActivityTimer();
  };

  checkAppPermissions = (tokenClaims) => {
    const { appName } = this.props;
    const groups = tokenClaims['cognito:groups'];
    return groups.some((group) => {
      const groupParts = group.split('_');
      let groupApp, groupRole;
      if(groupParts.length === 2){
        groupApp = groupParts[0];
        groupRole = groupParts[1]
      }else{
        groupApp = groupParts[2];
        groupRole = groupParts[3];
      }
      return (
        groupApp === appName ||
        (groupApp === 'odysseyPortal' && (groupRole === 'admin' || groupRole === 'superUser'))
      );
    });
  };

  handleLogout = () => {
    Auth.signOut();
    window.location.replace(this.props.redirectUrl);
  };

  handleSessionTimeout = () => {
    const { cancelLogoutTimeout } = this.props;
    const { sessionTimer } = this.state;
    clearTimeout(sessionTimer);
    const confirmTimer = setTimeout(this.handleLogout, cancelLogoutTimeout);
    this.setState({
      confirmTimer,
      sessionTimedOut: true
    });
  };

  initActivityTimer = () => {
    const { inactivityTimeout } = this.props;
    let activeTimestamp = Date.now();

    // check for browser "awake" at 2 second intervals
    // If browser just "woke up", check if the time is longer than the allowed timeout
    setInterval(() => {
      var currentTime = Date.now();
      if (currentTime > activeTimestamp + 2000 * 2) {
        // ignore small delays
        // Probably just woke up
        const asleepTime = currentTime - activeTimestamp;
        if (asleepTime > inactivityTimeout) {
          this.handleLogout();
        }
      }
      activeTimestamp = currentTime;
    }, 2000);

    // Once we have an "awake" browser, keep track of user interaction
    // and logout if inactive period is longer than allowed
    let sessionTimer = setTimeout(this.handleSessionTimeout, inactivityTimeout);
    const resetTimer = () => {
      clearTimeout(sessionTimer);
      sessionTimer = setTimeout(this.handleSessionTimeout, inactivityTimeout);
    };
    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    events.forEach(function(name) {
      document.addEventListener(name, resetTimer, true);
    });

    this.setState({
      sessionTimer
    });
  };

  verifyToken = async (token) => {
    try {
      const { authConfig } = this.props;
      const jwksUrl = `https://cognito-idp.us-east-1.amazonaws.com/${authConfig.userPoolId}/.well-known/jwks.json`;
      const requestJwks = await axios.get(jwksUrl);
      const jwks = requestJwks.data;
      const decodeToken = jwt.decode(token, { complete: true });
      const jwk = jwks.keys.find((key) => {
        return key.kid === decodeToken.header.kid;
      });
      if (jwk) {
        const pem = jwkToPem(jwk);
        const validToken = jwt.verify(token, pem);
        return validToken;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  render() {
    const { inactivityTimeout, fontFamily } = this.props;
    const { user, invalidUser, sessionTimedOut } = this.state;
    if (!user && !invalidUser) {
      return null;
    }

    if (invalidUser) {
      return (
        <div id="accessDenied">
          <p style={{ textAlign: 'center' }}>
            Sorry, you do not have permission to access this application.
          </p>
        </div>
      );
    }

    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        return React.cloneElement(child, { logout: this.handleLogout, user });
      },
      this
    );

    return (
      <>
        <LogoutNotification
          open={sessionTimedOut}
          handleCancel={this.cancelLogout}
          inactivityTimeout={inactivityTimeout}
          fontFamily={fontFamily}
        />
        {childrenWithProps}
      </>
    );
  }
}

export default SSOProvider;
