import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import TextInputComponent from '../TextInputComponent/TextInputComponent';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
import { ThemeContext } from 'styled-components';

const FormBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 100;
    background-color: ${props => props.theme.card};
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
`;

const InputGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    
`;

const ApplicantRequirementsHeader = styled.h4`
  font-size: 1.5em;
  letter-spacing: 0px;
  color: ${props => props.theme.accentText};
`;

const ApplicantRequirementsWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-left: 1em;
  margin-bottom: 1em;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none; 
  align-items: left;

`;

const StyledBullet = styled.div`
  max-height: .25em;
  min-width: .25em;
  margin-right: .5em;
  margin-top: 1.5%;
  border-radius: 100%;
  background: linear-gradient(90deg, ${props => props.theme.tertiary} 0%, ${props => props.theme.secondary} 100%);  
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  min-width: 20em;
  max-width: 20em;
  margin-bottom: .5em;
  font-size: 1.05em;
  font-weight: 400;
  letter-spacing: 0px;
  color: ${props => props.theme.primaryText};
  align-items: left; 
`;

const LargeInputWrapper = styled.div`
    display: flex;
    flex-grow: 3;
    width: 5em;
`;

const ButtonRow = styled.div`
    margin-left: 1em;
    margin-right: 1em;
    justify-content: space-between;
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 2em;
    padding-top: 3em;
`;

const PrimaryFormComponent = (props) =>{
    //Theme & State
    const themeContext = useContext(ThemeContext);
    const config = useSelector(state => state.global && state.global.config || {"name": "default"})
    const client = props.client;
    const cognitoUser = props.globalState && props.globalState.cognitoUser; 
    const firstName = props.firstName;
    const lastName = props.lastName;
    const aboutUs = props.aboutUs;
    const phone = props.phone;
    const address = props.address;
    const apt = props.apt;
    const city = props.city;
    const state = props.state;
    const zipcode = props.zipcode;
    const ext = props.ext;

    //Change Handlers
    const onFnameChange = props.onFnameChange;
    const onLnameChange = props.onLnameChange;
    const onAboutUsChange = props.onAboutUsChange;
    const onPhoneChange = props.onPhoneChange;
    const onAddressChange = props.onAddressChange;
    const onAptChange = props.onAptChange;
    const onCityChange = props.onCityChange;
    const onStateChange = props.onStateChange;
    const onZipcodeChange = props.onZipcodeChange;
    const onExtChange = props.onExtChange;

    //Blur Handlers
    const onFnameBlur = props.onFnameBlur;
    const onLnameBlur = props.onLnameBlur;
    const onPhoneBlur = props.onPhoneBlur;
    const onAddressBlur = props.onAddressBlur;
    const onCityBlur = props.onCityBlur;
    const onStateBlur = props.onStateBlur;
    const onZipCodeBlur = props.onZipCodeBlur;
    const onExtBlur = props.onExtBlur;

    //Validation State
    const isValidFname = props.isValidFname;
    const isValidLname = props.isValidLname;
    const isValidPhone = props.isValidPhone;
    const isValidAddress = props.isValidAddress;
    const isValidCity = props.isValidCity;
    const isValidState = props.isValidState;
    const isValidZip = props.isValidZip;
    const isValid = props.isValid;

    //Submit Handler
    const handleSubmit = props.handleSubmit;

    const renderListitems = (items) => {
        return items.map((v, i) => {
          return  <StyledListItem key={i}><StyledBullet />{v}</StyledListItem>
        })
    }

    return (
        <FormBox>
            <InputGroup>
                {
                (!cognitoUser || (!cognitoUser.attributes.given_name || !cognitoUser.attributes.family_name)) &&
                <>
                    <TextInputComponent
                        id={'fname-input'}
                        error={ isValidFname === null ? false : !isValidFname }
                        helperText={ 'Must be at least one and less than 256 characters' }
                        type="text"
                        onChange={onFnameChange}
                        value={firstName}
                        backgroudColor={themeContext.quarterly}
                        onBlur={onFnameBlur}
                        required={true}
                        isValid={isValidFname}
                        label='First Name'
                    />
                    <TextInputComponent
                        id={'lname-input'}
                        error={ isValidLname === null ? false : !isValidLname }
                        helperText={'Must be at least one and less than 256 characters'}
                        type="text"
                        onChange={onLnameChange}
                        onBlur={onLnameBlur}
                        isValid={isValidLname}
                        value={lastName}
                        backgroudColor={themeContext.quarterly}
                        required
                        label='Last Name'
                    />
                </>
                }
                <TextInputComponent
                    id={'phone-input'}
                    error={ isValidPhone === null ? false : !isValidPhone }
                    isValid={ isValidPhone }
                    helperText={'Please enter a valid phone number'}
                    type="tel"
                    onChange={onPhoneChange}
                    onBlur={onPhoneBlur}
                    backgroudColor={themeContext.quarterly}
                    value={phone}
                    label='Phone'
                    required
                />
                <TextInputComponent
                    id={'ext-input'}
                    type="tel"
                    onChange={onExtChange}
                    onBlur={onExtBlur}
                    backgroudColor={themeContext.quarterly}
                    value={ext}
                    label='Ext'
                />
                { 
                client != 'createoppcolumbia' ? 
                    <LargeInputWrapper>
                        <TextInputComponent
                            id={'about-us-ddl'}
                            onChange={onAboutUsChange}                   
                            value={aboutUs}                                
                            label ={"How did you hear about us?"}                          
                            onBlur={onAboutUsChange}                   
                            backgroudColor={themeContext.quarterly}
                        />                         
                    </LargeInputWrapper> : '' 
                }
                <TextInputComponent
                    id={'address-input'}
                    error={ isValidAddress === null ? false : !isValidAddress }
                    isValid={ isValidAddress }
                    helperText={'Please enter a valid address'}
                    type="text"
                    onChange={onAddressChange}
                    onBlur={onAddressBlur}
                    backgroudColor={themeContext.quarterly}
                    value={address}
                    label='Address'
                    required
                />
                <TextInputComponent
                    id={'apt-input'}
                    type="text"
                    onChange={onAptChange}
                    value={apt}
                    backgroudColor={themeContext.quarterly}
                    label='Apt/Suite'
                />
                <TextInputComponent
                    id={'city-input'}
                    error={ isValidCity === null ? false : !isValidCity }
                    isValid={ isValidCity }
                    helperText={'Please enter a valid city'}
                    type="text"
                    onChange={onCityChange}
                    onBlur={onCityBlur}
                    value={city}
                    label='City'
                    backgroudColor={themeContext.quarterly}
                    required
                />
                <TextInputComponent
                    id={'state-input'}
                    error={ isValidState === null ? false : !isValidState }
                    isValid={ isValidState }
                    helperText={ 'Please enter a valid state abbreviation' }
                    type="text"
                    onChange={onStateChange}
                    onBlur={onStateBlur}
                    value={state}
                    label='State'
                    backgroudColor={themeContext.quarterly}
                    required
                />
                <TextInputComponent
                    id={'zip-input'}
                    error={ isValidZip === null ? false : !isValidZip }
                    isValid={ isValidZip }
                    helperText={ 'Please enter a valid zip code' } 
                    type="text"
                    onChange={onZipcodeChange}
                    onBlur={onZipCodeBlur}
                    value={zipcode}
                    label='Zip'
                    backgroudColor={themeContext.quarterly}
                    required
                />
            </InputGroup>
            <ApplicantRequirementsHeader>{config.registration.primary.requirementsHeader}</ApplicantRequirementsHeader>
            <ApplicantRequirementsWrapper>
                {
                (config.registration.primary.requirements) &&
                renderListitems(config.registration.primary.requirements)
                }
            </ApplicantRequirementsWrapper>
            <ButtonRow>
              <PrimaryButtonComponent
                    id={'next-btn'}
                    innactive={!isValid}
                    onClick={handleSubmit}
                    text={'Next'}
              ></PrimaryButtonComponent>
            </ButtonRow>
        </FormBox>
      );
  }

export default PrimaryFormComponent;
