import React from "react";
import styled from 'styled-components/macro';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const OneButtonDiv = styled.div`
    padding-top:50px;
    padding-left:0px;
    width: 230px;
    text-align: center;
    margin:0 auto;
`;
const Title = styled.h1`
    text-align:center;
    float:center;
`;
const TextStyle = styled.p`
    text-align:center;
    font-size: 30px;
    width: 96%;
    padding-left: 10px;
`;
const ModalDiv = styled.div`
    width: 40%; 
    max-Height: 100%; 
    position: fixed; 
    align-items: center; 
    justify-content: center; 
    background-color: ${props => props.theme.card}; 
    top: 40%; 
    left: 30%; 
    transform: translate(0, -50%);
    overflow-y: auto;
    outline: none !important;
    border-radius: 4px;

    &:focus {
        outline: none !important;
    }

    &:active {
        outline: none !important;
    }
`;

const StyledModal = styled(Modal)`
    color: #1b3e77 !important;
`;

const StyledCloseButton = styled(IconButton)`
    float: right;
`;


const StyledContinueButton = styled.button`
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: linear-gradient(48deg, rgba(247,139,52,1) 0%, rgba(247,208,52,1) 100%);
    border-radius: 9999px !important;
    width: 10em;
    color: white;
    font-weight: bold;
    text-transform: unset !important;
    height: 2.6em;
    font-size: .9em;
    cursor: pointer;
    border: none;
    letter-spacing: .01em;
`;

const OneButtonModal = (props) => { 
   
    const handleClose = () => {       
        props.handleContinue();        
    };

    return (
        <StyledModal open={true}>
            <ModalDiv>
                <StyledCloseButton>
                    <CloseIcon color='inherit' onClick={handleClose} value ='XClose'></CloseIcon><br/>
                </StyledCloseButton>
                <Title>{ props.title }</Title>
                <TextStyle>{ props.body }</TextStyle>
                    
                <p>
                <OneButtonDiv onClick = {handleClose}>
                    <StyledContinueButton >Continue</StyledContinueButton>
                </OneButtonDiv>
                    </p>   
            </ModalDiv>
        </StyledModal>
    );
}

export default OneButtonModal;