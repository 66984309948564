import React from 'react';
import styled from 'styled-components/macro';
import { up } from 'styled-breakpoints';
import { getClientBaseUrl } from '../../util/helper/Helper';

const StyledError = styled.div`
  border-bottom: 2.5em solid #3e549c;
  display: flex;
  padding: 3em 0;
  color: #1b3e77;
  flex-grow: 1;
  ${up('md')} {
    padding: 3em;
    } 
`;

const ImageWrapper = styled.img`
  max-width: 20em;
  margin-bottom: 3em;
  ${up('lg')} {
    margin-left: 3em;
    } 
`;

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-align: center;
  align-items: center;
`;

const StyledContent = styled.div`
  margin-top: 3em;
  width: 60%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  width: 100%;
  height: 2.5em;
  background-color: #3e549c;
  color: white;
  text-align: center;
  padding-top: .5em;
  font-weight: 600;
`;

const StyledP = styled.p`
  margin-bottom: 0;
`;

const ErrorPageComponent = () =>{
    let redirectTo = getClientBaseUrl()

    return (
      <StyledRoot>
        <StyledContent>
          <ImageWrapper src='/images/catalyte-logo.png'></ImageWrapper>
          <StyledTitle>
            Error Page
          </StyledTitle>
          <StyledError>
          <div>
          <p><b>If you have received this error while taking your assessment, click <a id='redirectLink' href={process.env.REACT_APP_env_sso_redirect_url+'?redirectTo='+redirectTo}>here</a> to log back in with Odyssey.</b></p>
          <p>An error has occurred. If you would like to contact support, see contact information below.</p>
          <StyledP><b>Contact Information</b></StyledP>
          Email: <a id='supportEmailLink' href="mailto: screeningsupport@catalyte.io">screeningsupport@catalyte.io</a>
          </div>
          </StyledError>
        </StyledContent>
      </StyledRoot>
    );
}

export default ErrorPageComponent;
