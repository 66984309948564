
    import React, { useEffect } from 'react';
    import styled from 'styled-components/macro'; 
    import { ThemeProvider } from 'styled-components';
    import DividerComponent from '../DividerComponent/DividerComponent';
    import themes from '../../Themes/themes';
    import FooterComponent from '../FooterComponent/FooterComponent';   
    import { up } from 'styled-breakpoints';
    import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
    import { Redirect } from 'react-router-dom';
    import { useDispatch, useSelector } from 'react-redux';
    import { Helmet } from "react-helmet";
    import { isIE, isOpera } from 'react-device-detect';

    const imgBasePath = process.env.REACT_APP_env_img_base_path;
    
    const StyledThemeWrapper = styled(ThemeProvider)``;

    const StyledRoot = styled.div`
        background-color: ${props => props.theme.regBgColor};                     
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    `;

    const StyledTopWrapper = styled.div`
    `;

    const StyledTop = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
        ${up('lg')}{
        }
    `;

    const StyledHeader = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 4em;
        background-color: ${ props => props.theme.main };
        ${up('lg')}{
        }
    `;

    const StyledTitle = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 62%;
        padding-left: 2em;
        margin-top: 6.5em;
        margin-bottom: 50px;
        ${up('lg')}{
          padding-left: 7em;
        }
    `;

    const TitleTextWrapper = styled.div`
        display:flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
    `;

    const StyledTitleText = styled.div`         
        font-weight: bold;
        font-size: 3.5em;
        color: ${ props => props.theme.main };
        margin-bottom: .5em;           
    `;

    const VerticalDecorationContainer = styled.div`
      display: flex;
      flex-direction: row;
      width: 5%;
      margin-top: 3em;
    `;

    const HorizontalDecorationContainer = styled.div`
      width: 10em;
      overflow: hidden;
      ${up('lg')}{
        margin-right: 3em;
      }
    `;

    const BlackSquareDecoration = styled.div`
      width: 1.75em;
      height: 1.75em;
      background-color: black;
    `;

    const VerticalGradientDecoration = styled.div`
      width: 2em;
      height: 10em;
      background: linear-gradient(#B5B3B3, #DE4F31);
    `;

    const HorizontalGradientDecoration = styled.div`
      width: 100%;
      height: 1.75em;
      background: linear-gradient(to left top, #DE4F31, #B5B3B3);
      margin-left: 1.75em;
    `;

    const StyledSectionCountText = styled.div`        
      color: ${props => props.theme.main};
      font-weight: 600;      
      font-size: 1.3em;
      margin-top: 16px;
      margin-bottom: 16px;
    `;

    const StyledTextArea = styled.div`        
        margin: 0 5em;
        min-height: 600px;
        flex: 1;
        ${up('lg')}{
            width: 70%;
            margin: 0 16.5em;
        }
    `;        

    const StyledSubtitle = styled.div`        
        font-size: 1em;
        font-weight: 200;
        color: ${ props => props.theme.main };
        margin-bottom: 1em;
    `;        
    
    const StyledHeaderImage = styled.img`
        image-rendering: -webkit-optimize-contrast;
        height: 1.3em;
        max-width: 10.5em;
        margin-left: 4em;
    `;

    const StyledSeparateBar = styled.div`
        dispaly: flex; 
        background-color: #070707;
        height: .5em;    
        width: 1.2em;
        ${up('lg')}{
            width: 1.5em;
        }
    `;   
    
    const StyledList = styled.ul`
      max-width: 85%;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 4em;
      margin-left: 1.5em;
      list-style-type: "+   ";
    `;

    const StyledBullet = styled.div`
      height: 10px;
      width: 10px;
      min-width: 10px;
      min-height: 10px;
      margin-right: 1em;  
      border-radius: 100%;
      position: relative;
      top: 5px;
      background: linear-gradient(90deg, #F7C634 0%, #F79334 100%);  
    `;

    const StyledListItem = styled.li`
      display: list-item;
      flex-direction: row;  
      font-size: 18px;
      color: #717171;
      align-items: flex-start;
      margin-bottom: 24px;
    `;
    
    const StyledText = styled.div`
      display: flex;
      flex-direction: row;  
      font-size: 18px;
      color: #717171;
      align-items: flex-start;
      margin-bottom: 2em;
      max-width: 100%;
      ${up('lg')}{
        max-width: 85%;
      }
    `;

    const StyledHeaderText = styled.div`
      display: flex;
      flex-direction: row;  
      font-weight: none;      
      font-size: 1.3em;
      color: ${ props => props.theme.main };
      align-items: flex-start;
      margin-bottom: 24px;
      ${up('lg')}{
        max-width: 85%;
      }
    `;

    const StyledButton = styled(PrimaryButtonComponent)`
      font-size: 1.1em;
	    margin-bottom: 5em;
      width: 12em;
      ${up('lg')}{
        width: 14.5em;
      }
    `;

    const scrollToTop = () => {
        window.scrollTo({
          top: 0
        });
      }
  
    const WelcomePageComponent = () => {               
      const [goNext, setGoNext] = React.useState(false);
      const sections = useSelector((state) => state.sections);
      const global = useSelector((state) => state.global);
      const registrationConfig = global.config && global.config.registration || {"confirmation": {}, "primary": {}}; // Fetching registration config from Redux
      const dispatch = useDispatch();

      scrollToTop();

      useEffect(() => {
        if (sections && sections.length === 0) {
            dispatch({ type: 'INIT_ASSESSMENT', data: { config: global.config.name } });
          }
      },[])

      if (isIE || isOpera) {
        return (<Redirect to='/unsupportedbrowser'/>)
      }

      if(global && global.goRegister) {
        return (<Redirect to='/registration'/> );
      }

      if(global && global.completed){
        // go here if they just submitted for the first time
        if(global.submitted){
          return(
            <Redirect to='/thankyou' />
          )
        // go here if they're returning to a previously completed assessment
        }else{
          return(
              <Redirect to = '/completed' />
          )
        }
      }
      
      return goNext? <Redirect to="/home" /> : (
            sections.length > 0 && !sections[0].init && <StyledThemeWrapper theme={ themes['default']}>   
                <StyledRoot>
                    <Helmet>
                    <title>Catalyte screening</title>
                    </Helmet>         
                    <StyledHeader>
                          <StyledHeaderImage src={imgBasePath + global.config.logo} />                                  
                    </StyledHeader>                          
                    <StyledTopWrapper>
                        <StyledTop>
                          <VerticalDecorationContainer>
                            <VerticalGradientDecoration />
                          </VerticalDecorationContainer>
                          <StyledTitle>
                            <StyledTitleText> {global.config.welcome.title} </StyledTitleText>
                            <StyledSeparateBar></StyledSeparateBar>    
                            <StyledSectionCountText>{sections.length} Section{sections.length === 1? '' : 's'}</StyledSectionCountText>
                            <StyledHeaderText>{global.config.welcome.header}</StyledHeaderText>                  
                          </StyledTitle>
                          <HorizontalDecorationContainer>
                            <HorizontalGradientDecoration />
                            <BlackSquareDecoration />
                          </HorizontalDecorationContainer>
                        </StyledTop>
                    </StyledTopWrapper>
                    <DividerComponent />
                    <StyledTextArea>
                      <StyledSubtitle>{'Screening tips'}</StyledSubtitle>
                      <StyledList>
                        <StyledListItem><div dangerouslySetInnerHTML={{__html: global.config.welcome.tip1}}></div></StyledListItem>
                        <StyledListItem><div>{registrationConfig.primary.assessmentDuration}</div></StyledListItem>
                        <StyledListItem><div dangerouslySetInnerHTML={{__html: global.config.welcome.tip3}}></div></StyledListItem>
                        <StyledListItem><div dangerouslySetInnerHTML={{__html: global.config.welcome.tip4}}></div></StyledListItem>
                        <StyledListItem><div dangerouslySetInnerHTML={{__html: global.config.welcome.tip5}}></div></StyledListItem>
                        <StyledListItem><div dangerouslySetInnerHTML={{__html: global.config.welcome.tip6}}></div></StyledListItem>                       
                      </StyledList>
                      <StyledText>{global.config.welcome.email}</StyledText>
                      <StyledText>{global.config.welcome.start}</StyledText>                                                                           
                      <StyledButton onClick = {() => {setGoNext(true);}} id={'submit-btn'} text={'Start the Screening'}></StyledButton>       
                    </StyledTextArea>    
                    <FooterComponent />                    
                </StyledRoot>  
            </StyledThemeWrapper>   
        )
    }
export default WelcomePageComponent;