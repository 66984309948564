import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import { TextInputComponent }  from '../TextInputComponent/TextInputComponent';
import { colors } from '../../Themes/Colors';

const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const StyledRoot = styled.div`

`;

const StyledFormControl = styled(FormControl)`

`;

const StyledFormLabel = styled(FormLabel)`

`;

const getPrompts = (prompts) =>{
    if(!prompts){
      return(<div></div>)
    }
    let orderedPrompts = prompts.sort((a, b) => (a.order > b.order) ? 1 : -1)
    return(
      orderedPrompts.map((prompt, i) =>{
        if(prompt.valueType === 'img'){
          return (<Typography key={'prompt_'+i} variant='body1' style={{  color: colors.darkGrey }}>{prompt.value}<img alt={prompt.description} src={imgBasePath+prompt.url} ></img></Typography>);       
        }else{
          return (<Typography key={'prompt_'+i} variant='body1' style={{  color: colors.darkGrey }} dangerouslySetInnerHTML={{__html: prompt.value}}></Typography>);       
        }
        
      })
    );
  }
const CheckboxMixedComponent = (props) => {
    const dispatch = useDispatch();
    const getTextAnswer = () => {
        if(props.question.answer){
            let textAnswer = props.question.answer.filter(o => o.valueType === 'text');
            if(textAnswer.length > 0){
                return textAnswer.input;
            }
        }
    }
    const [textState, setTextState] = useState(getTextAnswer() || '');



    const handleChange = (event) => {
        const option = props.question.answer.filter(o => o.value === event.target.value)[0];
        let newAnswerVal = props.question.answer;
        if(option.isExclusive){
            newAnswerVal = newAnswerVal.map((v, i) => {
                 if(v.questionOptionID === option.questionOptionID){
                     v.input = !v.input;
                 }else if(v.valueType === 'text'){
                     v.input = '';
                 }else{
                     v.input = false;
                 }
                 return v;
            })
            setTextState('');
        }else{
            newAnswerVal = newAnswerVal.map((v, i) => {
                if(v.questionOptionID === option.questionOptionID){
                    v.input = !v.input;
                }else if(v.isExclusive){
                    v.input = false;
                }
                return v;
            })
        }
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": newAnswerVal} });
    };

    const handleTextBlur = (val) => {
        let newAnswerVal = props.question.answer.map((v, i) => {
            if(v.isExclusive && val){
                v.input = '';
            }else if(v.valueType === 'text'){
                v.input = val;
            }
            return v;
        })
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": newAnswerVal} });
    }

    const handleTextChange = (val) => {
        setTextState(val);
    }

    const getCheckState = (id) => {
        return props.question.answer.filter(o => o.questionOptionID === id)[0].input;
    }

    const getListItems = () => {
        const selectionOptions = props.question;
        let orderedOptions = selectionOptions.options.sort((a, b) => (a.order > b.order) ? 1 : -1)
        const listElements = orderedOptions.map((option, i) => {
            const choicevalue = option.value === '' ? option.optionsUrl : option.value;
            if(option.valueType === 'radioButton'){
              return (
                <FormControlLabel key={'option_'+i} value={choicevalue} control={<Checkbox size='small' checked={getCheckState(option.questionOptionID) || false} onChange={handleChange}/>}
                    label={
                        <span style={{ fontSize: '.8rem', color: colors.darkGrey }}> {option.value} </span>
                    }
                >
                </FormControlLabel>
            )
            }else if(option.valueType === 'text'){
              return (
                    <TextInputComponent
                        key={'option_'+i}
                        onChange={handleTextChange}
                        onBlur={handleTextBlur}
                        value={textState}
                        placeholder={option.value}
                    />
                )
            } 
            return <p key={`opt_warning_${i}`}>unsupported option type in mixed choice component {option.valueType}</p>
        })
        return listElements;
    }
    return (
      <div>
        <StyledRoot>
          <StyledFormControl>
            <StyledFormLabel>{getPrompts(props.question.prompts)}</StyledFormLabel>
                {getListItems()}
          </StyledFormControl>
        </StyledRoot>
      </div>
    );
}

export default CheckboxMixedComponent;