import React from 'react';
import styled from 'styled-components/macro';

const StyledRoot = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
`;

const Divider = styled.div`
    display: flex;
    height: .3em;
    margin-top: .5em;
    width: 4em;
    background-image: linear-gradient(90deg, ${props => props.theme.secondary} 0%, ${props => props.theme.tertiary} 100%);
    border-radius: 10em;
`;

const DividerText = styled.div`
    margin-left: 1em;
    margin-right: 1em;
`;

const DividerComponentWithText = (props) => {
    return(
        <StyledRoot>
            <Divider />
            <DividerText>{props.dividerText}</DividerText>
            <Divider />
        </StyledRoot>
    )
}

export default DividerComponentWithText;