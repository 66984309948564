import React from 'react';
import styled from 'styled-components/macro';
import { down } from 'styled-breakpoints';

const Root = styled.div`
  display: flex;
  justify-content: center;
`;

const RadioButtonWrapper = styled.div`
    opacity: 1;
    border-radius: 100%;
    margin: 0;
    padding: 0;
    height: 18px;
    width: 18px;
    min-width: 18px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const RadioButton = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    height: 14px;
    width: 14px;
    background: ${props => props.theme.background};
    margin: 0;
    border-radius: 100%;
    border-style: solid;
    border-width: 2px;
    border-color: ${props => props.theme.radioBorderColorUnfilled};
    padding: 0;
    &:checked{
        border-color: ${props => props.theme.radioBorderColorFilled};
        background: ${props => props.theme.radioColorFilled};
        box-shadow: none;
    }
    &:focus{
        outline: none;
    }
    cursor: pointer;
`;

const RadioButtonLabel = styled.label`
    padding-left: 0.75em;
    color: ${ props => props.theme.label };
    font-weight: 600;
    font-size: small;
    margin-right: 1.5em;

    ${(down('md'))}{
        padding-left: 0.5em;              
    }
`;

const SelectedRadioButtonLabel = styled.label`
    padding-left: 0.75em;
    color: ${ props => props.theme.accentText };
    font-weight: 600;
    font-size: small;
    margin-right: 1.5em;

    ${(down('md'))}{
        padding-left: 0.5em;                    
    }
`;

export const RadioButtonComponent = (props) => {

    const handleClick = (e) => {
        props.onChange(props.id);
    }  
 
    const renderLabel = () => {
        if(!props.label){
            return;
        }

        if(props.checked && !props.isSelectedUnbold){
            return (
                <SelectedRadioButtonLabel htmlFor={props.id} style={props.labelStyle}>{props.label}</SelectedRadioButtonLabel>
            );
        }else{
            return(
                <RadioButtonLabel htmlFor={props.id} style={props.labelStyle}>{props.label}</RadioButtonLabel>
            );
        }
    }   

    return (
        <Root className={props.className} onClick={handleClick}>
            <RadioButtonWrapper>
                <RadioButton type="radio" name={props.group} id={props.id} checked={!!props.checked} readOnly></RadioButton>
            </RadioButtonWrapper>
            { renderLabel() }
        </Root>
    );
};

export default RadioButtonComponent;