
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import DividerComponent from '../DividerComponent/DividerComponent';
import themes from '../../Themes/themes';
import SectionBorderComponent from '../ScreeningContainerComponent/SectionBorderComponent';
import FooterComponent from '../FooterComponent/FooterComponent';
import ProgressBarComponent from '../ProgressBarComponent/ProgressBarComponent';
import { up } from 'styled-breakpoints';
import { PrimaryButtonComponent } from '../PrimaryButtonComponent/PrimaryButtonComponent';
import { getClient } from '../../util/helper/Helper';

const imgBasePath = process.env.REACT_APP_env_img_base_path;

const StyledThemeWrapper = styled(ThemeProvider)``;

const Root = styled.div`
        background-color: ${props => props.theme.background};                     
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    `;

const TopWrapper = styled.div`                
        background-color: ${props => props.theme.main};
        padding-top: 50px;                
    `;

const Top = styled.div`
        margin: 0 2em;
        ${up('lg')}{
            width: 70%;
            max-width: 1300px;
            margin: 0 auto;
        }
    `;

const Header = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;     
        margin-bottom: 70px;           
        ${up('lg')}{
            margin-bottom: 110px;
        }
    `;

const ProgressBar = styled.img`        
        display: none;
        ${up('xl')}{
            height: 8px;    
            display: inline;        
        }
    `;

const Title = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 50px;
    `;

const TitleText = styled.div`         
        font-weight: bold;
        font-size: 2em;
        color: ${props => props.theme.thankyouTitleColor};
        margin-bottom: 16px;           
    `;

const TextArea = styled.div`
        display: flex;        
        flex-direction: column;    
        flex-grow: 1;
        margin: 0 2em;    
        ${up('lg')}{
            width: 70%;
            max-width: 1300px;
            margin: 0 auto;
        }
    `;

const StyledThankyouTitle = styled.div`
        float: left;
        font-size: 2em;
        font-weight: bold;
        color: ${props => props.theme.main};
    `;

const PartyImage = styled.img.attrs({
    src: './images/party.svg'
})`
        padding-left: 20px;      
        position: relative;
        top: -8px;
    `;

const GoodbyeText = styled.p`
        display: flex;               
        text-align: left;
        font-size: 1.2em;
        color: ${props => props.theme.inputText}; 
        max-width: 568px;
        flex-direction: column;
    `;

const StyledThankyouTileWrapper = styled.div`
       diplay: flex;
    `;

const StyledHeader = styled.img`
        image-rendering: -webkit-optimize-contrast;
        height: 30px;                
    `;

const StyledSeparateBar = styled.div`
        dispaly: flex;     
        width: 42px;
        ${up('lg')}{
            width: 52px;
        }
    `;

const TryAgainButton = styled(PrimaryButtonComponent)`
        margin-top: 2em;
        margin-bottom: 5em;
    `;

const TraitifyButton = styled(PrimaryButtonComponent)`
        margin-top: 2em;
        margin-bottom: 5em;
        width: 100%;
        height: 3em;
        font-size: 20px;
    `;

const VerticalCenteringDiv = styled.div`
    display: none;
    ${up('lg')} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: ${props => props.width ? props.width : 'unset'};
    }
`;

const ThankYouComponent = () => {
    const globalState = useSelector((state) => state.global);
    const sections = useSelector((state) => state.sections);
    const selectedSection = useSelector((state) => state.selectedSection);
    const dispatch = useDispatch();

    const handleButtonClick = (e) => {
        dispatch({ type: 'TRY_AGAIN', data: {} })
    }

    const handleTraitifyClick = (e) => {
        window.location = 'https://catalyte-media.traitify.com/'
    }

    return (
        <StyledThemeWrapper theme={themes['default']}>
            <Root>
                <TopWrapper>
                    <Top>
                        <Header>
                            <StyledHeader src={imgBasePath + globalState.config.logo} />
                            <VerticalCenteringDiv width='56%'>
                                <ProgressBarComponent sections={sections} currentSection={selectedSection} />
                            </VerticalCenteringDiv>
                        </Header>
                        <Title>
                            <TitleText> {'Screening Completed!'} </TitleText>
                            <StyledSeparateBar><DividerComponent /></StyledSeparateBar>
                        </Title>
                    </Top>
                    <SectionBorderComponent />
                </TopWrapper>
                <TextArea>
                    <StyledThankyouTileWrapper>
                        <StyledThankyouTitle>{globalState.config.thankyou.thankyouTitleText}</StyledThankyouTitle>
                        {globalState.config.thankyou.showPartyImage &&
                            <PartyImage />
                        }
                    </StyledThankyouTileWrapper>
                    <GoodbyeText>
                        {globalState.config.thankyou.goodbyeText}
                        {globalState.config.thankyou.enableTryAgain &&
                            <TryAgainButton
                                id={'try-again-btn'}
                                text={'Try Again'}
                                onClick={handleButtonClick}>
                            </TryAgainButton>
                        }
                        {globalState.config.thankyou.enableTraitify &&
                            <TraitifyButton
                                id={'traitify-btn'}
                                text={'TAKE TRAITIFY ASSESSMENT'}
                                onClick={handleTraitifyClick}
                                disabled={globalState.config.thankyou.enableTraitify === false} >
                            </TraitifyButton>
                        }
                    </GoodbyeText>
                </TextArea>
                <FooterComponent />
            </Root>
        </StyledThemeWrapper>
    )
}

export default ThankYouComponent;