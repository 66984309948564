import React from "react";
import styled from 'styled-components/macro';

const TimerStyle = styled.div`   
`;

const TimeCountStyle = styled.div`
    display: flex;
    align-items: center;
    color: ${ props => props.theme.accentText };    
`;

const TimerDisplay = styled.div`      
    display: flex;
    justify-content: center;
    align-items: center;
    top: 446px;
    left: 375px;
    width: 66px;
    height: 66px;
    border-radius: 800px/800px;
    background: ${props => props.theme.background};
    box-shadow: 1px 10px 35px ${props => props.theme.formShadow};
    border: 5px solid ${props => props.theme.absolute};
    opacity: 1;
    margin-right: 1em;
`;

const SpanStyle = styled.div`
    font-size: 1.6em; 
    font-weight:bold;     
`;

const StyledSeparator=styled.span`
    font-size: 1.6em; 
    font-weight:bold;  
`;

const TimingPart = styled.div` 
    display : flex;
    flex-direction : row; 
`;

const TimerImage = styled.img`
   width: 33px;
   height: 33px;
   position: relative;
   left: 2px;
`; 

const TimerDisplayerComponent = (props) => {     
    const customizedWith = props.width ? props.width : '';
    const customizedHeight = props.height?props.height: '';
    const timerSize = {width: customizedWith, height: customizedHeight};
    const imgSize ={width: props.width ?  customizedWith / 2 : '', height: props.height ? customizedHeight / 2 : ''}
    
    let timerCustomization = props.customization ? props.customization : null;
    
    if(timerCustomization){
       timerCustomization = {...timerCustomization, ...timerSize};
    }
  
    return (
        <TimerStyle className={props.className}>                    
              <TimeCountStyle >                                     
                <TimerDisplay style ={ timerCustomization } >
                    <TimerImage src={props.timerImg} style = {imgSize}/>
                </TimerDisplay>
                <TimingPart style={{color: props.textColor ? props.textColor : ''}}> 
                  <SpanStyle className="minutes" id="minute">{props.sectionTimer.timeLeft.minutes.toString().length > 1 ? props.sectionTimer.timeLeft.minutes : '0' + props.sectionTimer.timeLeft.minutes}</SpanStyle><StyledSeparator>:</StyledSeparator>              
                  <SpanStyle className="seconds" id="second"> {props.sectionTimer.timeLeft.seconds.toString().length>1 ? props.sectionTimer.timeLeft.seconds : '0'+ props.sectionTimer.timeLeft.seconds}</SpanStyle>
                </TimingPart>                
            </TimeCountStyle> 
      </TimerStyle>     
    );
  }

export default TimerDisplayerComponent;