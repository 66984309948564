const breakpoints = {
    sm: '576px',
    md: '768px',
    lg: '1160px',
    xl: '1300px',
    xxl: '1857px'
  };

export default {
    default: {
        breakpoints,
        regBgColor:"#F5F5F1",
        regFormBgColor:"#FFFFFF",
        regCardBorderColor:"#D8D7D5",
        secondaryButtonTextColor:"#070707",
        secondaryButtonBgColor:"#FFFFFF",
        secondaryButtonBorderColor:"#070707",
        mainHeader:"#070707",
        headerButtonTextColor:"#FFFFFF",
        headerButtonBgColor:"#070707",
        headerButtonBorderColor:"#FFFFFF",
        radioColorFilled:"#E8FC5E",
        radioBorderColorUnfilled:"#D8D7D5",
        radioBorderColorFilled:"#070707",
        mainTextBackground:"#F5F5F1",
        main: "#070707",
        mainText: "#070707",
        mainTwo: "#22447BB2",
        secondary: "#070707",
        tertiary: "#070707",
        quarterly: "#FFFFFF",
        background: "#F5F5F1",
        backgroundTwo: "#f9f9fa",
        formShadow: "#F5F5F1",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#F5F5F1",
        error: "#F4708F",
        label: "#070707",
        primaryText: "#070707",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#070707",
        inputBackground: "#FFFFFF",
        accentText: "#070707",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#ffffff"
    },
    untimed: {
        breakpoints,
        main: "#27487E",
        mainTwo: "#22447BB2",
        secondary: "#F79334",
        tertiary: "#F7C634",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#ffffff",
        error: "#F4708F",
        label: "#8B8B8B",
        primaryText: "#8B8B8B",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#868686",
        inputBackground: "#ECEDEF",
        accentText: "#1B3E77",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#ffffff"
    },
    ravens: {
        breakpoints,
        main: "#241773",
        mainTwo: "#000000",
        secondary: "#9E7C0C",
        tertiary: "#d6b342",
        buttonShadow: "0px 8px 18px #CCB7A146",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        headerText: "#ffffff",
        card: "#ffffff",
        error: "#F4708F",
        label: "#727272",
        primaryText: "#727272",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        inputText: "#868686",
        accentText: "#000",
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#ffffff"
    },
    dark: {
        breakpoints,
        main: "#1b1b1b",
        mainTwo: "#212121",
        secondary: "#ff0000",
        tertiary: "#27d5fd",
        buttonShadow: "0px 8px 18px #000000b0",
        quarterly: "#212121",
        background: "#282828",
        backgroundTwo: "#282828",
        formShadow: "#2f2f2f",
        card: "#0e0e0e",
        error: "#F4708F",
        label: "#ffffff",
        primaryText: "#ffffff",
        headerText: "#ffffff",
        primaryTextReg: "#ffffff",
        labelReg: "#ffffff",
        inputText: "#ffffff",
        accentText: "#95e6ff",
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#151515",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#0e0e0e"
    },
    createoppcolumbia: {
        breakpoints,
        main: "#27487E",
        mainTwo: "#22447BB2",
        secondary: "#F79334",
        tertiary: "#F7C634",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#ffffff",
        error: "#F4708F",
        label: "#8B8B8B",
        primaryText: "#8B8B8B",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#868686",
        inputBackground: "#ECEDEF",
        accentText: "#1B3E77",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "transparent linear-gradient(89deg, #27487E 0%, #124BC5CB 33%, #6294E8 60%, #F79634 100%) 0% 0% no-repeat padding-box",
        thankyouTitleColor: "#ffffff"
    },
    digmedia: {
        breakpoints,
        main: "#27487E",
        mainTwo: "#22447BB2",
        secondary: "#F79334",
        tertiary: "#F7C634",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#ffffff",
        error: "#F4708F",
        label: "#8B8B8B",
        primaryText: "#8B8B8B",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#868686",
        inputBackground: "#ECEDEF",
        accentText: "#1B3E77",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#0008b"
    },
    digmediauntimed: {
        breakpoints,
        main: "#27487E",
        mainTwo: "#22447BB2",
        secondary: "#F79334",
        tertiary: "#F7C634",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#ffffff",
        error: "#F4708F",
        label: "#8B8B8B",
        primaryText: "#8B8B8B",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#868686",
        inputBackground: "#ECEDEF",
        accentText: "#1B3E77",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#0008b"
    },
    serveng: {
        breakpoints,
        main: "#27487E",
        mainTwo: "#22447BB2",
        secondary: "#F79334",
        tertiary: "#F7C634",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#ffffff",
        error: "#F4708F",
        label: "#8B8B8B",
        primaryText: "#8B8B8B",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#868686",
        inputBackground: "#ECEDEF",
        accentText: "#1B3E77",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#0008b"
    },
    servenguntimed: {
        breakpoints,
        main: "#27487E",
        mainTwo: "#22447BB2",
        secondary: "#F79334",
        tertiary: "#F7C634",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#ffffff",
        error: "#F4708F",
        label: "#8B8B8B",
        primaryText: "#8B8B8B",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#868686",
        inputBackground: "#ECEDEF",
        accentText: "#1B3E77",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#0008b"
    },
    ba: {
        breakpoints,
        main: "#27487E",
        mainTwo: "#22447BB2",
        secondary: "#F79334",
        tertiary: "#F7C634",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#ffffff",
        error: "#F4708F",
        label: "#8B8B8B",
        primaryText: "#8B8B8B",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#868686",
        inputBackground: "#ECEDEF",
        accentText: "#1B3E77",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#0008b"
    },
    cybersec: {
        breakpoints,
        main: "#27487E",
        mainTwo: "#22447BB2",
        secondary: "#F79334",
        tertiary: "#F7C634",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#ffffff",
        error: "#F4708F",
        label: "#8B8B8B",
        primaryText: "#8B8B8B",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#868686",
        inputBackground: "#ECEDEF",
        accentText: "#1B3E77",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#0008b"
    },
    sales: {
        breakpoints,
        main: "#27487E",
        mainTwo: "#22447BB2",
        secondary: "#F79334",
        tertiary: "#F7C634",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#ffffff",
        error: "#F4708F",
        label: "#8B8B8B",
        primaryText: "#8B8B8B",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#868686",
        inputBackground: "#ECEDEF",
        accentText: "#1B3E77",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#0008b"
    },
    projman: {
        breakpoints,
        main: "#27487E",
        mainTwo: "#22447BB2",
        secondary: "#F79334",
        tertiary: "#F7C634",
        quarterly: "#F4F4F4",
        background: "#F4F5F8",
        backgroundTwo: "#f9f9fa",
        formShadow: "#6F6F6F21",
        stickyShadow: "#0000003E",
        buttonShadow: "0px 8px 18px #CCB7A146",
        card: "#ffffff",
        error: "#F4708F",
        label: "#8B8B8B",
        primaryText: "#8B8B8B",
        primaryTextReg: "#727272",
        labelReg: "#727272",
        headerText: "#ffffff",
        inputText: "#868686",
        inputBackground: "#ECEDEF",
        accentText: "#1B3E77",
        textboxColor: '#DEE0E5',
        validIcon: "./images/check.svg",
        errorIcon: "./images/error.svg",
        absolute: "#ffffff",
        tooltipText: "#084069",
        fontFamily: "Montserrat",
        registrationHeaderBackground: "",
        thankyouTitleColor: "#0008b"
    }
}