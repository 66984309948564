import React from "react";
import styled from 'styled-components/macro';
import TimerDisplayerComponent from './TimerDisplayerComponent';

const StyledDisplayer = styled(TimerDisplayerComponent)`
`
const MainTimerWrapper = styled.div`
`;

const MainTimerComponent = (props) => {
  
    return (
         <MainTimerWrapper>
            <StyledDisplayer
            timerImg ={props.timerImg}
            sectionTimer ={props.sectionTimer}
            />
         </MainTimerWrapper>
    );
  }

export default MainTimerComponent;