import React, { useContext, useEffect, useState } from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import TextInputComponent from '../TextInputComponent/TextInputComponent';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';
import RadioButtonComponent from '../RadioButtomComponent/RadioButtonComponent';
import DividerComponentWithText from '../DividerComponent/DividerComponentWithText';
import { ThemeContext } from 'styled-components';
const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const FormBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 100;
    background-color: ${props => props.theme.card};
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
`;

const InputGroup = styled.div`
    align-self: center;
    display: flex;
    flex-wrap: wrap;
`;

const StyledBullet = styled.div`
  max-height: .25em;
  min-width: .25em;
  margin-right: .5em;
  margin-top: 1.5%;
  border-radius: 100%;
  background: linear-gradient(90deg, ${props => props.theme.tertiary} 0%, ${props => props.theme.secondary} 100%);  
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  min-width: 20em;
  max-width: 20em;
  margin-bottom: .5em;
  font-size: 1.05em;
  font-weight: 400;
  letter-spacing: 0px;
  color: ${props => props.theme.primaryText};
  align-items: left; 
`;

//** Terms & Conditions */
const TermTitleWrapper = styled.div` 
    display: flex;
    width: 50%;
    align-self: center;
    color: ${props => props.theme.accentText};
    font-weight: bold;
`;

const RequiredSymbol = styled.div`
    color: red;  
    margin-bottom: 0;
    padding-left: 0.5em;
`;

const Paragraphs = styled.div`
    width: 50%;
    align-self: center;
`;

const Paragraph = styled.p`
    color: ${props => props.theme.primaryText};
    margin-bottom: 20px;
`;

const BoldStyle = styled.div`
    font-weight: bold; 
`;

const StyledLink = styled.a`
    width: 50%;
    align-self: center;
    color: ${(props) => props.theme.main};
    underline: none;
    text-decoration: none;
    padding-bottom: 1em;
`;

//** Consent Radio Button */
const RadioButtonRow = styled.div`
    width: 50%;
    align-self: center;
    margin-top: 1em;
    display: flex;
`;

const RadioButtons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 15em;
`;

const ConsentHelperText = styled.div`
    color: ${props => props.theme.error};
    margin-left: 1.5em;
    margin-top: .17em;
    font-size: .75em;
    min-height: 1.25em;
`;

//** Google SSO Elements */
const FedBtnContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FedBtn = styled.div`
  border-color: black;
  border-width: 1px;
  border: 1px solid lightgrey;

  width: 60%;
  display: flex;
  cursor: pointer;
  margin-top: 0.5em;
  height: 50px;

  border-radius: 6px;
  &:hover {
    background-color: lightgrey;
  }
`;

const GoogleLogo = styled.div`
  height: 1.5em;
  width: 1.5em;
  margin: 10px;
  margin-left: 25%
`;

const SignInWithGoogleText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  font-size: .9em;
  }    
`;

//** Divider */
const DividerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 2em;
    margin-top: 2em;
`;

const Divider = styled.div`
    display: flex;
    height: .3em;
    width: 100%;
    background-image: linear-gradient(90deg, ${props => props.theme.secondary} 0%, ${props => props.theme.tertiary} 100%);
    border-radius: 10em;
`;

const DividerComponentWithTextWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
`;

//** Email Signup elements */
const CreateAccountTitle = styled.div` 
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    color: ${props => props.theme.accentText};
    font-size: 1.5em;
    font-weight: bold;
`;

const EmailInputWrapper = styled.div`
  display: flex;
  min-height: 6.2em;
  width: 100%;  
`;

const PasswordInputWrapper = styled.div`
  display: flex;
  min-height: 6.2em;
  width: 100%;
`;

//** Buttons */
const SubmitButtonContainer = styled.div`
    margin-left: 1em;
    margin-right: 1em;
    justify-content: space-between;
    display: flex;
    padding-bottom: 4em;
    padding-top: 2em;
`;

const ContinueButtonContainer = styled.div`
    margin-left: 1em;
    margin-right: 1em;
    justify-content: center;
    display: flex;
    padding-bottom: .5em;
    padding-top: 1em;
`;

const SingleFormComponent = (props) => {
    //Theme & State
    const themeContext = useContext(ThemeContext);
    const config = useSelector(state => state.global && state.global.config || { "name": "default" })
    const client = props.client;
    
    const firstName = props.firstName;
    const lastName = props.lastName;
    const email = props.email;
    const password = props.password;
    const confirmedPassword = props.confirmedPassword;
    const consent = props.consent;

    //Cognito
    const cognitoUser = props.globalState && props.globalState.cognitoUser;
    const validCognitoPassword = props.validCognitoPassword;

    //Change Handlers
    const onFnameChange = props.onFnameChange;
    const onLnameChange = props.onLnameChange;
    const onEmailChange = props.onEmailChange;
    const onPasswordChange = props.onPasswordChange;
    const onConfirmedPasswordChange = props.onConfirmedPasswordChange;
    const onConsentChange = props.onConsentChange;

    //Blur Handlers
    const onFnameBlur = props.onFnameBlur;
    const onLnameBlur = props.onLnameBlur;
    const onEmailBlur = props.onEmailBlur;
    const onPasswordBlur = props.onPasswordBlur;
    const onConfirmedPasswordBlur = props.onConfirmedPasswordBlur;

    //Validation State
    const isValidFname = props.isValidFname;
    const isValidLname = props.isValidLname;
    const isValid = props.isValid;
    const isValidEmail = props.isValidEmail;
    const isValidPassword = props.isValidPassword;
    const isValidConfirmedPassword = props.isValidConfirmedPassword;
    const isValidConsent = props.isValidConsent;

    //Federated Sign In
    const fedSignIn = props.fedSignIn;
    const getGoogleHelperText = props.getGoogleHelperText;

    //Handlers
    const handleSubmit = props.handleSubmit;
    const onEyeClick = props.handleEyeClick;
    const handleOnKeyDown = props.handleOnKeyDown;

    //Helpers
    const getEmailHelper = props.getEmailHelper;
    const getPasswordHelperText = props.getPasswordHelperText;
    const getConsentHelperText = props.getConsentHelperText;

    const renderListitems = (items) => {
        return items.map((v, i) => {
            return <StyledListItem key={i}><StyledBullet />{v}</StyledListItem>
        })
    }

    return (
        <FormBox onKeyDown={handleOnKeyDown} tabIndex={0}>
            {/* Terms & Conditions, Privacy Policy, and accept radio button */}
            <TermTitleWrapper>Terms and Conditions<RequiredSymbol>*</RequiredSymbol></TermTitleWrapper>
            <Paragraphs>
                {config.registration.termsAndConditions.texts.map((v, i) => {
                    return (<Paragraph key={i}>{v}</Paragraph>)
                })}
                <BoldStyle>
                    {config.registration.termsAndConditions.boldTexts.map((v, i) => {
                        return (<Paragraph key={i}>{v}</Paragraph>)
                    })}
                </BoldStyle>
            </Paragraphs>
            <StyledLink href="https://www.catalyte.io/privacy/" target="_blank" rel="noreferrer">
                Catalyte privacy policy
            </StyledLink>
            <RadioButtonRow>
                <RadioButtons>
                    <RadioButtonComponent id="agree" onChange={onConsentChange} group="consent" label="I accept all terms and conditions" checked={consent === "agree"} isSelectedUnbold={true} labelStyle={{ fontSize: 'medium', fontWeight: 600 }} />
                </RadioButtons>
                <ConsentHelperText>{isValidConsent !== true ? getConsentHelperText(consent) : ''}</ConsentHelperText>
            </RadioButtonRow>
            {
                !cognitoUser &&
                <div style={{width: '50%', alignSelf: 'center'}}>
                    <DividerWrapper>
                        <Divider></Divider>
                    </DividerWrapper>
                    {/* Email and Password registration */}
                    <CreateAccountTitle>Create Your Account</CreateAccountTitle>
                    <InputGroup>
                        {
                        (!cognitoUser || (!cognitoUser.attributes.given_name || !cognitoUser.attributes.family_name)) &&
                        <>
                            <TextInputComponent
                                id={'fname-input'}
                                error={isValidFname === null ? false : !isValidFname}
                                helperText={'Must be at least one and less than 256 characters'}
                                type="text"
                                onChange={onFnameChange}
                                value={firstName}
                                backgroudColor={themeContext.quarterly}
                                onBlur={onFnameBlur}
                                required={true}
                                isValid={isValidFname}
                                label='First Name'
                            />
                            <TextInputComponent
                                id={'lname-input'}
                                error={isValidLname === null ? false : !isValidLname}
                                helperText={'Must be at least one and less than 256 characters'}
                                type="text"
                                onChange={onLnameChange}
                                onBlur={onLnameBlur}
                                isValid={isValidLname}
                                value={lastName}
                                backgroudColor={themeContext.quarterly}
                                required
                                label='Last Name'
                            />
                        </>
                        }
                        <EmailInputWrapper>
                            <TextInputComponent
                                id={'email-input'}
                                error={(isValidEmail !== null && (props.userExists || !isValidEmail)) ? true : false}
                                helperText={getEmailHelper()}
                                type="text"
                                onChange={onEmailChange}
                                onBlur={onEmailBlur}
                                value={email}
                                backgroudColor={themeContext.quarterly}
                                isValid={isValidEmail !== null && (!props.userExists && isValidEmail)}
                                label='Email'
                                required
                            />
                        </EmailInputWrapper>
                    </InputGroup>
                    <InputGroup>
                        <PasswordInputWrapper>
                            <TextInputComponent
                                id={'pwd-input'}
                                error={isValidPassword === null ? false : !isValidPassword}
                                helperText={getPasswordHelperText(password, validCognitoPassword)}
                                type={props.passwordType}
                                isEyeButton={props.isShowPWEyeButton}
                                onChange={onPasswordChange}
                                onEyeClick={onEyeClick}
                                value={password}
                                onBlur={onPasswordBlur}
                                onFocus={props.onPWFocus}
                                required={true}
                                isValid={isValidPassword}
                                label='Password'
                                backgroudColor={themeContext.quarterly}
                            />
                        </PasswordInputWrapper>
                    </InputGroup>
                    <InputGroup>
                        <PasswordInputWrapper>
                            <TextInputComponent
                                id={'cnfrm-input'}
                                error={isValidConfirmedPassword === null ? false : !isValidConfirmedPassword}
                                helperText={confirmedPassword ? 'Passwords do not match.' : 'You must confirm your password.'}
                                type={props.confirmPasswordType}
                                isEyeButton={props.isShowCPWEyeButton}
                                onChange={onConfirmedPasswordChange}
                                onEyeClick={onEyeClick}
                                value={confirmedPassword}
                                onBlur={onConfirmedPasswordBlur}
                                onFocus={props.onCPWFocus}
                                required={true}
                                isValid={isValidConfirmedPassword}
                                label='Confirm Password'
                                backgroudColor={themeContext.quarterly}
                            />
                        </PasswordInputWrapper>
                    </InputGroup>
                    <ContinueButtonContainer>
                        <PrimaryButtonComponent
                            id={'submit-btn'}
                            innactive={!isValid}
                            onClick={handleSubmit}
                            text={!cognitoUser ? 'Continue' : 'Continue'}
                            className={'g-recaptcha'}
                            data-sitekey='6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C'
                            data-callback={handleSubmit}
                            data-action='submit'
                        ></PrimaryButtonComponent>
                    </ContinueButtonContainer>
                    <DividerComponentWithTextWrapper>
                        <DividerComponentWithText dividerText={'OR'} />
                    </DividerComponentWithTextWrapper>
                    {/* Google registration */}
                    <FedBtnContainer>
                        <FedBtn onClick={isValidConsent === true ? fedSignIn : null} style={{ opacity: isValidConsent ? '1' : '0.5' }}>
                            <GoogleLogo style={{ backgroundImage: `url(${imgBasePath}logo_google.svg)`, margin: '10px' }} id="googLogo" />
                            <SignInWithGoogleText>Continue with Google</SignInWithGoogleText>
                        </FedBtn>
                    </FedBtnContainer>
                </div>
            }
            {/* Submit and Back buttons */}
            <SubmitButtonContainer>
            {
                cognitoUser &&
                <PrimaryButtonComponent
                    id={'submit-btn'}
                    innactive={!isValid}
                    onClick={handleSubmit}
                    text={!cognitoUser ? 'Continue' : 'Continue'}
                    className={'g-recaptcha'}
                    data-sitekey='6Lfvaz4aAAAAAKj9Lvx8KB-3AqEGt3TrbTVc-x3C'
                    data-callback={handleSubmit}
                    data-action='submit'
                ></PrimaryButtonComponent>
            }
            </SubmitButtonContainer>
        </FormBox>
    );
}

export default SingleFormComponent;
