import QuestionParentComponent from '../QuestionParentComponent/QuestionParentComponent';
import React from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components/macro';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 367px;
`;

const ParentQuestion = styled(QuestionParentComponent)`
    margin: 0;    
    min-height: 110px;
    min-width: 16em;
`;

const ChildQuestion = styled(QuestionParentComponent)`
    margin: 0;
    padding: 0;
    position: relative;
`;

const ConditionalQuestionGroupComponent = (props) => {
    const dispatch = useDispatch();
    const childConditionIsMet = () => { 
        return props.parentQuestion.answer === props.childQuestion.condition;
    }   

    const onParentQuestionChange = (answer) =>{
        if(answer !== props.childQuestion.condition) { 
            if(props.childQuestion.type.indexOf('text') > -1){ 
                dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.childQuestion.uuid, "id": props.childQuestion.questionID, "answer": null} });
            }
            else{
                if(props.childQuestion.answer && props.childQuestion.type.indexOf('text') === -1){ 
                    props.childQuestion.answer.forEach(v =>{v.input = ''; });
                }
                dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.childQuestion.uuid, "id": props.childQuestion.questionID, "answer": props.childQuestion.answer} });
            }
        }
    }
 
    return (   
        <Root className={props.className}>              
            <ParentQuestion question={props.parentQuestion} onChange={onParentQuestionChange} ></ParentQuestion>
            {childConditionIsMet() ? <ChildQuestion question={props.childQuestion}></ChildQuestion> : <></>}
        </Root>
    );   
}

export default ConditionalQuestionGroupComponent;