import React, {useState, useEffect} from 'react';
import styled from "styled-components/macro";
import { useDispatch } from 'react-redux';
import TextInputComponent from '../TextInputComponent/TextInputComponent';
import { up } from 'styled-breakpoints';

const Tile = styled.div`
    width: 100%;
    margin-bottom: .5em;
    ${up('lg')} {        
        width: calc(50% - 1em);
        margin: 0 0 2.5em 0;
    }    
`

const QuestionGroup = styled.div`    
    position: relative;    
    opacity: 1;
    ${up('lg')} {
        background: ${props => props.theme.backgroundTwo};    
        border-radius: 35px;
        box-shadow: 4px 20px 70px ${props => props.theme.formShadow};
        border: 5px solid ${props => props.theme.absolute};
    }
`;

const GroupLabel = styled.div`
    display: flex;
    color: ${props => props.theme.accentText};
    font-weight: 600;
    font-size: x-large;
    margin-left: 1.5em;    
    ${up('lg')} {
        margin-bottom: 1em;
    }
`;

const GroupFormWrapper = styled.div`    
    margin-left: 2em;
    margin-right: 2em;    
    padding-top: 2em;
    padding-bottom: 2em;    
`;

const TitleAndSupervisorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${up('xl')} {
        flex-direction: row;
        > div {
            width: calc(50% - 1em);
        }
                
        > div:last-child{
            margin-left: auto;
        }
    }
`;

const JobTitleInput = styled(TextInputComponent)`

`;

const SupervisorNameInput = styled(TextInputComponent)`

`;

const JobDescriptionWrapper = styled.div`
    display: flex;      
    border-radius: 1em;
`;

const JobDescriptionInput = styled.textarea`    
    height: 148px;
    display: block;    
    border-style: unset;
    border-radius: 1em;
    outline: none;
    opacity: 1;
    resize: none;    
    background-color: ${props => props.theme.inputBackground};
    color: ${props => props.theme.inputText};
    font-size: 17px;
    padding-top: 10px;
    padding-left: 1em;  
    flex-grow: 1;
    &:hover{
        border: none;
    }
    &:focus{
        border: none;
        outline: none;
    }
`;

const JobDescriptionLabel = styled.label`
    display: flex;
    color: ${props => props.theme.label};
    font-weight: 600;
    margin-bottom: 1em;
`;

const ReasonForLeavingInput = styled(TextInputComponent)`
`;


const HideGroupButtonWrapper = styled.div`
    width: 3em;
    height: 3em;    
    display: flex;        
    opacity: 1;    
    cursor: pointer;
    position: absolute;
    top: -38px;
    right: 32px;
    border: 3px solid #FFF;
    border-radius: 100%;
    ${up('lg')} { 
        border: 0;
        top: -28px;
        right: -24px;
        box-shadow: 1px 10px 35px ${props => props.theme.formShadow};
        background: ${props => props.theme.backgroundTwo};
    }
`;

const HideGroupButton = styled.div`
    width: 18px;
    height: 22px;
    border: none;
    background: transparent url(./images/delete.svg) 0% 0% no-repeat padding-box;
    background-size: cover;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const JobHistoryForm = (props) => {
    const dispatch = useDispatch();

    const [jobTitle, setJobTitle] = useState(props.questions[0].answer ? props.questions[0].answer.input ?? '' : '');
    const [supervisorName, setSupervisorName] = useState(props.questions[1].answer ? props.questions[1].answer.input ?? '' : '');
    const [jobDescriptionName, setJobDescriptionName] = useState(props.questions[2].answer ? props.questions[2].answer.input ?? '' : '');
    const [reasonForLeaving, setreasonForLeaving] = useState(props.questions[3].answer ? props.questions[3].answer.input ?? '' : '');

    //** Change handlers **/
    const onJobTitleChange = (val) => {
        setJobTitle(val);
    }

    const onSupervisorNameChange = (val) => {
        setSupervisorName(val);
    }

    const onJobDescriptionChange = (e) => {
        setJobDescriptionName(e.target.value);
    }

    const onReasonForLeavingChange = (val) => {
        setreasonForLeaving(val);
    }

    useEffect(() => {
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.questions[0].uuid, "id": props.questions[0].questionID, "answer": { input: props.questions[0].answer ? props.questions[0].answer.input : '', groupOrder: props.groupOrder} } });
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.questions[1].uuid, "id": props.questions[1].questionID, "answer": { input: props.questions[1].answer ? props.questions[1].answer.input : '', groupOrder: props.groupOrder} } });
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.questions[2].uuid, "id": props.questions[2].questionID, "answer": { input: props.questions[2].answer ? props.questions[2].answer.input : '', groupOrder: props.groupOrder} } });
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.questions[3].uuid, "id": props.questions[3].questionID, "answer": { input: props.questions[3].answer ? props.questions[3].answer.input : '', groupOrder: props.groupOrder } } });
      }, [])


    //** Blur handlers **/
    const onJobTitleBlur = (val) => {
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.questions[0].uuid, "id": props.questions[0].questionID, "answer": { input: val, groupOrder: props.groupOrder} } });
    }

    const onSupervisorNameBlur = (val) => {
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.questions[1].uuid, "id": props.questions[1].questionID, "answer": { input: val, groupOrder: props.groupOrder} } });
    }

    const onJobDescriptionBlur = (e) => {
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.questions[2].uuid, "id": props.questions[2].questionID, "answer": { input: e.target.value, groupOrder: props.groupOrder} } });
    }

    const onReasonForLeavingBlur = (val) => {
        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.questions[3].uuid, "id": props.questions[3].questionID, "answer": { input: val, groupOrder: props.groupOrder } } });
    }
    
    const renderQuestions = (questions) => {
        
        return(
            <GroupFormWrapper>
                <TitleAndSupervisorWrapper>                    
                    <JobTitleInput 
                        value={jobTitle}
                        onChange = {onJobTitleChange} 
                        onBlur = {onJobTitleBlur}        
                        type="text"         
                        label="Job Title"
                    />                    
                    <SupervisorNameInput 
                        value={supervisorName}
                        onChange = {onSupervisorNameChange} 
                        onBlur = {onSupervisorNameBlur}        
                        type="text"         
                        label="Supervisor Name"
                    />                    
                </TitleAndSupervisorWrapper>
                <div style={{marginBottom: '1em'}}>
                    <div>
                        <JobDescriptionLabel>{"Job Description"}</JobDescriptionLabel>
                        <JobDescriptionWrapper>
                            <JobDescriptionInput value={jobDescriptionName} onChange={onJobDescriptionChange} onBlur={onJobDescriptionBlur}></JobDescriptionInput>
                        </JobDescriptionWrapper>
                    </div>
                </div>
                <div>
                    <div>
                        <ReasonForLeavingInput 
                            value={reasonForLeaving}
                            onChange = {onReasonForLeavingChange} 
                            onBlur = {onReasonForLeavingBlur}        
                            type="text"         
                            label="Reason for Leaving"
                        />
                    </div>
                </div>
            </GroupFormWrapper>
        )
    }

    const handleHideQuestionGroup = () => {
        props.onHideGroup(props.group);
    }

    if( props.groupOrder === 1){
        return (
            <Tile>
                <GroupLabel>Latest Role</GroupLabel>
                <QuestionGroup>                    
                    {renderQuestions(props.questions)}
                </QuestionGroup>
            </Tile>
        )
    } else {
        return (
            <Tile>
                <GroupLabel>Previous Role</GroupLabel>
                <QuestionGroup>                    
                    <HideGroupButtonWrapper onClick={handleHideQuestionGroup} data={"group_"+props.group}>
                        <HideGroupButton data={"group_"+props.group}></HideGroupButton>
                    </HideGroupButtonWrapper>                    
                    {renderQuestions(props.questions)}
                </QuestionGroup>
            </Tile>
        )
    }
}

export default JobHistoryForm;