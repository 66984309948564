import React from 'react';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

const HorizontalSpinnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 201;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PulsingDotContainer = styled('div')`
  min-height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  align-items: center;
  margin: ${(props) => (props.size / 2.5).toFixed(1)}px;
`;

const pulseAnimation = keyframes`
  0% { transform: scale(0); visibility: visible}
  50% { transform: scale(1); }
  100% { transform: scale(0); }
`;

const PulsingDot = styled('div')`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  ${(props) =>
    !!props.borderColor.length && `border: solid 2px ${props.borderColor}`};
  background-color: ${(props) => props.color};
  border-radius: 50%;
  animation-name: ${pulseAnimation};
  animation-duration: ${(props) => props.speed}s;
  animation-delay: ${(props) => props.delay}s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  visibility: hidden;
`;

const getRandom = () => {
  return uuidv4();
};

const PulseSpinnerComponent = (props) => {
  const {
    dotColorArray = ['#F7C634', '#F7C634', '#F79334', '#F79334'],
    dotSpeedArray = [1.8, 1.8, 1.8, 1.8],
    dotDelayArray = [0, 0.2, 0.4, 0.6],
    size = 28,
    borderColor = ''
  } = props;
  const dotElementArray = [];

  dotColorArray.forEach((color, index) => {
    dotElementArray.push(
      <PulsingDotContainer id={`pulsing-dot-container-${index}`} key={getRandom()} size={size}>
        <PulsingDot
          id={`pulsing-dot-${index}`}
          key={getRandom()}
          color={dotColorArray[index]}
          speed={dotSpeedArray[index]}
          delay={dotDelayArray[index]}
          size={size}
          borderColor={borderColor}
        />
      </PulsingDotContainer>
    );
  });

  return (
    <HorizontalSpinnerContainer id="horizontal-spinner-container" key={getRandom()}>
      {dotElementArray}
    </HorizontalSpinnerContainer>
  );
};

export default PulseSpinnerComponent;