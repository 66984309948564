import React from 'react';
import styled from 'styled-components/macro';
import MultipleRadiobuttonComponent from '../MultipleRadiobuttonComponent/MultipleRadiobuttonComponent';
import QuestionComponent from '../QuestionComponent/QuestionComponent';
import QuestionAreaComponent from '../QuestionAreaComponent/QuestionAreaComponent';
import ParagraphComponent from '../ParagraphComponent/ParagraphComponent';
import MultipleCheckboxComponent from '../MultipleCheckboxComponent/MultipleCheckboxComponent';
import NumberDdlPairComponent from '../NumberDdlPairComponent/NumberDdlPairComponent';
import CheckboxMixedComponent from '../CheckboxMixedComponent/CheckboxMixedComponent';
import LikertScaleComponent from '../LikertScaleComponent/LikertScaleComponent';
import VerticalRadioQuestionComponent from '../VerticalRadioQuestionComponent/VerticalRadioQuestionComponent';
import LargeImageOptionsComponent from '../LargeImageOptionsComponent/LargeImageOptionsComponent';
import { useDispatch } from 'react-redux';
import AutocompleteComponent from '../DropDownListComponent/AutocompleteComponent';
import DropdownMultipleSelectComponent from "../DropDownListComponent/DropdownMultipleSelectComponent";
import NumberQuestionComponent from '../NumberQuestionComponent/NumberQuestionComponent';
import { useMediaQuery } from 'react-responsive';
import TextRadioGroupComponent from "../TextRadioGroupComponent/TextRadioGroupComponent";
import LargeImageQuestionComponent from '../LargeImageQuestionComponent/LargeImageQuestionComponent';
import { up } from 'styled-breakpoints';

const StyledRoot = styled.div`
    background-color: ${props => props.theme.background};   
    flex: 1; 
`;

const DenseRoot = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-start; 
    align-items: center;   
`;

const VerticalRoot = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
`;

const StyledVerticalRadioComponent = styled(VerticalRadioQuestionComponent)`
`;

const QuestionParentComponent = (props) => {
    const showRequired = props.question.required && props.question.type !== 'likert';
    const isBigScreen = useMediaQuery({ query: '(min-width: 1160px)' });

    //TODO: Refactor this component shouldn't need to know what section it's in
    let isDemographics = false;
    let isPersonal = false;
    let textboxBgColor = '#DEE0E5';
    if( props.question.class === 'text.generalInformation.demographics'){
        isDemographics = true;
    }
    else if (props.question.class === 'conditional.text.generalInformation.personal'){
        isPersonal = true;
    }

    const dispatch = useDispatch();
    const handleQuestionUpdate = (data) => {

        if(props.onChange){
            props.onChange(data);

        }

        if(data !== '' && data !== null){
            dispatch({ type: 'UPDATE_SECTION_QUESTION', data});
        }
    }

    const options = props.question.options;  

    //TODO: Refactor. Render details should be in lower level components

    let dropDownHeight = 0;
    let dropdownWidth = 0; 
    const optionList = [];  
    let dropdownOptionList = [];

    //TODO: Refactor. This case should be part of this main switch that maps to question types. The ddl specific steps should be in a lower level component

    if(props.question.type.toLowerCase().indexOf('ddl') >= 0)
    {
        for(let i =0; i<options.length; i++){ 
            if(options[i].valueType !== 'placeholder') //we don't want placeholder in option
            { 
                dropdownOptionList.push(options[i]);
                //determine the width of dropdown based on the width of text string
                if(dropdownWidth < options[i].value.length){
                    dropdownWidth = options[i].value.length;
                }
            }
        }

       //calculate the size of box based on dropdown option text length
        dropDownHeight = options.length * 32 > 308 ? 308 : options.length * 39; 
         
        dropdownWidth = dropdownWidth * 8.6;
        if(dropdownWidth < 230){
            dropdownWidth = 230;
        } 
        if(dropDownHeight > 265)  {
            dropDownHeight = 265;
        }
       
        //sort the option based defined type 
        if(props.question.type.toLowerCase().indexOf('ordered') >=0){
            dropdownOptionList = dropdownOptionList.sort((a,b) => (a.order > b.order) ? 1 : -1);
        }else{
            dropdownOptionList = dropdownOptionList.sort((a,b) => (a.value > b.value) ? 1 : -1);        
        }
 
        //convert to value array and put 'Other' or 'Decline to Answer' to the last of the list
        let otherItem='';
        for(let i =0; i<dropdownOptionList.length; i++) {
            if(dropdownOptionList[i].value.toLowerCase().indexOf('other') < 0 && 
               dropdownOptionList[i].value.toLowerCase().indexOf('decline to answer') < 0){
                optionList.push(dropdownOptionList[i].value);
               }               
            else{
                otherItem = dropdownOptionList[i].value;
            }
        } 
        if(otherItem) {
            optionList.push(otherItem);
        } 

    }     
  
    const getPlaceholder = (options) => {
        const placeholder = options.filter((v, i) => {
            return v.valueType === 'placeholder';
        })

        if(placeholder && placeholder.length > 0){
            return placeholder[0].value;
        }else{
            return null;
        }
    }

    const renderQuestion = () => { 
        if(!props.question){
                return (<StyledRoot></StyledRoot>)
        }
        else if (props.question.type === 'text.area') {
            return (
              <QuestionAreaComponent question={props.question} onChange={props.onChange} boxWidth={250} bgColor={isPersonal ? textboxBgColor : null} />
            );
        }
        else if(props.question.type === 'checkbox.text' ){
            return (
                <CheckboxMixedComponent question={props.question} />
            )
        }
        else if(props.question.type === 'number.ddl'){
            return (
                <NumberDdlPairComponent question={props.question} />
            )
        }else if(props.question.type === 'multipleChoice.puzzle'){             
            return(
                <LargeImageQuestionComponent question={props.question} />
            )
        }
        else if(props.question.type === 'number'){ 
            return(             
                <NumberQuestionComponent question={props.question} style={{width: '100%'}}  />
            )
        }
        /*TODO: 
        * 1. this condition statement is for multiple radiobutton, and excludes 'multipleselect'
        * 2. move out all the logic about demographics page to other component
        * 3. condition on 'multipleselect' needs to be removed
        */
       else if((!(props.question.type.toLowerCase().indexOf('multiple') === -1) || props.question.type.toLowerCase().indexOf('parent.radio') >=0 || props.question.type.toLowerCase().indexOf('multiple.radio') >=0 ) &&
                 props.question.type.toLowerCase().indexOf('ddl.multipleselect') <0){  
            return (
                <MultipleRadiobuttonComponent question={props.question} required={showRequired} onChange={props.onChange} />        
            )
        }
        else if(props.question.type === 'text.image.large'){
            return(
                <LargeImageOptionsComponent question={props.question}></LargeImageOptionsComponent>
            )
        }
        else if(!(props.question.type.toLowerCase().indexOf('text') === -1) || props.question.type === 'zipCode' || props.question.type === 'phone'){
            
            return (
                <QuestionComponent question={props.question} onChange={props.onChange} boxWidth={250} bgColor={isPersonal ? textboxBgColor : null } /> 
            )
        }        
        else if(props.question.type.indexOf('multipleselect') >= 0 ){                
               
            return (<> 
                        <DropdownMultipleSelectComponent 
                            onChange={handleQuestionUpdate}                       
                            question={props.question} 
                            required={props.question.required}      
                            textShift={5}
                            fontSize={14}
                        /> 
                    </> 
            ) ;       
            
        }
        else if(props.question.type.toLowerCase().indexOf('ddl') >= 0 ){ 
            return (<> 
                        <AutocompleteComponent 
                            onChange={handleQuestionUpdate}
                            selectionOption={optionList}
                            value={props.question.answer}
                            helperText={''}                           
                            question={props.question}
                            required={props.question.required}      
                            textShift={10}
                            width={ dropdownWidth} 
                            height={48} 
                            fontSize={14}
                            placeholder={getPlaceholder(props.question.options) ? getPlaceholder(props.question.options) : 'Please select'} 
                            dropDownHeight={dropDownHeight}
                        /> 
                    </> 
            ) ;             
        }             
        else if(props.question.type === 'noAnswer'){
            return(
                <ParagraphComponent question={props.question} />
            )
        }  
        else if(props.question.type === 'checkbox'){
            return(
                <MultipleCheckboxComponent question={props.question} />
            )
        }else if(props.question.type === 'date.radio'){
            return (
                <TextRadioGroupComponent question={props.question} />
            )
        }
        else if(props.question.type === 'likert'){
            return(
                <LikertScaleComponent question={props.question} />
            )
        }
        else if(props.question.type === 'radio.vertical'){            
            return <StyledVerticalRadioComponent question={props.question} />            
        }  
        else{
            return (
                <StyledRoot>
                    <p>Question Type not supported {props.question.type}</p>
                    <p>Question uuid {props.question.uuid}</p>
                </StyledRoot>
                )
        }
    }
  
    switch(props.question.type){ 
        
        case "likert":
            return(
                <DenseRoot className={props.className}>
                    {renderQuestion()}
                </DenseRoot>
            )
        case "radio.vertical":
        case "text.image.large":
            return(
                <VerticalRoot className={props.className} style={{width: isBigScreen ? '' : '100%'}}>
                    {renderQuestion()}
                </VerticalRoot>
            )
        default:        
                return (
                    <StyledRoot className={props.className}>
                        {renderQuestion()}
                    </StyledRoot>
                )
        
    }
}

export default QuestionParentComponent;