import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import ChartComponent from '../ChartComponent/ChartComponent';
import VerticalRadioQuestionComponent from '../VerticalRadioQuestionComponent/VerticalRadioQuestionComponent';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled'
import MobileChartComponent from '../ChartComponent/MobileChartComponent';
import traitify from "traitify-widgets";

const StyledRoot = styled.div`
    display: flex;    
    flex-direction: column;    
    margin-right: 2em;
    margin-left: 2em;   
    ${up('xl')} {
        margin-right: 0;
        margin-left: 0;
    }
`;

const StyledBody = styled.div`
    display: flex;
    flex-wrap: wrap;    
    ${up('xl')} {
        margin-right: 0;
        margin-left: 0;
    }
`;

const TraitifySectionComponent = ({onNextSection}) => {    
    const selectedSection = useSelector((state) => state.selectedSection);
    const global = useSelector((state) => state.global)

    useEffect(() => {
        
        if(global && global.dataConfig && global.dataConfig.tratifyApiKey){
            traitify.setHost("https://api.traitify.com");
            traitify.setPublicKey(global.dataConfig.tratifyApiKey);
        }else{
            return
        }

        //Skip Traitify submission if not in prd
        if(selectedSection.traitifyAssessmentID){
            let assessment = traitify.ui.component();

            if(process.env.REACT_APP_env_nodeenv == 'production'){
                assessment.on("SlideDeck.Finished", function(){
                    console.log("SlideDeck.Finished");
                    onNextSection()
                  });
            }else{
                assessment.on("SlideDeck.UpdateSlide", function(){
                    console.log("SlideDeck.UpdateSlide");
                    onNextSection()
                  });
            }
            
            assessment.assessmentID(selectedSection.traitifyAssessmentID);
            assessment.target("#tratify-element");
            assessment.render();
        }
    }, [selectedSection.traitifyAssessmentID, global.dataConfig])

    return (
        <StyledRoot> 
            <StyledBody>
                <div style={{display: 'flex', width: '100%'}} id="tratify-element"></div>
            </StyledBody>
        </StyledRoot>
    );
}

export default TraitifySectionComponent;
