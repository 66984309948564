import { combineReducers, Action } from 'redux';
import globalReducer, { GlobalState } from '../globalReducer/globalReducer';
import registrationReducer, { RegistrationState } from '../registrationReducer';
import sectionReducer from '../sectionReducer';
import selectedSectionReducer from '../selectedSectionReducer';
import sectionTimerReducer from '../sectionTimerReducer';
import flagReducer from '../flagReducer';

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT'){
    state = undefined;
  } 
  return combineReducers({
    global: globalReducer,
    registration: registrationReducer,
    sections: sectionReducer,
    selectedSection: selectedSectionReducer,
    sectionTimer: sectionTimerReducer,
    flags: flagReducer
  })(state, action);
};

export default rootReducer;
