import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import TextAreaComponent from '../TextAreaComponent/TextAreaComponent';

const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const StyledRoot = styled.div`
  display: flex;
`;

const TextAreaStyled = styled.textarea`
  max-width: 100%;
  min-height: 100px;
`;

const QuestionAreaComponent = (props) => {
  const [qText, setQText] = useState(!props.question.answer ? "" : props.question.answer);
  const [isDataValid, setIsDataValid] = useState(null);
  const dispatch = useDispatch();

  if (!props.question) {
    return (<div></div>);
  }

  const handleChange = (answer) => {
    let formatedData;

    if(props.onChange){
      props.onChange(answer);
    }

    if(props.handleDataValidation) {
      formatedData = props.handleDataValidation(answer, props.validationType, props.requriedDataLength );
    }   

    if(formatedData){          
        setQText(formatedData);          
    }
    else if(formatedData == null && props.validationType != null){
        setQText('');
    }
    else{
        setQText(answer);
    }
 } 

  const handleOnBlur = (answer) => {   
    if(props.validationType){
      if(props.isDatavalid && props.isDatavalid(answer)){           
          setQText(answer);   
          setIsDataValid(true);             
        } 
        else{
          setIsDataValid(false);  
          return;
        }
    }
    else{
      setQText(answer);
    }

    dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": answer} });
 }

 const getDataHelper = () => {
  if(isDataValid === false){
    return props.helpText;
  }   
  return '';
}

  const getPlaceholder = (qst) => {
    let placeHolderVal = "";

    let placeHolderArr = qst.options.filter((v, i) => {
      return v.valueType === 'placeholder';
    });

    if(placeHolderArr.length > 0){
      placeHolderVal = placeHolderArr[0].value;
    }else if(qst.type.toLowerCase().indexOf('date') >= 0){
      placeHolderVal = 'xx/xx/xxxx';
    }

    return placeHolderVal;
 }

 const getPrompt = (prompts) =>{
  if(prompts && prompts.length > 0){
    if(prompts[0].url){ 
      return(
        <img alt={''} src={imgBasePath+prompts[0].url} ></img>
      )
    }else{
      return prompts[0].value;
    }
  }else{
    return "";
  }
} 

  const question = props.question;

  return (
    <StyledRoot className={props.className}>
        <TextAreaComponent
          onChange={handleChange}
          onBlur={handleOnBlur}
          value={qText || ""}
          type="text.area"
          maxLength={3000}
          rows={5}
          placeholder={getPlaceholder(question)}
          required={props.required}
          helperText={props.validationType ? getDataHelper() : ''}
          isValid={isDataValid !== null && isDataValid} 
          label ={getPrompt(question.prompts)}                      
        />
    </StyledRoot>
  );
}

export default QuestionAreaComponent;
