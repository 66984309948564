import React from 'react';
import { createGlobalStyle } from 'styled-components';

const StyledGlobal = createGlobalStyle`
  html {
    background-color: ${props => props.theme.background};
  }

  body {
    background-color: ${props => props.theme.background};
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;p
    -moz-osx-font-smoothing: grayscale;

    * {
      box-sizing: border-box;
      image-rendering: -webkit-optimize-contrast;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  #root {
    min-height: 100vh;
  }
  
  button:focus {
    outline: none;
  }

  div:focus {
    outline: none;
  }

  .katex {
    font-size: inherit !important;
  }
`;

const GlobalStyles = () => (
      <StyledGlobal />
);

export default GlobalStyles;
