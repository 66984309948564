import React from 'react';
import styled from 'styled-components/macro';
import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';

const imgBasePath = process.env.REACT_APP_env_svg_base_path + 'info.svg';
 
const TooltipStyle = styled.div`    
`;

const TooltipIcon = styled.div`
    background-image: url(${imgBasePath});
    background-size: 75px;
    background-repeat: no-repeat;
    width: 18px;
    padding-top: 31px;
    background-position: center;
`;

const ReactTooltipStyled = styled(ReactTooltip)`
    box-shadow: 0px 25px 40px ${props => props.theme.formShadow} !important;
    border-radius: 1.5em !important;
    background-color: white !important;
    border-color: white !important;
    text-align: center !important; 
    width: 10% !important;
    min-width: 200px !important;
    line-height: 95% !important;
    color: ${props => props.theme.tooltipText} !important;
`;

const TooltipComponent = (props) =>{    
  
    const tipMessage = props.tooltip ? props.tooltip : '';
    const tipId = 'tooltip' + props.tooltipid ? props.tooltipid : '1';
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

    return (<>
              <TooltipStyle
                  data-tip={''}
                  data-for={tipId}
                  data-place='top'
                  data-event={isSmallScreen ? 'click focus': ''}
                  data-html={true}
                  data-delay-hide={isSmallScreen ? '' : '100'}
              >
                  <TooltipIcon />
              </TooltipStyle>
              <ReactTooltipStyled 
                  id={tipId}
                  globalEventOff='click'
                  multiline={true}
                  border={true}
                  type={'info'}
                  offset={{top: 5}}
                  effect={'float'}
                  arrowColor={'white'}
                  wrapper={'div'}
              >
                  {tipMessage}
              </ReactTooltipStyled>
           </>
    );
}

export default TooltipComponent;