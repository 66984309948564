import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import PrimaryButtonComponent from '../PrimaryButtonComponent/PrimaryButtonComponent';

const StyledContainer = styled.div`
  display: flex;
  margin: 10%;
  flex-direction: column; 
  align-items: left;
  
`;

const ProgramOverviewTitle = styled.h1`
  font: normal normal 900 22px/20px;
  letter-spacing: 0px;
  color: ${props => props.theme.accentText};
  text-align: left;
`;

const ProgramOverviewText = styled.div`
  margin-bottom: 1em;
  text-align: left;
  font: normal normal medium 14px/19px;
  letter-spacing: 0px;
  color: ${props => props.theme.primaryText};
`;

const ApplicantRequirementsHeader = styled.h4`
  font: normal normal bold 14px/19px;
  letter-spacing: 0px;
  color: ${props => props.theme.accentText};
`;

const ApplicantRequirementsWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  margin-bottom: 10%;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none; 
  align-items: left;

`;

const StyledBullet = styled.div`
  max-height: 10px;
  min-width: 10px;
  margin-right: 1em;
  margin-top: 1.5%;
  border-radius: 100%;
  background: linear-gradient(90deg, ${props => props.theme.tertiary} 0%, ${props => props.theme.secondary} 100%);  
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: row;
  min-width: 50%;
  max-width: 50%;
  margin-bottom: 2%;
  font: normal normal 600 14px/16px;
  letter-spacing: 0px;
  color: ${props => props.theme.primaryText};
  align-items: left; 
`;

const StyledPrimaryButtonComponent = styled(PrimaryButtonComponent)`
  width: 18em;  
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const OverviewContent = (props) =>{
  const config = useSelector(state => state.global && state.global.config)

const renderTexts = (texts) => {
  return texts.map((v, i) => {
    return <ProgramOverviewText key={i}>{v}</ProgramOverviewText>
  })
}

const renderListitems = (items) => {
  return items.map((v, i) => {
    return  <StyledListItem key={i}><StyledBullet />{v}</StyledListItem>
  })
}

if(!(config && config.registration && config.registration.overview)){
  return (
    <></>
  )
}

  return (
    <StyledContainer>
        <ProgramOverviewTitle>{config.registration.overview.title}</ProgramOverviewTitle>
        {
          renderTexts(config.registration.overview.texts)
        }
        <ApplicantRequirementsHeader>{config.registration.overview.requirementsHeader}</ApplicantRequirementsHeader>
        <ApplicantRequirementsWrapper>
            {
              renderListitems(config.registration.overview.requirements)
            }
        </ApplicantRequirementsWrapper>
        <ButtonWrapper>
        <StyledPrimaryButtonComponent
            onClick={() => props.onClickSubmit({})}
            text={'Start Registration'}>
        </StyledPrimaryButtonComponent>
        </ButtonWrapper>
    </StyledContainer>  
    );
  }

export default OverviewContent;
