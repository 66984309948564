/* eslint-disable no-return-await */
import axios from 'axios'; 
import { createBrowserHistory } from '../../../node_modules/history';
import { getToken, ACCESS_TOKEN_KEY, ID_TOKEN_KEY } from '../../Auth';

const history = createBrowserHistory();
export default async function(endpoint, method, data = null, isBase64Encoded = false) {
   
  let url = process.env.REACT_APP_env_api_base;
  const idToken = await getToken(ID_TOKEN_KEY);
  const accessToken = await getToken(ACCESS_TOKEN_KEY);
  let authToken = `Bearer ${accessToken}`;
  if (process.env.REACT_APP_env_use_sso === 'true') {
    //url = `${url}v2/`;
    authToken = idToken; // cognito does not use access token
  }

  const result = await axios({
    baseURL: url + endpoint,
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
      'Accept': '*/*'
    },
    data,
    isBase64Encoded,
    clientID: process.env.REACT_APP_env_client_id,
    mode: 'no-cors'
  })

  return result;
}
