import React from 'react';
import styled from 'styled-components/macro';

const ProgressBar = styled('div')`
  background: linear-gradient(to right, ${props => props.theme.secondary} 0%, ${props => props.theme.tertiary} 100%);
  height: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-width: 40%;
`;

const SkewedDivContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SkewedDiv = styled('div')`
  border-left: 3px solid white;
  height: 10px;
  width: 20%;

  &:first-child {
    border-left: none;
  }
`;

// default width should be 101% to cover the entire width of the scale
const ShadedContainer = styled('div')`
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.75) 18%,
    rgba(255, 255, 255, 0.7) 124%
  );
  background-size: cover;
  height: 10px;
  right: 0;
  position: absolute;
  transition: width 0.5s;
  width: ${(props) => props.sectionPercentage + '%'};
`;

export const ProgressBarComponent = (props) => {
  const { sections, currentSection } = props;
  const sectionsArray = [];
  let sectionPercentage = '';
  if(sections) {
    sections.forEach((section, i) => sectionsArray.push(<SkewedDiv key={i} />));

    let currentPosition = 0;

    if(currentSection){
      for(let i = 0; i < sections.length; i++){
        if(sections[i].name === currentSection.name){
          currentPosition = i
        }
      }
    }

    sectionPercentage = (100 - ((100 / sections.length) * currentPosition));

    if(window.location.pathname === '/thankyou') {
      sectionPercentage = 0;
    }
  }

  return (
    <ProgressBar>
      <ShadedContainer sectionPercentage={sectionPercentage}/>
      <SkewedDivContainer>
        {sectionsArray}
      </SkewedDivContainer>
    </ProgressBar>
  );
};

export default ProgressBarComponent;