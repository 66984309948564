import React from 'react';
import styled from 'styled-components/macro';

const StyledRoot = styled.button`
    width: 120px;
    color: ${props => props.theme.main};
    font-weight: bold;
    text-transform: unset !important;
    height: 41px;
    font-size: .9em;
    cursor: pointer;
    border: none;
    letter-spacing: .01em;
    position: relative;
    z-index: 1;
    background-color: Transparent;
}
`;

const SmallButton = styled(StyledRoot)`
    width: 100px;
    height: 27px;
`;

export const SaveExitButtonComponent = (props) => {
    switch(props.size){
        case "small":
            return (
                <SmallButton id={props.id} className={props.className} style={props.innactive ? { opacity: .5 } : { opacity: 1 }} onClick={ props.onClick } >{ props.text }</SmallButton>
            )
        default:
            return (
                <StyledRoot id={props.id} className={props.className} style={props.innactive ? { opacity: .5 } : { opacity: 1 }} onClick={ props.onClick } >
                    { props.text }
                </StyledRoot>
            )
        }   
};

export default SaveExitButtonComponent;