import React from "react";
import styled from 'styled-components/macro';
import TimerDisplayerComponent from './TimerDisplayerComponent';
import { useMediaQuery } from 'react-responsive';
import { up } from "styled-breakpoints";

const StyledRoot = styled.div`
    width: 100%;
    height: 3.5em;
    background-color: ${ props => props.theme.main };
    box-shadow: 0px 6px 30px ${props => props.theme.stickyShadow};   
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;    
    opacity: 1;    
`;

const StyledCenteringDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledLogo = styled.img`
    height: 30px;        
`;

const LogoWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 2em;
    ${up('lg')} {
        width: 70%;
        max-width: 1300px;
        margin: 0;
    }
`;

const StickyTimerComponent = (props) => {    
    return(
        <StyledRoot>
            <LogoWrapper>
                <StyledCenteringDiv>
                    <StyledLogo src={props.logoImageUrl}/>
                </StyledCenteringDiv>
                <StyledCenteringDiv>
                    <TimerDisplayerComponent 
                        timerImg ={props.timerImg}
                        sectionTimer ={props.sectionTimer}
                        textColor ={'white'} 
                        timerID ={props.timerID}                             
                        width ={56} 
                        height ={52}
                        customization ={{background: '#436296', 
                                        border : '5px solid #506FA3'}}
                    />
                </StyledCenteringDiv>
            </LogoWrapper>
        </StyledRoot>
    );
  }

export default StickyTimerComponent;