import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import JobHistoryComponent from '../JobHistoryComponent/JobHistoryComponent';
import EducationHistoryComponent from '../EducationHistoryComponent/EducationHistoryComponent';

const StyledRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const MultiFormComponent = () => {
    const selectedSection = useSelector((state) => state.selectedSection);

    const renderMultiForm = () => {
        switch(!selectedSection ? "fail" : selectedSection.type){
          case "multiForm.education":
            return (
                <EducationHistoryComponent />
            );
          case "multiForm.employment":
            return (
                <JobHistoryComponent />
            );
          default:
            return;
          }
      }

    return (
        <StyledRoot>
            <StyledBody>
                {renderMultiForm()}
            </StyledBody>
        </StyledRoot>
    );
}

export default MultiFormComponent;