import React from 'react';
import styled from 'styled-components/macro'; 
import SectionParentComponent from '../SectionParentComponent/SectionParentComponent';
import DividerComponent from '../DividerComponent/DividerComponent';
import SectionBorderComponent from './SectionBorderComponent';
import SectionTransitionComponent from '../SectionTransitionComponent/SectionTransitionComponent';
import ProgressBarComponent from '../ProgressBarComponent/ProgressBarComponent';
import { useMediaQuery } from 'react-responsive';
import PulseSpinnerComponent from '../PulseSpinnerComponent/PulseSpinnerComponent';
import { up } from 'styled-breakpoints';

const StyledRoot = styled.div`
  background-color: ${props => props.theme.background}
  align-content: center;   
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: ${ props => props.theme.background };
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
`;

const VerticalDecorationContainer = styled.div`
display: flex;
flex-direction: row;
width: 15%;
margin-top: 3em;
`;

const HorizontalDecorationContainer = styled.div`
width: 15%;
overflow: hidden;
${up('lg')}{
  margin-right: 3em;
}
`;

const BlackSquareDecoration = styled.div`
width: 1.75em;
height: 1.75em;
background-color: black;
`;

const VerticalGradientDecoration = styled.div`
width: 2em;
height: 10em;
background: linear-gradient(#B5B3B3, #DE4F31);
`;

const HorizontalGradientDecoration = styled.div`
width: 100%;
height: 1.75em;
background: linear-gradient(to left top, #DE4F31, #B5B3B3);
margin-left: 1.75em;
`;

const StyledTitle = styled.div`
  width: 100%;
  padding-top: 1em;  
  margin-bottom: 1em;
  background-color: ${ props => props.theme.background };
`;

const StyledTitleText = styled.div`
  margin: 1.5em 0 1em 0;  
  font-family: 'Tusker Grotesk';   
  font-weight: 100;
  font-size: 1.5em;
  letter-spacing: -.022em;
  transform: scale(1.75, 2);
  transform-origin: left;
  color: #FFFFFF;
  color: ${props => props.theme.main};
  ${up('md')} {
    font-size: 4em;
}
`;

const DividerBox = styled.div`
  height: .5em;
  width: 1.5em;
  background-color: ${ props => props.theme.main };
`;

const PrimaryTextWrapper = styled.div`
  margin-bottom: 3em;
  color: ${props => props.theme.main};
  font-size: 1.2em;
  background-color: ${ props => props.theme.background };
`;

const StyledInstruction = styled.div`
  margin-bottom: 3em;
  color: ${props => props.theme.main};
  font-size: 1.2em;
  background-color: ${ props => props.theme.background };
`;

const StyledQuestionNumber = styled.div`
  margin: 1em 0 1em 0; 
  font-weight: bold;
  font-size: 1.3em;  
  color: ${props => props.theme.main};
`;

const StyledSectionParentComponent = styled(SectionParentComponent)`
  color: ${props => props.theme.primaryText}
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const SectionsCompleteSquare = styled.div`
  max-width: 2.5em;
  height: 2.5em;
  padding-top: .6em;
  font-size: 1em;
  color: white;
  background-color: ${props => props.theme.main};
`;

const getNumberOfGroups = (questions) =>{
  let numberOfGroups = 0, numberOfNonGroupQuestions = 0;   

  for (let i = 0; i < questions.length; i++){     
      
    if(questions[i].group && parseInt(questions[i].group) > 0){
      // get number of groups            
      if(numberOfGroups < parseInt(questions[i].group)){             
          numberOfGroups = parseInt(questions[i].group);               
      }
    } 
    else if(questions[i].group == null && questions[i].type !== 'noAnswer') {
      //number of questions not in group needs to be included for count
      numberOfNonGroupQuestions ++;           
    }        
    
    if((i>0 && questions[i].type === "noAnswer") || questions[i].class === "text.reading.passage_text"){
      //it needs to exclude the number of questions if the question is instruction text         
      numberOfNonGroupQuestions--; 
    }       
  }

  const numbersOfGroupsAndNonGroupedQuestions = { "numberOfGroups": numberOfGroups, "numberOfNonGroupQuestions": numberOfNonGroupQuestions };
  return numbersOfGroupsAndNonGroupedQuestions;
}

const getInstructionAndNumberOfQuestions = (questions)=> {
  const questionInstAndNumb =[];
  let numberOfQuestions = questions.length;
  const numbersOfQuestionsNGroups = getNumberOfGroups(questions);
  let numberOfGroups = numbersOfQuestionsNGroups.numberOfGroups;    
  let numberOfNonGroupQuestions = numbersOfQuestionsNGroups.numberOfNonGroupQuestions;
  
  //get section instruction
  if(questions[0] && (questions[0].type === "noAnswer")){ 
    // first question is instruction            
    questionInstAndNumb.push(questions[0] && questions[0].prompts[0]?.value);
    //exclude instruction from question counting 
    numberOfQuestions--;          
  }
  
  if(numberOfGroups > 0){
    // it needs to exclude non group questions from grouped questions for grouping
    numberOfQuestions = numberOfQuestions - numberOfNonGroupQuestions ;        
    numberOfQuestions = Math.round(numberOfQuestions / numberOfGroups);        
    // add back excluded question for total count
    numberOfQuestions = numberOfQuestions + numberOfNonGroupQuestions;        
  }

  //exclude reading passages from question counting
  for (let question of questions){
    if (question.class === "text.reading.passage_text"){
      numberOfQuestions--;
    }
  }

  if(questionInstAndNumb.length >0){
    // there is instruction
    questionInstAndNumb.push(numberOfQuestions);
  }else{
    //for sections which are not of instruction
    questionInstAndNumb.push('');
    questionInstAndNumb.push(numberOfQuestions);
  }
      
  return questionInstAndNumb
}

const ScreeningContainerComponent = (props) => {
    const selectedSection = props.selectedSection;
    const sections = props.sections;
    const global = props.global;
    const isBigScreen = useMediaQuery({ query: '(min-width: 1160px)' });
    const showTransitionPage = props.showTransitionPage;
    const handleTransitionStart = props.handleTransitionStart;
    const handleTransitionNext = props.handleTransitionNext;
    const handleTransitionBack = props.handleTransitionBack;
    const hardSectionTimer = props.hardSectionTimer;
    const hardTimedSections = props.hardTimedSections;
     
    let title, qnumb;
   
    if(selectedSection){
      title = selectedSection.name.split('-')[0];

      if(selectedSection.questions){
        const instrAndQnumber = getInstructionAndNumberOfQuestions(selectedSection.questions);
        if(instrAndQnumber && instrAndQnumber.length > 1){
          qnumb = instrAndQnumber[1] ;  
        }
      }
    }
    
    const renderContainerComponent = () => {

        if(global.showLoadingSpinner){
          return (                      
              <PulseSpinnerComponent
                dotColorArray={['#F7C634', '#f6bf55', '#f7ac43', '#f79a34']}
                dotSpeedArray={[1.8, 1.8, 1.8, 1.8]}
                dotDelayArray={[0, 0.2, 0.4, 0.6]}
                size={30}
                positionArray={[5, 10]}
                borderColor={'white'}
              />
          ); 
        }

        if(selectedSection && showTransitionPage){
          
            return(
                <SectionTransitionComponent onTransitionStart={handleTransitionStart} sections={sections} hardTimedSections={hardTimedSections} onTransitionBack ={handleTransitionBack} />
            );
        }else{
            return(
                <StyledSectionParentComponent selectedSection={selectedSection} hardTimedSections={hardTimedSections} sections={sections} hardSectionTimer={hardSectionTimer} completed={global.completed} submitted={global.submitted} showRequiredModal={global.showRequiredModal} onSectionChange={handleTransitionNext} />
            );
        }
    }

    return(
        <StyledRoot>          
            <StyledTop>
              <VerticalDecorationContainer>
                <VerticalGradientDecoration />
              </VerticalDecorationContainer>
              <TitleContainer>
                <StyledTitle>
                    <StyledTitleText>{title} </StyledTitleText>
                    <DividerBox/>
                    { (selectedSection && selectedSection.type && selectedSection.type.split('.')[0] === 'multiForm') || showTransitionPage || (selectedSection && selectedSection.type && selectedSection.type.split('.')[0] === 'conditional') ? <></> : <StyledQuestionNumber>{qnumb === 0 || qnumb === undefined ? '' : qnumb + ' Questions'}</StyledQuestionNumber> }
                </StyledTitle>
                { selectedSection && showTransitionPage ? <PrimaryTextWrapper dangerouslySetInnerHTML={{__html: selectedSection.primaryText}}></PrimaryTextWrapper> : <StyledInstruction dangerouslySetInnerHTML={{__html: selectedSection && selectedSection.instructions ? selectedSection.instructions : ''}}></StyledInstruction> } 
                { (selectedSection && selectedSection.order) ? 
                <ProgressBarContainer>
                  <ProgressBarComponent bannerImageUrl={props.bannerImageUrl ? props.bannerImageUrl : null} sections={props.sections} currentSection={selectedSection}></ProgressBarComponent>
                  <SectionsCompleteSquare dangerouslySetInnerHTML={{__html: selectedSection.order + '/' + sections.length}}></SectionsCompleteSquare>
                </ProgressBarContainer>
                : ''}
              </TitleContainer>
              <HorizontalDecorationContainer>
                <HorizontalGradientDecoration />
                <BlackSquareDecoration />
              </HorizontalDecorationContainer>
            </StyledTop>
            <DividerComponent></DividerComponent>
            {renderContainerComponent()}
        </StyledRoot>        
    )
}

export default ScreeningContainerComponent;