import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import RadioButtonComponent from '../RadioButtomComponent/RadioButtonComponent';
import KatexFormulaComponent from '../KatexFormulaComponent/KatexFormulaComponent';
import { up } from 'styled-breakpoints';

const StyledRoot = styled.div`
    display: flex;
    margin-bottom: 8em;
    flex-direction: column;
    width: 100%;    
    ${up('lg')} {
        flex-direction: row;
        width: auto;
    }
`;

const RadioGroup = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;    
    ${up('lg')} {        
        flex-wrap: unset;
        justify-content: unset;
    }
`;

const PromptText = styled.div`
    color: ${ props => props.theme.label };
`;

const KatexText = styled.div`
    color: ${ props => props.theme.label };
    margin-top: .5em;
`;

const KatexPrompt = styled(KatexFormulaComponent)`
    font-size: 25px;
    margin-top: .3em;
    margin-left: .5em;
    margin-right: .5em;
`;

const KatexOption = styled(KatexFormulaComponent)`
    font-size: 25px;
    ${up('lg')} {
        white-space: nowrap;
    }
`;

const SelectedKatexOption = styled(KatexOption)`
    color: ${ props => props.theme.accentText };
    ${up('lg')} {
        white-space: nowrap;
    }
`;

const StyledPrompt = styled.div`
    display: flex;
    margin-right: 4em;
    flex-wrap: wrap;
    align-content: baseline;
    margin-top: 4px;
    padding-bottom: 2em;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    ${up('lg')} {
        padding-bottom: 0;
        width: 17em;        
        justify-content: unset;
    }
`;

const RadioWrapper = styled.div`
    display: flex;
    line-height: 2em;    
    padding-right: 2em;
    flex-wrap: wrap;    
    ${up('lg')} {
        flex-wrap: unset;
        flex-direction: row;
        margin-bottom: 1em;
    }
`;

const RadioButtonLabel = styled.div`
    color: ${ props => props.theme.label };
    font-size: 15px;
    font-weight: 400;    
    white-space: wrap;
    line-height: normal;    
`;

const SelectedRadioButtonLabel = styled.div`
    color: ${ props => props.theme.accentText };
    font-size: 15px;
    font-weight: 400;
    white-space: wrap;
    line-height: normal;    
`;

const StyledRadioButtonComponent = styled(RadioButtonComponent)`
    padding-bottom: 1em;    
    margin-right: 1em;
    ${up('lg')} {
        padding-bottom: 0;
        flex-direction: column;        
        justify-content: flex-start;
    }
`;

const StyledImagePrompt = styled.img`
    max-width: 270px;
    margin-top: 4px;
    margin-bottom: 1em;
`;

const StyledImageOption = styled.img`
    width: 280px;
    margin: 0;
`;

const imgBasePath = process.env.REACT_APP_env_svg_base_path;

const VerticalRadioQuestionComponent = (props) => {
    const dispatch = useDispatch();

    const handleChange = (questionOptionID) => {
        const selectedVal = props.question.options.filter((v, i) => {
            return v.questionOptionID === parseInt(questionOptionID);
        })[0].value;

        const currentVal = props.question.answer;

        const newVal = selectedVal === currentVal ? "" : selectedVal;

        dispatch({ type: 'UPDATE_SECTION_QUESTION', data: { "uuid": props.question.uuid, "id": props.question.questionID, "answer": newVal} });
    };

    const getListItems = () => {
        const selectionOptions = props.question;
        let orderedOptions = selectionOptions.options.sort((a, b) => (a.order > b.order) ? 1 : -1)
        const listElements = orderedOptions.map((option, i) => {
            const isChecked = props.question.answer && (props.question.answer === option.value || props.question.answer === option.optionUrl);
            let suffix=props?.question?.questionID===202 && i<4?' in':'';
            return(
                <RadioWrapper key={option.questionOptionID + '_wrapper'}>
                    <StyledRadioButtonComponent key={option.questionOptionID + '_button'} onChange={handleChange} id={option.questionOptionID} group={props.question.questionID} checked={!!isChecked}></StyledRadioButtonComponent>
                    {getLabel(i, isChecked, option,suffix)}
                </RadioWrapper>
            )
        })
        return listElements;
    }

    const getLabel = (index, isChecked, option,suffix='') => {
        if(option.valueType === 'img'){
            return(<StyledImageOption key={'option_'+index} src={imgBasePath+option.optionsUrl}></StyledImageOption>);
        }else if(option.valueType === 'katex'){
            if(isChecked){
                return (<SelectedKatexOption key={'option_'+index} value={option.value}></SelectedKatexOption>);
            }else{
                return (<KatexOption key={'option_'+index} value={option.value}></KatexOption>);
            }
        }else if(option.valueType === 'text' || option.valueType === '#text'){
            if(isChecked){
                return(<SelectedRadioButtonLabel key={'option_'+index} dangerouslySetInnerHTML={{__html: option.value}}></SelectedRadioButtonLabel>);
            }else{
                return(<RadioButtonLabel key={'option_'+index} dangerouslySetInnerHTML={{__html: `${option.value}${suffix}`}}></RadioButtonLabel>);
            }
        }
    }

    const getPrompt = (prompts) => {
        return prompts
        .sort((a, b) => parseInt(a.order) < parseInt(b.order) ? -1 : 1)
        .map((v, i) => {
            switch(v.valueType){
                case "#text":
                    return (<PromptText key={'prompt_'+i} style={{}} dangerouslySetInnerHTML={{__html: v.value}}></PromptText>);
                case "img":
                    return (<StyledImagePrompt key={'prompt_'+i} src={imgBasePath+v.url}></StyledImagePrompt>);
                case "katex":
                    return (<KatexPrompt key={'prompt_'+i} value={v.value}></KatexPrompt>);
                case "katex.text":
                    return (<KatexText key={'prompt_'+i} dangerouslySetInnerHTML={{__html: v.value}}></KatexText>);
                default:
                    return (<PromptText key={'prompt_'+i} dangerouslySetInnerHTML={{__html: v.value}}></PromptText>);
            }
        })
    }

    return (
        <StyledRoot className={props.className}>
            <StyledPrompt>
                {getPrompt(props.question.prompts)}
            </StyledPrompt>
            <RadioGroup>
                {getListItems()}
            </RadioGroup>
        </StyledRoot>
    );
}

export default VerticalRadioQuestionComponent;
