import React, { useState, useLayoutEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import QuestionParentComponent from '../QuestionParentComponent/QuestionParentComponent';
import ConditionalQuestionGroupComponent from '../ConditionalQuestionGroupComponent/ConditionalQuestionGroupComponent';
import { useMediaQuery } from 'react-responsive';

const StyledRoot = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const QuestionRows = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;     
`;

const IndividualQuestion = styled(QuestionParentComponent)`
    margin: 0;    
    padding-bottom: 1em;   
    @media (max-width: 425px) {
        :last-child {
            padding-bottom: 4em;
        }
    }
`;

const QuestionContainer = styled.div`
    width: 100%;
    display: flex;
    max-height: 15em;
`;

const GroupedQuestionContainer = styled.div`     
    display: flex;  
`;

const StyledConditionalQuestionGroupComponent = styled(ConditionalQuestionGroupComponent)`
    padding-bottom: 1em;
`;

const StylePadding = styled.div` 
    width: 90%;
`;

const ConditionalSectionComponent = () => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const selectedSection = useSelector((state) => state.selectedSection);
    const [questionContainerWidth, setQuestionContainerWidth] = useState(null);   

    useLayoutEffect(() => {
        const newVw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        let newQuestionContainerWidth;

        //main content container has a width of 70% and max-width of 1300px
        //this check is to see if the view width is large enough to let the main container reach max width
        if(newVw >= 1857){
            newQuestionContainerWidth = (1300)/3;
        }else if(newVw >= 1300){
            newQuestionContainerWidth = (newVw*.7)/3 - 5;
        }else if(newVw >= 1100){
            newQuestionContainerWidth = (newVw*.7)/2 - 5;
        }else{
            newQuestionContainerWidth = '100%';
        }

        setQuestionContainerWidth(newQuestionContainerWidth);
    }, [])

    const renderQuestions = () => {
        const groups = getUniqueGroups(selectedSection.questions);

        return groups.map((v, i) => {           
            const questions = getGroupQuestions( selectedSection.questions, v);
             
            if(questions.length > 1){ 
                const parentQuestion = questions.filter((v, i) => {return v.type.indexOf('parent') === 0})[0];    
                const childQuestion = questions.filter((v, i) => {return v.type.indexOf('child') === 0})[0]; 
               
                if(parentQuestion && childQuestion){
                    return(
                        <QuestionContainer key={`q_container_${i}`} style={selectedSection.name == 'Plot Summary' ? {maxWidth: '40%'} : {maxWidth: questionContainerWidth}}>
                            <StyledConditionalQuestionGroupComponent parentQuestion={parentQuestion} childQuestion={childQuestion}></StyledConditionalQuestionGroupComponent>
                        </QuestionContainer>
                    )
                }              
            }else if(questions[0].type !='noAnswer'){
                return(
                    <QuestionContainer key={`q_container_${i}`} style={selectedSection.name == 'Plot Summary' ? {maxWidth: '40%'} : {maxWidth: questionContainerWidth}}>
                        <StylePadding><IndividualQuestion question={questions[0]}></IndividualQuestion></StylePadding>
                    </QuestionContainer>
                )
            }
        })
    }

    //Returns a set of unique groups that questions belong to
    const getUniqueGroups = (questions) => {
        let groups = questions.map((v, i) => {return v.group});

        let uniqueGroups = [...new Set(groups)];
        
        return uniqueGroups;
    }

    //Returns an array of questions that belong to the given group
    const getGroupQuestions = (questions, group) => {
        let groupQuestions = questions.filter((v, i) => {
            return v.group === group;
        });

        return groupQuestions;
    }

    const renderSection = () => {
        return(
            <QuestionRows style={{justifyContent: !isSmallScreen ? 'space-between' : 'center', paddingLeft: isSmallScreen ? '2em' : 0, paddingRight: isSmallScreen ? '2em' : 0}}>
                {renderQuestions()}
            </QuestionRows>
        )
    }

    return (
        <StyledRoot style={{marginTop: isSmallScreen ? 0 : '7em'}}>
            <StyledBody>
                {renderSection()}
            </StyledBody>
        </StyledRoot>
    );        
}

export default ConditionalSectionComponent;